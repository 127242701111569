import { Injectable } from '@angular/core';
import { ColDef, ColGroupDef } from 'ag-grid-enterprise';

@Injectable({
    providedIn: 'root',
})

export class HhraRunRiskAssessmentOpexResultsColDef {

    public getColumnsDefinition(): (ColDef | ColGroupDef)[] | null {
        return [
            {
                headerName: '',
                children: [
                    {
                        headerName: 'Worker activity',
                        field: '',
                        width: 155,
                        filter: 'agSetColumnFilter',
                        editable: false,
                        resizable: true,
                        sortable: true,
                    },
                    {
                        headerName: 'Exposure Scenario',
                        field: '',
                        width: 190,
                        filter: 'agSetColumnFilter',
                        editable: false,
                        resizable: true,
                        sortable: true,
                    },
                    {
                        headerName: 'Crop or Target',
                        field: '',
                        width: 200,
                        filter: 'agSetColumnFilter',
                        resizable: true,
                        sortable: true,
                        editable: false,
                    },
                    {
                        headerName: 'Dermal Unit Exposure (μg/lb ai)',
                        field: '',
                        width: 155,
                        filter: 'agSetColumnFilter',
                        resizable: true,
                        sortable: true,
                        editable: false,
                    },
                    {
                        headerName: 'Level of PPE or Engineering control',
                        field: '',
                        width: 270,
                        filter: 'agSetColumnFilter',
                        editable: false,
                        resizable: true,
                        sortable: true,
                    },
                    {
                        headerName: 'Inhalation Unit Exposure (μg/lb ai)',
                        field: '',
                        width: 170,
                        filter: 'agSetColumnFilter',
                        editable: false,
                        resizable: true,
                        sortable: true,
                    },
                    {
                        headerName: 'Level of PPE or Engineering control',
                        field: '',
                        width: 250,
                        filter: 'agSetColumnFilter',
                        editable: false,
                        resizable: true,
                        sortable: true,
                    },
                    {
                        headerName: 'Maximum Application Rate',
                        field: '',
                        width: 150,
                        filter: 'agSetColumnFilter',
                        editable: false,
                        resizable: true,
                        sortable: true,
                    },
                    {
                        headerName: 'App Rate Unit',
                        field: '',
                        width: 120,
                        filter: 'agSetColumnFilter',
                        editable: false,
                        resizable: true,
                        sortable: true,
                    },
                    {
                        headerName: 'Area Treated or Amount Handled Daily',
                        field: '',
                        width: 170,
                        filter: 'agSetColumnFilter',
                        editable: false,
                        resizable: true,
                        sortable: true,
                    },
                    {
                        headerName: 'Area Treated/Amount Handled Unit',
                        field: '',
                        width: 170,
                        filter: 'agSetColumnFilter',
                        editable: false,
                        resizable: true,
                        sortable: true,
                    },
                ]
            },
            {
                headerName: 'Dermal',
                children: [
                    {
                        headerName: 'Dose (mg/kg/day)',
                        field: '',
                        width: 120,
                        filter: 'agSetColumnFilter',
                        editable: false,
                        resizable: true,
                        sortable: true,
                    },
                    {
                        headerName: 'MOE',
                        field: '',
                        width: 130,
                        filter: 'agSetColumnFilter',
                        editable: false,
                        resizable: true,
                        sortable: true,
                    },
                    {
                        headerName: 'Result',
                        field: '',
                        width: 130,
                        filter: 'agSetColumnFilter',
                        editable: false,
                        resizable: true,
                        sortable: true,
                    },
                ]
            },
            {
                headerName: 'Inhalation',
                children: [
                    {
                        headerName: 'Dose (mg/kg/day)',
                        field: '',
                        width: 120,
                        filter: 'agSetColumnFilter',
                        editable: false,
                        resizable: true,
                        sortable: true,
                    },
                    {
                        headerName: 'MOE',
                        field: '',
                        width: 130,
                        filter: 'agSetColumnFilter',
                        editable: false,
                        resizable: true,
                        sortable: true,
                    },
                    {
                        headerName: 'Result',
                        field: '',
                        width: 130,
                        filter: 'agSetColumnFilter',
                        editable: false,
                        resizable: true,
                        sortable: true,
                    },
                ]
            },
            {
                headerName: 'Total',
                children: [
                    {
                        headerName: 'Dose (mg/kg/day)',
                        field: '',
                        width: 120,
                        filter: 'agSetColumnFilter',
                        editable: false,
                        resizable: true,
                        sortable: true,
                    },
                    {
                        headerName: 'MOE',
                        field: '',
                        width: 130,
                        filter: 'agSetColumnFilter',
                        editable: false,
                        resizable: true,
                        sortable: true,
                    },
                    {
                        headerName: 'Result',
                        field: '',
                        width: 130,
                        filter: 'agSetColumnFilter',
                        editable: false,
                        resizable: true,
                        sortable: true,
                    },
                ]
            }
        ];
    }
}