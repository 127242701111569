import { Component, Renderer2 } from '@angular/core';
import { InputTextRenderer } from '../input-text.component';

@Component({
  selector: 'app-input-text-application-scheme',
  templateUrl: './input-text-application-scheme.component.html',
  styleUrls: ['./input-text-application-scheme.component.css']
})
export class InputTextApplicationSchemeRenderer extends InputTextRenderer {
  constructor(private renderer: Renderer2) {
    super();
  }
  public override manageIsValid(): void {
    super.manageIsValid();
    this.isValid = this.isValid && !this.params.data.isDuplicate
  }

  public override onBlur(event: any) {
    super.onBlur(event)
    this.manageIsValidOnBlur();
  }

  private manageIsValidOnBlur(): void {
    const inputElement = document.querySelector('input.p-inputtext-invalid');
    if (!inputElement) return;
  
    if ((this.value === undefined || this.value === null || this.value === '') && this.isRequired) {
      this.isValid = false;
      this.renderer.removeClass(inputElement, 'p-inputtext');
      this.renderer.addClass(inputElement, 'p-inputtext-invalid');
    } else {
      this.isValid = true;
      this.renderer.removeClass(inputElement, 'p-inputtext-invalid');
      this.renderer.addClass(inputElement, 'p-inputtext');
    }
  }
}
