import { Component, DestroyRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, inject } from '@angular/core';
import { firstValueFrom, take } from 'rxjs';
import { SelectedProjectApiService } from 'src/app/shared/components/selected-project/selected-project.api.service';
import { ActiveIngredient } from 'src/app/shared/models/active-ingridient';
import { Catalog } from 'src/app/shared/models/echo/catalog';
import { Formulation } from 'src/app/shared/models/formulation';
import { Project } from 'src/app/shared/models/project';
import { OrderByPipe } from 'src/app/shared/pipes/order-by.pipe';
import { FormulationApiService } from 'src/app/shared/services/echo/formulation.api.service';
import { GeographyApiService } from 'src/app/shared/services/echo/geography.api.service';
import { Environmental_Assessments, UserLogicService } from 'src/app/shared/services/user.logic.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MetaboliteApiService } from 'src/app/shared/services/echo/metabolite.api.service';
import { Metabolite } from 'src/app/shared/models/echo/metabolite';
import { ModelLibrary, ModelLibraryCollection } from 'src/app/shared/models/model-library';
import { ListboxChangeEvent } from 'primeng/listbox';
import { Constants } from 'src/app/shared/utils/constants';
import { TabMenuLogicService } from 'src/app/shared/services/tab-menu.logic.service';
import { EEALogicService } from '../../environmental-exposure-assessment/environmental-exposure-assessment.logic.service';
import { InverseModelingEEALogicService } from 'src/app/inverse-modeling-environmental-exposure-assesment/inverse-modeling-environmental-exposure-assesment/inverse-modeling-environmental-exposure-assesment.logic.service';
import { CustomTabMenuItem } from 'src/app/shared/models/custom-tab-menu-item';
import { ProjectXCompoundXModel } from 'src/app/shared/models/project-x-compound-x-model';
import Swal from 'sweetalert2';
import { ModelToBeRun, ModelToBeRunRow } from 'src/app/shared/models/run/model-to-be-run';
import { Utils } from 'src/app/shared/utils/utils';

@Component({
  selector: 'app-eea-setup',
  templateUrl: './eea-setup.component.html',
  styleUrls: ['./eea-setup.component.css']
})
export class EEASetupComponent implements OnInit {

  @Input() isInverseModeling: boolean = false;
  @Input() menuService!: TabMenuLogicService;
  @Input() activeItem?: CustomTabMenuItem;
  @Input() columnDef: any[] = [];
  @Input() disciplinePk: number = 0;
  @Input() secondDisciplinePk?: number;
  @Input() modelsCollection: ModelLibraryCollection[] = [];
  @Input() isHHRA: boolean = false;
  @Input() isEEA: boolean = false;
  @Input() environmentalAssessment?: Environmental_Assessments;
  @Output() isSetupValidEvent = new EventEmitter<boolean>();
  @Output() modelsSelectedEvent = new EventEmitter<string[]>();

  geographies: Catalog[] = [];
  filteredFormulations: Formulation[] = [];
  modelsToBeRun: ModelToBeRun[] = [];
  currentUserName: string = "";
  selectedProject?: Project;
  projects: Project[] = [];
  selectedOutputs: any = [];
  gapOuputs = Constants.GAP_OUTPUTS;
  modelsLoading: boolean = false;
  setupLoading: boolean = false;
  validSetup: boolean = false;
  validSetupModel: boolean = false;
  destroyRef = inject(DestroyRef)
  isProjectOwnershipValid: boolean = false;
  isValidCompoundName: boolean = true;
  showAlternativeNameLabel: boolean = false;

  constructor(private selectedProjectAPIService: SelectedProjectApiService,
    private geograpthyAPIService: GeographyApiService,
    private formulationAPIService: FormulationApiService,
    private orderByPipe: OrderByPipe,
    private userLogicService: UserLogicService,
    private metaboliteApiService: MetaboliteApiService,
    private EEALogicService: EEALogicService,
    private InverseModelingEEALogicService: InverseModelingEEALogicService) {
  }

  ngOnInit(): void {
    this.setupLoading = true;
    this.getGeographies();
    this.getCreatedBy();
    this.initSubscribes();
  }

  getGeographies() {
    this.geograpthyAPIService.getRegionCountryCatalog().pipe(take(1)).subscribe(geographies => {
      this.geographies = this.geographies = this.orderByPipe.transform(geographies.filter(x => x.name == Constants.CROP_GEOGRAPHIES.USA || x.name == Constants.CROP_GEOGRAPHIES.EUROPE_UNION || x.name == Constants.CROP_GEOGRAPHIES.UK), 'name');
    });
  }

  initSubscribes() {
    this.selectedProjectAPIService.projects.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(projects => this.projects = projects);

    this.selectedProjectAPIService.selectedProject.pipe<Project | undefined>(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: async (project?: Project) => {
        this.getSelectedFormulation(project);
        await this.getProjectsXCompoundXModels(project);
      }
    });

    this.menuService.activeIndexChanged.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((changed: boolean) => {
      if (changed) this.SaveSelectedProject()
    });
  }

  getSelectedFormulation(project?: Project) {
    if (project) this.filteredFormulations = [{ formulationPK: project.formulationPk, tradeName: project.formulation ?? '' }];
  }

  initNewProject() {
    if (this.selectedProject) return;
    this.selectedProjectAPIService.initNewProject(this.currentUserName);
  }

  getCreatedBy() {
    this.currentUserName = this.userLogicService.profile.displayName ?? "";
  }

  onFormulationFilterChanged(query: string) {
    this.formulationAPIService.getFormulationsByName(query)
      .pipe(take(1))
      .subscribe((formulations: Formulation[]) => {
        this.filteredFormulations = this.orderByPipe.transform(formulations, 'tradeName');
      });
  }

  async onformulationSelectionChanged(formulation: Formulation) {
    this.initAlternativeNameFlags();

    if (!formulation) {
      this.modelsToBeRun = [];
      this.modelsLoading = false;
      return;
    }

    if (formulation.formulationPK > 0)
      await firstValueFrom(this.formulationAPIService.getFormulationByPk(formulation.formulationPK, true).pipe(take(1))).then(completeFormulation => formulation = completeFormulation);

    if (this.selectedProject) {
      this.selectedProject.formulationType = formulation.formulationType;
      this.selectedProject.OPEXFormulationType = this.getOPEXFormulationType(this.selectedProject.formulationType);
    }

    this.modelsLoading = true;
    let localModelList: ModelToBeRun[] = [];
    if (!formulation.activeIngredients) {
      this.modelsToBeRun = [];
      this.modelsLoading = false;
      return;
    }

    const selectedMets = [...new Set(this.selectedProject?.projectXCompoundXModel?.filter(PxCxM => PxCxM.MetabolitePk != undefined)
      .map(m => m.MetabolitePk))];

    await Promise.all(formulation.activeIngredients.map(async (active: ActiveIngredient) => {
      let rowData: ModelToBeRunRow[] = [];
      let selected = false;
      await this.getMetabolitesByMolecule(active.activeIngredientPk).then((metabolites: Metabolite[]) => {

        rowData = metabolites
          .filter(m => (m.showInEea === 'Yes' && !this.isHHRA) ||
            (m.showInHhra === 'Yes' && this.isHHRA) ||
            (selectedMets.includes(Number(m.metabolitePk)) &&
              m.selectedInProjects.includes(this.selectedProject?.projectPk ?? 0))
          )
          .map((metabolite) => {
            let row: ModelToBeRunRow = { ...metabolite };
            row.moleculePk = active.activeIngredientPk;
            this.setRowCollectionForModel(row);

            if (this.selectedProject)
              row.disabled = this.isMetaboliteDisabled(metabolite);

            return row;
          });

        this.selectedProject?.projectXCompoundXModel?.forEach(PxCxM => {
          if (rowData.some(r => r.metabolitePk === PxCxM.MetabolitePk)) {
            PxCxM.disabled = rowData.find(r => r.metabolitePk === PxCxM.MetabolitePk)?.disabled;
          }
        });

        rowData = this.orderByPipe.transform(rowData, 'metaboliteName');

        let newRow: ModelToBeRunRow = {};
        newRow.moleculePk = active.activeIngredientPk;
        newRow.metaboliteName = active.activeIngredientName;
        newRow.compartments = this.modelsCollection.map(x => x.name);
        this.setRowCollectionForModel(newRow);
        rowData.unshift(newRow);
        let usedModels: string[] = [];
        if (!this.isInverseModeling)
          usedModels = ['core'];
        if (this.selectedProject?.projectXCompoundXModel && this.selectedProject.projectXCompoundXModel?.length > 0) {
          if (this.selectedProject?.projectXCompoundXModel?.map(compound => compound.MoleculePk).includes(active.activeIngredientPk)) {
            selected = true;
            rowData.forEach((row: ModelToBeRunRow) => {
              row.alternativeNameCompound = Utils.getAlternativeCompoundName(active.activeIngredientPk, this.selectedProject, undefined, row.metabolitePk);
              this.setRowCollectionForModel(row, true, usedModels);
              this.validateAlternativeNameCompoundByRow(row);
              let hasToShowAlternativeName = Utils.showAlternativeName(row?.metaboliteName, row.groundwater, row.surfacewater);
              if (hasToShowAlternativeName)
                this.showAlternativeNameLabel = true;
            });
            this.isInverseModeling ?
              this.InverseModelingEEALogicService.SetUsedModels(usedModels) :
              this.EEALogicService.SetUsedModels(usedModels);
          }
        }
      });

      localModelList.push({
        activeIngredient: active,
        rowData,
        selected: selected,
      });
    }));
    this.modelsToBeRun = localModelList;
    this.modelsLoading = false;
  }

  initAlternativeNameFlags() {
    this.showAlternativeNameLabel = false;
    this.isValidCompoundName = true;
  }

  validateAlternativeNameCompoundByRow(row: ModelToBeRunRow) {
    let isValid = Utils.validateAlternativeNameCompound(row.metaboliteName!, row.alternativeNameCompound, row.surfacewater!, row.groundwater!);
    if (!isValid)
      this.isValidCompoundName = false;
  }

  getOPEXFormulationType(formulationType?: string) {
    for (const fType in this.selectedProjectAPIService.matchingFACTSOPEX) {
      if (formulationType) {
        if (formulationType.toLowerCase() === fType.toLowerCase()) {
          return this.selectedProjectAPIService.matchingFACTSOPEX[fType];
        }
      }
    }
    return "";
  }

  setRowCollectionForModel(row: ModelToBeRunRow, useFilter: boolean = false, usedModels?: string[]) {
    this.modelsCollection.forEach((collection: ModelLibraryCollection) => {
      switch (collection.name) {
        case Constants.COMPARTMENTS.SURFACE_WATER.toLowerCase().replace(" ", ""):
          row.surfacewater = useFilter ? this.filterModel(collection.library, row) : [];
          if (useFilter && usedModels && row.surfacewater.length > 0) usedModels.push(collection.name);
          break;
        case Constants.COMPARTMENTS.GROUND_WATER.toLowerCase():
          row.groundwater = useFilter ? this.filterModel(collection.library, row) : [];
          if (useFilter && usedModels && row.groundwater.length > 0) usedModels.push(collection.name);
          break;
        case Constants.COMPARTMENTS.SOIL.toLowerCase():
          row.soil = useFilter ? this.filterModel(collection.library, row) : [];
          if (useFilter && usedModels && row.soil.length > 0) usedModels.push(collection.name);
          break;
        case Constants.COMPARTMENTS.DIETARY.toLowerCase():
          row.dietary = useFilter ? this.filterModel(collection.library, row) : [];
          if (useFilter && usedModels && row.dietary.length > 0) usedModels.push(collection.name);
          break;
        case Constants.COMPARTMENTS.NONDIETARY.toLowerCase():
          row.nondietary = useFilter ? this.filterModel(collection.library, row) : [];
          if (useFilter && usedModels && row.nondietary.length > 0) usedModels.push(collection.name);
          break;
        case Constants.COMPARTMENTS.AQUATIC.toLowerCase():
          row.aquatic = useFilter ? this.filterModel(collection.library, row) : [];
          if (useFilter && usedModels && row.aquatic.length > 0) usedModels.push(collection.name);
          break;
        case Constants.COMPARTMENTS.TERRESTRIAL.toLowerCase():
          row.terrestrial = useFilter ? this.filterModel(collection.library, row) : [];
          if (useFilter && usedModels && row.terrestrial.length > 0) usedModels.push(collection.name);
          break;
      }
    });
  }

  filterModel(model: ModelLibrary[], row: any) {
    let compounds = this.selectedProject?.projectXCompoundXModel?.filter(c => c.MoleculePk == row.moleculePk);
    if (!row.metabolitePk) compounds = compounds?.filter(c => !c.MetabolitePk);
    if (row.metabolitePk) compounds = compounds?.filter(c => c.MetabolitePk == row.metabolitePk);
    let models = model.filter(m => compounds?.map(c => c.ModelPk).includes(m.modelPk));
    return models;
  }

  getMetabolitesByMolecule(moleculePk: number) {
    return firstValueFrom(this.metaboliteApiService.getMetabolitesByMolecule(moleculePk))
  }

  async SaveSelectedProject() {
    let validatedName = await this.validateName();
    if (!this.selectedProject || !validatedName) {
      this.menuService.setSuccess(true);
      return;
    }

    let currentProjectXCompoundXModel: ProjectXCompoundXModel[] = [];
    if (this.selectedProject.projectXCompoundXModel)
      currentProjectXCompoundXModel = [...this.selectedProject.projectXCompoundXModel];

    this.selectedProject.projectXCompoundXModel = [];
    let modelsToBeUsed: string[] = [];
    this.modelsToBeRun.filter(m => m.selected).forEach(model => {

      let usedModels: string[] = [];
      if (!this.isInverseModeling)
        usedModels = ['core'];

      model.rowData.forEach((row: any) => {
        this.modelsCollection.forEach((collection: ModelLibraryCollection) => {
          if (row[collection.name]?.length > 0) usedModels.push(collection.name);
          row[collection.name]?.forEach((model: any) => {
            let foundModel: ProjectXCompoundXModel | undefined = undefined;
            if (!row.metabolitePk) {
              foundModel = currentProjectXCompoundXModel.find(m => (m.ModelPk === model.modelPk && m.MoleculePk == row.moleculePk && !m.MetabolitePk));
            }
            if (row.metabolitePk) {
              foundModel = currentProjectXCompoundXModel.find(m => (m.ModelPk === model.modelPk && m.MoleculePk == row.moleculePk && m.MetabolitePk === row.metabolitePk));
            }

            if (foundModel) {
              foundModel.ModelName = model.name;
              foundModel.CompartmentPk = model.compartmentXModel[0];
              foundModel.AlternativeNameCompound = row.alternativeNameCompound;
              this.selectedProject?.projectXCompoundXModel?.push(foundModel);
            }

            if (!foundModel) {
              this.selectedProject?.projectXCompoundXModel?.push({
                ProjectPk: this.selectedProject.projectPk,
                MoleculePk: row.moleculePk,
                MetabolitePk: row.metabolitePk,
                ModelPk: model.modelPk,
                ModelName: model.name,
                CompartmentPk: model.compartmentXModel[0],
                AlternativeNameCompound: row.alternativeNameCompound
              });
            }
          });
          modelsToBeUsed.push(...usedModels);
        });
      })
    });
    this.isInverseModeling ?
      this.InverseModelingEEALogicService.SetUsedModels([...new Set(modelsToBeUsed)]) :
      this.EEALogicService.SetUsedModels([...new Set(modelsToBeUsed)]);

    if (this.selectedProject.projectXCompoundXModel.length <= 0) {
      this.menuService.setSuccess(true);
      return;
    }
    let savedProjectPk = -1;
    this.selectedProjectAPIService.saveProject().pipe(take(1)).subscribe({
      next: (projectPk: number) => {
        savedProjectPk = projectPk;
        this.selectedProjectAPIService.saveProjectXCompoundXModels(savedProjectPk, this.disciplinePk).pipe(take(1)).subscribe({
          next: async () => {
            if (this.secondDisciplinePk) {
              await firstValueFrom(this.selectedProjectAPIService.saveProjectXCompoundXModels(savedProjectPk, this.secondDisciplinePk));
            }
            if (!this.projects.map(p => p.projectPk).includes(savedProjectPk)) {
              if (!this.selectedProject) return;
              this.selectedProject.projectPk = savedProjectPk;
              this.selectedProjectAPIService.insertProject(this.selectedProject);
            }
            this.menuService.setSuccess(true);
          },
          error: (err) => {
            console.warn(err);
            this.menuService.setSuccess(true);
          }
        });
      },
      error: (err) => {
        console.warn(err);
        this.menuService.setSuccess(true);
      }
    });
  }

  async validateName() {
    let isValid = true;
    if (!this.selectedProject?.name) isValid = false;
    await firstValueFrom(this.selectedProjectAPIService.projects).then(projects => {
      if (projects.find(p => p.name === this.selectedProject?.name && p.projectPk !== this.selectedProject?.projectPk)) {
        isValid = false;
        this.menuService.setSuccess(true);
        Swal.fire({
          title: `${this.selectedProject?.name}, project name already exists.`,
          text: 'Please use another name',
          confirmButtonColor: '#0069be',
          confirmButtonText: 'Ok',
          icon: 'error',
        });
      }
    });
    this.validSetup = isValid;
    this.validateSetup();
    return isValid;
  }

  outputSelectionChanged({ value }: ListboxChangeEvent) {
    this.selectedOutputs = value;
    this.updateProjectOutputs();
  }

  updateProjectOutputs() {
    if (!this.selectedProject) return;
    this.selectedProject.useRateGHA = this.selectedOutputs.find((x: any) => x.label === Constants.GAP_OUTPUTS_LABELS.RateGHA) ? true : false;
    this.selectedProject.useRateLBACRE = this.selectedOutputs.find((x: any) => x.label === Constants.GAP_OUTPUTS_LABELS.RateLBAcre) ? true : false;
    this.selectedProject.useBBCH = this.selectedOutputs.find((x: any) => x.label === Constants.GAP_OUTPUTS_LABELS.BBCH) ? true : false;
    this.selectedProject.useApplicationWindow = this.selectedOutputs.find((x: any) => x.label === Constants.GAP_OUTPUTS_LABELS.ApplicationWindow) ? true : false;
    this.selectedProject.useNumberOfApplications = this.selectedOutputs.find((x: any) => x.label === Constants.GAP_OUTPUTS_LABELS.NumberOfApplications) ? true : false;
    this.selectedProject.useIntervalBetweenApplications = this.selectedOutputs.find((x: any) => x.label === Constants.GAP_OUTPUTS_LABELS.IntervalBetweenApplications) ? true : false;
  }

  getProjectOutputs(project: Project) {
    this.selectedOutputs = [];
    if (!project) return;
    if (project.useRateGHA) this.selectedOutputs.push(this.gapOuputs.find(x => x.label === Constants.GAP_OUTPUTS_LABELS.RateGHA));
    if (project.useRateLBACRE) this.selectedOutputs.push(this.gapOuputs.find(x => x.label === Constants.GAP_OUTPUTS_LABELS.RateLBAcre));
    if (project.useBBCH) this.selectedOutputs.push(this.gapOuputs.find(x => x.label === Constants.GAP_OUTPUTS_LABELS.BBCH));
    if (project.useApplicationWindow) this.selectedOutputs.push(this.gapOuputs.find(x => x.label === Constants.GAP_OUTPUTS_LABELS.ApplicationWindow));
    if (project.useNumberOfApplications) this.selectedOutputs.push(this.gapOuputs.find(x => x.label === Constants.GAP_OUTPUTS_LABELS.NumberOfApplications));
    if (project.useIntervalBetweenApplications) this.selectedOutputs.push(this.gapOuputs.find(x => x.label === Constants.GAP_OUTPUTS_LABELS.IntervalBetweenApplications));
  }

  async getProjectsXCompoundXModels(project?: Project): Promise<void> {
    if (!project) {
      this.selectedProject = project;
      this.setupLoading = false;
      return;
    }
    let newProjectXCompoundXModel: ProjectXCompoundXModel[] = [];
    await firstValueFrom(this.selectedProjectAPIService.getProjectXCompoundXModels(project.projectPk, this.disciplinePk).pipe(take(1))).then(models => {
      newProjectXCompoundXModel = [...newProjectXCompoundXModel, ...models];
    });
    if (this.secondDisciplinePk) {
      await firstValueFrom(this.selectedProjectAPIService.getProjectXCompoundXModels(project.projectPk, this.secondDisciplinePk).pipe(take(1))).then(models => {
        newProjectXCompoundXModel = [...newProjectXCompoundXModel, ...models];
      });
    }

    project.projectXCompoundXModel = [...newProjectXCompoundXModel]

    this.getProjectOutputs(project);
    this.selectedProject = project;
    this.isProjectOwnershipValid = this.userLogicService.verifyProjectOwnership(this.selectedProject?.createdBy, this.environmentalAssessment);
    this.setupLoading = false;
  }

  validateSetupInfoInProject(event: boolean) {
    this.validSetup = event;
    this.validateSetup();
  }

  validateAlternativeNameCompoundState(event: boolean) {
    this.isValidCompoundName = event;
    this.validateSetup();
  }

  showAlternativeNameCompoundLabel(event: boolean) {
    this.showAlternativeNameLabel = event;
  }

  validateSetupModelInProject(event: boolean) {
    this.validSetupModel = event;
    this.validateSetup();
  }

  validateSetup() {
    this.isSetupValidEvent.emit(this.validSetup && this.validSetupModel && this.isValidCompoundName);
  }

  private isMetaboliteDisabled(metabolite: Metabolite): boolean {
    if (!this.selectedProject) return false;
    return (metabolite.selectedInProjects?.includes(this.selectedProject?.projectPk) &&
      (metabolite.showInEea === 'No' && !this.isHHRA) || (metabolite.showInHhra === 'No' && this.isHHRA))
  }
}

