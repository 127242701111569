import { Component, DestroyRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, computed, effect, inject } from '@angular/core';
import { Constants } from '../../utils/constants';
import { DataSet } from '../../models/echo/data-set';
import { EEAEndpointsByCompartmentLogicService } from 'src/app/environmental-exposure-assessment/eea-endpoints/eea-endpoints-by-compartment/eea-endpoints-by-compartment.logic.service';
import { EndpointDatasetColDefService } from './endpoint-dataset.coldef';
import { EndpointDatasetGridTransaction, EndpointDatasetParamsData, EndpointDatasetSaveStatus } from './endpoint-dataset.model';
import { EndpointDatasetLogicService } from './endpoint-dataset.logic.service';
import { GridComponent } from '../grid/grid.component';
import { ImportDataSet } from '../../models/echo/import-data-set';
import { Project } from '../../models/project';
import { ProjectXCompoundXModel } from '../../models/project-x-compound-x-model';
import { TabMenuLogicService } from '../../services/tab-menu.logic.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Utils } from '../../utils/utils';
import { UseEndpointDatasetComponent } from '../use-endpoint-dataset/use-endpoint-dataset.component';
import Swal from 'sweetalert2';
import { EraEcotoxImportPopupParameters } from 'src/app/environmental-risk-assessment/era-endpoints/era-ecotox-import-popup/era-ecotox-import-popup.interface';
import { pipe, take } from 'rxjs';
import { RowSelectedEvent, SelectionChangedEvent } from 'ag-grid-community';
import { UseEndpointDatasetLogicService } from '../use-endpoint-dataset/use-endpoint-dataset-logic-service';
import { UserLogicService } from '../../services/user.logic.service';
import { SelectedProjectApiService } from '../selected-project/selected-project.api.service';
import { MetaboliteXModel } from '../../models/echo/metabolite';
import { Endpoint } from '../../models/endpoint';
import { ExcelWriterService } from '../../services/excel-writer.service';
import { ChildLevel } from 'src/app/environmental-exposure-assessment/eea-endpoints/eea-endpoints-parameters-pwc/eea-endpoints-parameters-pwc.inteface';
import { EeaEndpointsParametersPwcLogicService } from 'src/app/environmental-exposure-assessment/eea-endpoints/eea-endpoints-parameters-pwc/eea-endpoints-parameters-pwc.logic.service';
import { DragDropMetaboliteComponent } from '../ordering-metabolite/ordering-metabolite.component';

@Component({
  selector: 'app-endpoint-dataset',
  templateUrl: './endpoint-dataset.component.html',
  styleUrls: ['./endpoint-dataset.component.css'],
})
export class EndpointDatasetComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public environmentalAssessment!: string;
  @Input() public isProjectOwnershipValid: boolean = false;
  @Input() public menuService!: TabMenuLogicService;
  @Input() public selectedProject?: Project;
  @Input() public subTabMenuService!: TabMenuLogicService;
  @Input() public savedEraDatasetPk?: number;
  @Input() public isSubstanceProperties?: boolean = false;
  @Input() compartment: string = '';
  @Output() public datasetData = new EventEmitter<DataSet>();
  @Output() public isValid = new EventEmitter<boolean>();
  @Output() public showDetail = new EventEmitter<boolean>();
  @ViewChild('grid') private grid!: GridComponent;
  @ViewChild('appDialog') useExistingDSDialog!: UseEndpointDatasetComponent;
  @ViewChild('appDragDropMetabolite') appDragDropMetabolite!: DragDropMetaboliteComponent;
  public blockUi: boolean = false;
  public columnDefs: any = [];
  public isLoading: boolean = false;
  public projectPk: number = 0;
  public rowData: DataSet[] = [];
  public originalRowData: DataSet[] = [];
  public metabolitesByCompartment: MetaboliteXModel[] = [];
  public showControls: boolean = true;
  public eraEcotoxImportPopupParameters?: EraEcotoxImportPopupParameters;
  private projects: Project[] = [];
  private destroyRef = inject(DestroyRef);
  private projectXCompoundXModel: ProjectXCompoundXModel[] = [];
  private saveRef?: string;
  private selectedDataSetObservable: DataSet | undefined;
  private downloadCompleted = new EventEmitter<void>();
  public saveStatusChangeEffect = effect(async () => {
    if (this.logicService.saveStatus() === EndpointDatasetSaveStatus.Saved) {
      setTimeout(async () => {
        await this.initComponent();
        this.logicService.setSaveStatus(EndpointDatasetSaveStatus.NormalSave);
      }, 500);
    }
  });

  private eeaEndpointsParametersPwcLogicService = inject(EeaEndpointsParametersPwcLogicService);

  constructor(
    private readonly columnDefsService: EndpointDatasetColDefService,
    private readonly endpointByCompartmentLogicService: EEAEndpointsByCompartmentLogicService,
    public readonly logicService: EndpointDatasetLogicService,
    private readonly useDataSetLogicService: UseEndpointDatasetLogicService,
    private readonly userLogicService: UserLogicService,
    private readonly selectedProjectAPIService: SelectedProjectApiService,
    private readonly excelWriterService: ExcelWriterService,
  ) { }

  public async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes['savedEraDatasetPk'])
      await this.updateStatusFlagWhenEraDestroy();
  }


  private async initComponent() {
    this.isLoading = true;
    this.destructureSelectedProject();
    this.setProjectXCompoundXModelLogicService();
    this.logicService.setProjectPk(this.projectPk);
    this.initSubscribes();
    await this.setRowData();
    await this.logicService.setMoleculesFromProjectModels(this.projectXCompoundXModel);
    await this.logicService.getDataseByMoleculesPks(this.getProjectMoleculePks(), this.isEEA());
    this.setColumnDef();
    this.getSelectedDataSetObservable();
    this.setSelectedDataSetObservable();
    this.refreshSelectedValuesTimeout();
    this.isLoading = false;
  }

  public async ngOnInit(): Promise<void> {
    if (this.logicService.saveStatus() === EndpointDatasetSaveStatus.NormalSave)
      await this.initComponent();
  }

  private destructureSelectedProject(): void {

    if (!this.selectedProject) return;

    const { projectXCompoundXModel, projectPk } = this.selectedProject!;
    this.projectPk = projectPk;
    this.projectXCompoundXModel = projectXCompoundXModel!;
  }

  private setProjectXCompoundXModelLogicService(): void {
    this.logicService.projectXCompoundXModel = this.projectXCompoundXModel;
  }

  private initSubscribes(): void {
    this.menuService.activeIndexChanged
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.saveRef = this.menuService.saveRef;
        this.prepareToSave(EndpointDatasetSaveStatus.Saved);
      });

    this.subTabMenuService.activeIndexChanged
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.saveRef = this.subTabMenuService.saveRef;
        this.prepareToSave(EndpointDatasetSaveStatus.Saved);
      });

    this.selectedProjectAPIService.projects.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(projects => this.projects = projects);
  }

  public async setRowData(): Promise<void> {
    this.rowData = await this.logicService.getDatasets(this.projectPk, this.environmentalAssessment);
    this.rowData.forEach(r => r.compoundPk = r.substanceType == Constants.SUBSTANCE_TYPES.METABOLITE ? r.metabolitePk : r.moleculePk);
    this.originalRowData = [...this.rowData];
    if (!this.grid) return;
    this.refreshSelectedValuesTimeout();
  }

  private isValidCompound(compoundPk: number | undefined): boolean {
    if (compoundPk == null) return true;
    let compound = this.logicService.parentDropDownOptions.find(option => option.compoundPk == compoundPk);
    const currentModels: string[] | undefined = Constants.SPECIFIC_MODELS_BY_COMPARTMENT.find(m => m.compartment == this.compartment)?.models;
    const metabolites = this.projectXCompoundXModel?.filter(p => p.MetabolitePk != null && currentModels?.includes(p.ModelName!))?.flatMap(p => p.MetabolitePk!);
    const molecules = this.projectXCompoundXModel?.filter(p => p.MoleculePk != null && currentModels?.includes(p.ModelName!))?.flatMap(p => p.MoleculePk!);
    return compound?.compoundType === Constants.SUBSTANCE_TYPES.ACTIVE ? molecules.some(pk => pk == compoundPk) : metabolites.some(pk => pk == compoundPk);
  }

  private getProjectMoleculePks(): number[] {
    return Array.from(
      new Set(this.projectXCompoundXModel.map((p) => p.MoleculePk!))
    );
  }

  private setColumnDef(): void {
    if (this.isSubstanceProperties) {
      this.columnDefs =
        this.columnDefsService.getColumnsDefinitionSubstancePropertiesOPEX(
          this.isProjectOwnershipValid,
          this.projects
        );
    } else {
      this.columnDefs = this.columnDefsService.getColumnsDefinition(
        this.isProjectOwnershipValid,
        this.projects,
        this.selectedProject!,
        this.compartment,
        this.environmentalAssessment,
      );
    }
  }

  private async prepareToSave(saveStatus: EndpointDatasetSaveStatus): Promise<void> {
    let transactionList = this.grid?.transactionList as unknown as EndpointDatasetGridTransaction[];

    if (transactionList === undefined) {
      this.menuAndSubMenuSetSuccess(true);
      return;
    }

    transactionList.forEach((transaction) => {
      const dataset: DataSet = this.logicService.transformRowGridToDataset(
        transaction.row,
        this.environmentalAssessment,
        this.projectPk
      );
      transaction.row = dataset;
    });
    if (transactionList.length > 0) {
      await this.save(transactionList, saveStatus);
    }
  }

  private async save(dataTransaction: EndpointDatasetGridTransaction[], saveStatus: EndpointDatasetSaveStatus): Promise<void> {
    await this.logicService.save(dataTransaction, saveStatus)
    this.saveRef === Constants.WIZARD_MENU_REF.Master ? this.menuService.setSuccess(true) : this.subTabMenuService.setSuccess(true);
    await this.refreshImportsDatasets();
  }

  private menuAndSubMenuSetSuccess(success: boolean): void {
    this.menuService.setSuccess(success);
    this.subTabMenuService.setSuccess(success);
  }

  public onAdd(): void {
    this.grid.setLastRowSelected('useInProject');
    this.refreshSelectedValues();
    this.enableControls(false);
  }

  public onDropdownChange(data: any): void {
    let row: EndpointDatasetParamsData = data.row;
    if (data.field !== 'daughterPk' && data.field !== 'granddaughterPk') {
      this.updateSelectedParentCompoundDataSet(data);
    }

    if (data.field == 'daughterPk') {
      if (data.value == -1) {
        Swal.fire({
          text: 'If you unselect the Daughter, the Granddaughter will be unselected too. Do you want to proceed?',
          showCancelButton: true,
          confirmButtonColor: '#0069be',
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          icon: 'warning'
        }).then((result) => {
          if (result.value) {
            data.row.daughterPk = null;
            data.row.granddaughterPk = null;
            this.grid.gridApi.redrawRows();
          } else {
            data.row.daughterPk = data.params.value;
            this.grid.gridApi.redrawRows();
          }
          this.setChildLevelPwcParameters(data);
        });
      }

      if (data.row.daughterPk === data.row.granddaughterPk && data.row.daughterPk > 0)
        data.row.granddaughterPk = null;

    }

    if (data.field == 'granddaughterPk' && data.value == -1) {
      data.row.granddaughterPk = null;
    }

    this.setChildLevelPwcParameters(data);




    let compoundPk = this.isEEA() ? row.compoundPk : row.moleculePk;
    this.checkIfTheDatasetIsValid(row.description, compoundPk, row.dataSetPk, row.substanceType);
  }


  public updateChildLevelValues(data: any): void {

  }

  public onBlurInputText({ row }: { row: EndpointDatasetParamsData }): void {
    let compoundPk = this.isEEA() ? row.compoundPk : row.moleculePk;
    this.checkIfTheDatasetIsValid(row.description, compoundPk, row.dataSetPk, row.substanceType);
  }

  private updateSelectedParentCompoundDataSet(modifiedData: any): void {
    if (this.isEEA()) {
      const eventCompoundPk: number = modifiedData.value;
      const selectedOption = this.logicService.parentDropDownOptions.find(option => option.compoundPk == eventCompoundPk)!;
      const { compoundPk, compoundType, compoundName } = selectedOption;
      const moleculePk: number | undefined = compoundType == Constants.SUBSTANCE_TYPES.ACTIVE ? compoundPk : undefined;
      const metabolitePk: number | undefined = compoundType == Constants.SUBSTANCE_TYPES.METABOLITE ? compoundPk : undefined;
      modifiedData.row.moleculePk = moleculePk;
      modifiedData.row.metabolitePk = metabolitePk;
      modifiedData.row.substanceType = compoundType;
      if (compoundType == Constants.SUBSTANCE_TYPES.METABOLITE) {
        modifiedData.row.moleculePk = this.projectXCompoundXModel.find(p => p.MetabolitePk == compoundPk)?.MoleculePk;
      }
    }
  }

  protected isEEA(): boolean {
    return this.environmentalAssessment === Constants.ENVIRONMENTAL_ASSESSMENTS.ENVIRONMENTAL_EXPOSURE_ASSESSMENT && this.logicService.isInverseModeling === false;
  }

  public showExcelExportButton(): boolean {
    return ![Constants.ENVIRONMENTAL_ASSESSMENTS.ENVIRONMENTAL_RISK_ASSESSMENT, Constants.ENVIRONMENTAL_ASSESSMENTS.HUMAN_HEALTH_RISK_ASSESSMENT].includes(this.environmentalAssessment)
    && this.logicService.isInverseModeling === false;
  }

  public async onDelete(dataTransaction: EndpointDatasetGridTransaction[]): Promise<void> {
    const deletedRow = dataTransaction.find((row) => row.transactionType === Constants.TRANSACTION_TYPE.DELETE);
    const datasetPk: number = deletedRow?.row?.dataSetPk ?? 0;
    const isExpanded: boolean | undefined = deletedRow?.row.isExpanded;
    const isReferencedDataSet: boolean = this.isReferencedDataSet(deletedRow?.row?.originalProject);
    const hasLinkToOtherProjects = await this.logicService.existsDatasetByPkWithFk(this.selectedProject?.projectPk!, datasetPk);

    const deleteDataset = async () => {
      if (datasetPk > 0) await this.deleteSavedDataset(datasetPk, isExpanded, isReferencedDataSet);
      else if (deletedRow != undefined) {
        this.rowData = this.rowData.filter((r) => r.deleted === undefined || r.deleted === false);
        this.refreshSelectedValuesTimeout();
      }

      else this.rowData.splice(0, 1);

      if (isExpanded)
        this.showControls = true;
    };

    if (hasLinkToOtherProjects > 0 && !isReferencedDataSet) {
      const result = await Swal.fire({
        title: 'This dataset is being used in other projects',
        text: 'If you delete it, it will be deleted from the other projects as well. Do you still want to proceed?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it',
      });

      if (result.isConfirmed) {
        await deleteDataset();
      } else {
        Swal.fire('Cancelled', 'Your dataset is safe :)', 'error');
        await this.setRowData();
      }
    } else {
      await deleteDataset();
    }
    this.refreshSelectedValues();
    if (deletedRow) {
      let compoundPk = this.isEEA() ? deletedRow.row?.compoundPk : deletedRow.row?.moleculePk;
      this.checkIfTheDatasetIsValid('', compoundPk, deletedRow.row?.dataSetPk, deletedRow.row?.substanceType);
    }
  }

  private showCompoundErrorAlert(): void {
    Swal.fire({
      text: "The parent you want to use hasn't been set up for this compartment yet. Please add it in the setup and try again.",
      confirmButtonColor: "#3085d6",
      icon: "warning",
    });
  }

  private isReferencedDataSet(originalProjectPk: number | undefined): boolean {
    if (!originalProjectPk) return false;
    return originalProjectPk ? originalProjectPk !== this.logicService.projectPk ? true : false : false;
  }

  public async onButtonExpandClick({ data }: { data: EndpointDatasetParamsData }, isExpanded?: boolean): Promise<void> {
    let compoundPk = (data.substanceType === Constants.SUBSTANCE_TYPES.ACTIVE || data.substanceType == null) ? data.moleculePk : data.metabolitePk;

    if (await this.dataSetHasLinkToOtherProjects(data)) {
      if (this.isDataSetOwnership(data) && data.isExpanded) {
        Utils.showWarningMessage('This dataset is being used in other projects.', 'Any changes will be reflected in the other projects as well.');
      }
    }
    if (isExpanded != undefined && isExpanded)
      data.isExpanded = true;

    if (data.isExpanded) {
      this.logicService.setIsDatasetExpanded(true);
      this.updateSelectedDataSetObservable(data);
      this.showControls = false;
      this.rowData = this.rowData.filter((r) => r.moleculePk == data.moleculePk && r.description == data.description && r.dataSetPk == data.dataSetPk);
      await this.expandDataset(data);
      this.refreshSelectedValuesTimeout();
    }
    else {
      this.logicService.setIsDatasetExpanded(false);
      this.updateSelectedDataSetObservable(undefined);
      this.showControls = true;
      await this.collapseDataset(data);
    }

  }

  private isDataSetOwnership(dataSet: any): boolean {
    return dataSet?.createdBy != null ? dataSet?.createdBy === this.userLogicService?.profile?.displayName :
      this.projects.find((project) => project.projectPk === dataSet.originalProject)?.createdBy === this.userLogicService?.profile?.displayName;
  }

  private async expandDataset(data: EndpointDatasetParamsData): Promise<void> {
    let transactionList = this.grid?.transactionList as unknown as EndpointDatasetGridTransaction[];

    if (transactionList != undefined && transactionList.some((t) => t.row.modified))
      await this.saveExpandedModifiedDataset(data);
    else this.emmitExpandedDataset(data as DataSet);

  }

  private async dataSetHasLinkToOtherProjects(data: EndpointDatasetParamsData): Promise<boolean> {
    if (data.newRow) return false;
    const isReferencedDataSet = data.originalProject && data.originalProject != this.projectPk;
    const projectPk = isReferencedDataSet ? this.selectedProject?.projectPk : data.projectPk!;
    const hasLinkToOtherProjects = await this.logicService.existsDatasetByPkWithFk(
      projectPk!,
      data.dataSetPk!
    );
    return hasLinkToOtherProjects > 0;
  }

  private async collapseDataset(data: EndpointDatasetParamsData): Promise<void> {
    this.isLoading = true;
    if (data.modified != undefined && data.modified) {
      this.saveCollapsedModifiedDataset();
    } else {
      this.emitCollapsedDataset();
    }
  }

  private async saveCollapsedModifiedDataset(): Promise<void> {
    this.showDetail.emit(false);
    await this.prepareToSave(EndpointDatasetSaveStatus.NormalSave);
    this.getDatasetsAndSaveEndpoints();
  }

  private async saveExpandedModifiedDataset(data: EndpointDatasetParamsData): Promise<void> {
    await this.prepareToSave(EndpointDatasetSaveStatus.NormalSave);
    data.modified = false;
    const dataset = await this.logicService.getDatasetByProjectpkMoleculePkAndName(data.description!, data.moleculePk!, this.projectPk);
    dataset.isExpanded = true;
    dataset.compoundPk = dataset.substanceType == Constants.SUBSTANCE_TYPES.ACTIVE ? dataset.moleculePk : dataset.metabolitePk;
    this.rowData = [dataset];
    this.emmitExpandedDataset(dataset);
    this.grid.transactionList = [];
    await this.refreshImportsDatasets();
  }

  private async emitCollapsedDataset(): Promise<void> {
    this.getDatasetsAndSaveEndpoints();
    this.showDetail.emit(false);
  }

  private emmitExpandedDataset(dataset: DataSet): void {
    dataset.activeIngredient = this.logicService.allMolecules.find((m) => m.moleculePk == dataset.moleculePk)!.moleculeName;
    this.updateSelectedDataSetObservable(dataset);
    this.datasetData.emit(dataset);
    this.showDetail.emit(true);
  }

  private async getDatasetsAndSaveEndpoints(): Promise<void> {
    await this.setRowData();
    this.isLoading = false;
    if (this.environmentalAssessment == Constants.ENVIRONMENTAL_ASSESSMENTS.ENVIRONMENTAL_EXPOSURE_ASSESSMENT ||
      this.environmentalAssessment == Constants.ENVIRONMENTAL_ASSESSMENTS.HUMAN_HEALTH_RISK_ASSESSMENT) {
      this.endpointByCompartmentLogicService.runSaveObservable();
    }
    await this.refreshImportsDatasets();
    this.refreshSelectedValuesTimeout();
  }

  private async deleteSavedDataset(datasetPk: number, isExpanded: boolean | undefined, isReferencedDataSet: boolean = false): Promise<void> {
    this.blockUi = true;
    isReferencedDataSet ? await this.useDataSetLogicService.removeDataSetByProjectAndPK(this.projectPk, [datasetPk]) : await this.logicService.deleteDatasetWithEndpoints(datasetPk);
    this.rowData = this.rowData.filter((r) => r.dataSetPk != datasetPk);
    if (isExpanded != undefined && isExpanded) {
      this.showDetail.emit(false);
      await this.setRowData();
    }
    this.blockUi = false;
    await this.refreshImportsDatasets();
  }

  private enableControls(enable: boolean): void {
    this.isValid.emit(enable);
    this.showControls = enable;
    this.grid.useAddAction = enable;
    this.grid.columnDefs.find((c: any) => c.colId == 'show').cellRendererParams.disabled = !enable;
    this.grid.columnDefs.find((c: any) => c.colId == 'importDataset').cellRendererParams.disabled = !enable;
    this.grid.gridApi.setColumnDefs(this.columnDefs);
  }

  private async checkIfTheDatasetIsValid(datasetName: string | undefined, compoundPk: number | undefined, datasetPk: number | undefined, substanceType: string | undefined): Promise<void> {
    const isEEA = this.isEEA();
    const invalidRows = this.rowData.some(row => row.description == undefined || row.description == '' || (isEEA ? row.compoundPk == -1 : row.moleculePk == -1) || (isEEA ? row.compoundPk == undefined : row.moleculePk == undefined))
    const transactionOcurrences = this.logicService.getTransactionOccurrences(this.rowData);
    const duplicateDatasetsInTransaction = this.logicService.duplicateDataInRecord(transactionOcurrences);
    datasetPk = datasetPk ?? -1;

    let existsDatasetProjectPk: number = -1;

    if (datasetName != undefined && datasetName != '' && compoundPk != undefined && substanceType != null) {
      this.blockUi = true;
      existsDatasetProjectPk = await this.logicService.existsDatasetByName(datasetName, compoundPk, datasetPk, substanceType);
      this.blockUi = false;

      if (duplicateDatasetsInTransaction)
        Utils.showErrorMessage('The Dataset name already exists on selected Compound.', 'Please use another name');
      else if (existsDatasetProjectPk > 0)
        this.showExistingDatasetMessage(existsDatasetProjectPk);
    }
    let groupLimitExceeded = false;

    if (this.isSubstanceProperties) {
      const groupCounts = this.rowData.reduce((acc: any, row: any) => {
        acc[row.calculatorGroup] = (acc[row.calculatorGroup] || 0) + 1;
        return acc;
      }, {});
      groupLimitExceeded = Object.values(groupCounts).some((count: any) => {
        return count > 4;
      });
      if (groupLimitExceeded) {
        Utils.showErrorMessage(
          'A group cannot have more than 4 items.',
          'Please adjust the groups.'
        );
      }
    }

    if (invalidRows || existsDatasetProjectPk > 0 || duplicateDatasetsInTransaction || groupLimitExceeded)
      this.enableControls(false);
    else {
      this.enableControls(true);
    }
  }

  private async refreshImportsDatasets(): Promise<void> {
    this.blockUi = true;
    await this.logicService.getDataseByMoleculesPks(this.getProjectMoleculePks());
    this.grid.gridApi.redrawRows();
    this.blockUi = false;
  }

  public async onCopyDataset(datasetToImport: ImportDataSet): Promise<void> {

    if (datasetToImport.destinationDataSetPk === -1) {
      await this.prepareToSave(EndpointDatasetSaveStatus.NormalSave);
      const savedDs = await this.logicService.getDatasetByProjectpkMoleculePkAndName(datasetToImport.description, datasetToImport.moleculePk, this.selectedProject!.projectPk);

      if (!savedDs.dataSetPk) return;

      datasetToImport.destinationDataSetPk = savedDs.dataSetPk;
      const rowNode = this.grid.gridApi.getRowNode(datasetToImport.rowIndex);
      rowNode.data.dataSetPk = savedDs.dataSetPk;
      this.grid.CreateTransaction(rowNode.id, rowNode.id, '', rowNode.data);
    }

    const hasEndpoints: boolean = datasetToImport.hasEndpoints ?? false;
    const originDatasetDescription = this.logicService.datasetsToImport.find(d => d.dataSetPk == datasetToImport.originDataSetPk)?.description;

    if (this.environmentalAssessment == Constants.ENVIRONMENTAL_ASSESSMENTS.ENVIRONMENTAL_RISK_ASSESSMENT && originDatasetDescription?.toLowerCase().startsWith('echo-')) {
      let taxonGroup: string = '';
      this.subTabMenuService.activeItem.subscribe(value => {
        taxonGroup = value.label;
      }, pipe(take(1)));

      this.eraEcotoxImportPopupParameters = {
        datasetToImport,
        taxonGroup,
        geography: this.selectedProject!.geography!
      }
      await this.refreshImportsDatasets();
    }
    else {
      const messageText: string = hasEndpoints ? Constants.TITLE_CONFIRM_TO_OVERWRITE : Constants.TITLE_CONFIRM_TO_COPY;
      const actionResult = await Utils.showConfirmationMessage(messageText);
      if (actionResult.isConfirmed) {
        await this.blockUiAndSave(datasetToImport);
      }
    }
  }

  private async showExistingDatasetMessage(
    existsDatasetProjectPk: number
  ): Promise<void> {
    const { name, description, projectPk, createdBy } =
      await this.logicService.getProjectByPk(existsDatasetProjectPk);
    Utils.showErrorMessageHtml(
      'The Dataset name already exists on selected active ingredient.',
      `<br> <b> Project:</b> ${name} - <b>${projectPk}</b>
     <br> <b> Created by:</b> ${createdBy}
     <br> <b> Description:</b> ${description || 'No description'}
     <br> <b><u>Please use another name</u></b>`
    );
  }

  private async ImportDataset(datasetToImport: ImportDataSet): Promise<void> {
    datasetToImport.source = this.environmentalAssessment;
    datasetToImport.dataToCopy = this.logicService.getDataToCopy(this.environmentalAssessment);
    datasetToImport.endpointNames = this.logicService.getEndpointsByModelsSelected();

    await this.logicService.importDataSetInfo(datasetToImport);
    if (this.grid?.transactionList) {
      let transactionList = this.grid?.transactionList as unknown as EndpointDatasetGridTransaction[];
      this.grid.transactionList = transactionList.filter((t) => t.row.description != datasetToImport.description) as any;
      await this.prepareToSave(EndpointDatasetSaveStatus.NormalSave);
      this.grid.transactionList = [];
    }
  }

  private updateSelectedDataSetObservable(dataSet: DataSet | undefined): void {
    this.logicService.updateSelectedDataSet(dataSet);
  }

  private getSelectedDataSetObservable(): void {
    this.logicService.selectedDataSet$.subscribe((value) => {
      if (this.rowData.some((r) => r.dataSetPk == value?.dataSetPk))
        this.selectedDataSetObservable = value;
      else this.selectedDataSetObservable = undefined;
    });
  }

  private setSelectedDataSetObservable(): void {
    if (this.selectedDataSetObservable) {
      this.rowData.forEach((r) => {
        if (r.dataSetPk == this.selectedDataSetObservable?.dataSetPk)
          r.isExpanded = true;
      });
      this.onButtonExpandClick({ data: this.selectedDataSetObservable }, true);
    }
  }

  private async updateStatusFlagWhenEraDestroy(): Promise<void> {
    const row = this.rowData.find((r) => r.dataSetPk == this.savedEraDatasetPk);
    if (row != undefined) {
      this.isLoading = true;
      await this.setRowData();
      this.isLoading = false;
    }
  }

  public onImportEchoEra(datasetToImport: ImportDataSet): void {
    datasetToImport.importedFromEcho = true;
    this.blockUiAndSave(datasetToImport);
  }

  useExistingDS(){
    this.prepareToSave(EndpointDatasetSaveStatus.NormalSave);
    this.useExistingDSDialog.showDialog(true, this.selectedProject, this.environmentalAssessment);
  }

  private async blockUiAndSave(datasetToImport: ImportDataSet): Promise<void> {
    this.blockUi = true;
    this.grid.selectOrUnselectRow(datasetToImport.rowIndex, true);
    await this.ImportDataset(datasetToImport);
    await this.setRowData();
    this.blockUi = false;
    await this.refreshImportsDatasets();
    Utils.showSuccessMessage(Constants.MESSAGE_COPY_SUCCESSFULLY);
  }

  private refreshSelectedValuesTimeout(): void {
    setTimeout(() => {
      this.refreshSelectedValues();
    }, 75);
  }

  private refreshSelectedValues(): void {
    if (!this.grid) return;
    this.grid.refreshSelectedValues('useInProject');
  }

  public onSelectedRow(event: RowSelectedEvent): void {
    if (!this.grid) return;
    this.createTransactionForSelectedRow(event.node);
  }

  public onSelectionChanged(event: SelectionChangedEvent) {
    if (event.source !== 'uiSelectAll' || !this.grid) return;
    this.grid.gridApi.forEachNode((node: any) => {
      this.createTransactionForSelectedRow(node);
    });
  }

  private createTransactionForSelectedRow(node: any): void {
    const { id, data } = node;
    data.useInProject = node.isSelected();
    this.grid.CreateTransaction(id, id, data.useInProject, data);
  }

  protected async serverExcelExport() {
    var data: Endpoint[] = await this.logicService.getAllProjectEndpointsInformation(this.selectedProject?.projectPk, Constants.ENVIRONMENTAL_ASSESSMENTS.ENVIRONMENTAL_EXPOSURE_ASSESSMENT);
    const transformedJson = this.transformEndpointList(data);
    this.excelWriterService.exportToExcel(transformedJson, this.selectedProject?.name + " Endpoints", "Compartment");
    this.downloadCompleted.emit();
  }

  private transformEndpointList(endpoints: Endpoint[]) {
    const processedEndpoints = this.expandMetabolites(endpoints);

    const filteredEndpoints = processedEndpoints.filter(endpoint => {
      const endpointType = endpoint.endpointTypePk?.toString();
      if (this.selectedProject?.geography === Constants.GEOGRAPHYS.UK || this.selectedProject?.geography === Constants.GEOGRAPHYS.USA) {
        return endpointType === Constants.COMPARTMENTS.SURFACE_WATER;
      }
      return endpointType !== undefined &&
        [Constants.COMPARTMENTS.SURFACE_WATER, Constants.COMPARTMENTS.GROUND_WATER, Constants.COMPARTMENTS.SOIL]
          .includes(endpointType);
    });
    const clonedEndpoints = this.cloneEndpoints(filteredEndpoints).sort((a, b) => {
      return (a.dataSetPk ?? 0) - (b.dataSetPk ?? 0);
    });

    return clonedEndpoints.map(endpoint => this.transformEndpoint(endpoint));
  }

  private expandMetabolites(endpoints: Endpoint[]): Endpoint[] {
    const expandedEndpoints: Endpoint[] = [];
    endpoints.forEach(endpoint => {
      if (endpoint.substanceType === Constants.SUBSTANCE_TYPES.METABOLITE) {
        (endpoint.metabolites || []).forEach(metabolite => {
          expandedEndpoints.push({
            ...metabolite,
            dataSetPk: endpoint.dataSetPk
          });
        });
      } else {
        expandedEndpoints.push(endpoint);
      }
    });

    return expandedEndpoints;
  }

  private cloneEndpoints(endpoints: Endpoint[]): Endpoint[] {
    if (this.selectedProject?.geography == Constants.GEOGRAPHYS.EUROPE_UNION) {
      const clonedEndpoints: Endpoint[] = [];
      const existingCombinations = new Map<string, Set<string>>();

      endpoints.forEach(endpoint => {
        const endpointType = endpoint.endpointTypePk?.toString() || '';
        const datasetCompoundKey = `${endpoint.dataSetPk}-${endpoint.substanceName}`;

        if (!existingCombinations.has(endpointType)) {
          existingCombinations.set(endpointType, new Set<string>());
        }

        if (!existingCombinations.get(endpointType)!.has(datasetCompoundKey)) {
          const originalTemplate = this.getTemplateForType(endpointType);
          const filteredOriginalGroupedEndpointFieldValues = endpoint.groupedEndpointFieldValues.filter(field =>
            field.key && originalTemplate.has(field.key)
          );

          clonedEndpoints.push({
            ...endpoint,
            groupedEndpointFieldValues: filteredOriginalGroupedEndpointFieldValues
          });

          existingCombinations.get(endpointType)!.add(datasetCompoundKey);
        }

        const typesToClone = [
          Constants.COMPARTMENTS.SURFACE_WATER,
          Constants.COMPARTMENTS.GROUND_WATER,
          Constants.COMPARTMENTS.SOIL
        ];
        typesToClone.forEach(type => {
          if (type === endpointType || existingCombinations.get(type)?.has(datasetCompoundKey) || this.doesOriginalCombinationExist(endpoints, type, datasetCompoundKey)) {
            return;
          }

          const template = this.getTemplateForType(type);
          const filteredGroupedEndpointFieldValues = endpoint.groupedEndpointFieldValues.filter(field =>
            field.key && template.has(field.key)
          );

          const { precursors, ...endpointWithoutPrecursors } = endpoint;

          const clonedEndpoint = {
            ...endpointWithoutPrecursors,
            endpointTypePk: type,
            groupedEndpointFieldValues: filteredGroupedEndpointFieldValues
          };

          clonedEndpoints.push(clonedEndpoint as any);

          if (!existingCombinations.has(type)) {
            existingCombinations.set(type, new Set<string>());
          }
          existingCombinations.get(type)!.add(datasetCompoundKey);
        });
      });
      return clonedEndpoints;
    }
    return endpoints;
  }

  private doesOriginalCombinationExist(endpoints: Endpoint[], type: string, datasetCompoundKey: string): boolean {
    return endpoints.some(endpoint =>
      endpoint.endpointTypePk?.toString() === type &&
      `${endpoint.dataSetPk}-${endpoint.substanceName}` === datasetCompoundKey
    );
  }

  private getTemplateForType(type: string): Set<string> {
    const columns = Constants.templateColumnsByCompartment[type];
    if (typeof columns === 'object' && !Array.isArray(columns) && this.selectedProject?.geography) {
      return new Set(columns[this.selectedProject.geography] || []);
    }
    return new Set((Array.isArray(columns) ? columns : []) || []);
  }

  private transformEndpoint(endpoint: Endpoint) {
    const groupedFields: { [key: string]: number | string } = {};
    const parent = this.logicService.molecules.find(x => x.moleculePk === endpoint.moleculePk)?.moleculeName;
    const dataset = this.originalRowData.find(x => x.dataSetPk === endpoint.dataSetPk)?.description;

    Constants.endpointsExcelKeyOrder.forEach((key) => {
      const type = endpoint?.endpointTypePk?.toString() || "";
      const template = this.getTemplateForType(type);
      const filteredGroupedEndpointFieldValues = endpoint.groupedEndpointFieldValues.filter(field =>
        field.key && template.has(field.key)
      );
      const field = filteredGroupedEndpointFieldValues.find(field => field.key === key);
      if (field) {
        const newKey = Constants.endpointsExcelKeyReplacements[key] || key;
        groupedFields[newKey] = field.value ?? field.textValue ?? '';
      }
    });

    const precursors = this.selectedProject?.geography == Constants.GEOGRAPHYS.EUROPE_UNION ? endpoint.precursors?.map((precursor) => {
      let precursorKey = 'Precursor';
      let formationFractionKey = 'FF';
      if (endpoint.endpointTypePk?.toString() === Constants.COMPARTMENTS.SURFACE_WATER && precursor.precursorType) {
        const typeSuffix = precursor.precursorType.replace('fractionIn', '')
          .replace('SurfaceWater', 'SW')
          .replace('Groundwater', 'GW')
          .replace('Sediment', 'Sed');
        precursorKey = `${typeSuffix}_Precursor`;
        formationFractionKey = `${typeSuffix}_FF`;
      }
      return {
        [precursorKey]: precursor.substanceName,
        [formationFractionKey]: precursor.formationFraction,
      };
    }) : [];

    return {
      Project: this.selectedProject?.name,
      'Created by': this.selectedProject?.createdBy,
      'Parent': parent,
      'Dataset': dataset,
      'Compound': endpoint.substanceName,
      Compartment: endpoint.endpointTypePk,
      ...groupedFields,
      precursors,
    };
  }

  private setChildLevelPwcParameters(data: any): void {
    let childrenLevel: ChildLevel[] = [];

    if (data.row.daughterPk > 0)
      childrenLevel.push({
        childLevel: Constants.CHILD_LEVEL.DAUGHTER,
        metabolitePk: data.row.daughterPk,
        metaboliteName: this.logicService.metabolites.find(m => m.metabolitePk == data.row.daughterPk)!.metaboliteName,
        isActive: false
      });

    if (data.row.granddaughterPk > 0)
      childrenLevel.push({
        childLevel: Constants.CHILD_LEVEL.GRANDDAUGHTER,
        metabolitePk: data.row.granddaughterPk,
        metaboliteName: this.logicService.metabolites.find(m => m.metabolitePk == data.row.granddaughterPk)!.metaboliteName,
        isActive: false
      });
  }

  onClickSetButton(event: any) {
    this.appDragDropMetabolite.showDialog(event);
  }

  ngOnDestroy(): void {
    this.logicService.setIsDatasetExpanded(false);
  }

}
