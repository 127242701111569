<div class="pt-1 pr-5 pb-5 pl-5 center-content">
  <div id="swal-success-icon"></div>
  <p>The QC has Successfully Completed</p>
  <h2>Are you ready to run the UDL Overlap Tool?</h2>
  <div>
    <p-button [disabled]="false" styleClass="standard-button" class="p-button-outlined mr-2" label="Yes" (onClick)="RunModel()"></p-button>
    <p-button [disabled]="false" styleClass="standard-button" class="p-button-outlined ml-2" label="No" (onClick)="BackToSetup()"></p-button>
  </div>
</div>
<app-loading class="flex align-items-center justify-content-center" *ngIf="loading"></app-loading>
