import { ChangeDetectorRef, Component, Input, SimpleChanges } from '@angular/core';
import { Project } from 'src/app/shared/models/project';
import { EEAOutputProjectMacrogwInputsUsedInCalculationColdef } from './eea-output-project-macrogw-inputs-used-in-calculation-coldef';
import { EEAOutputProjectInputsApiService } from 'src/app/shared/services/eea-output-project-inputs.api.service';
import { EEAOutputProjectInputsLogicService } from 'src/app/shared/services/out-put-project/eea-output-project-logic.service';

@Component({
  selector: 'app-eea-output-project-macro-gw-run-inputs',
  templateUrl: './eea-output-project-macro-gw-run-inputs.component.html',
  styleUrls: ['./eea-output-project-macro-gw-run-inputs.component.css'],
})
export class EeaOutputProjectMacroGwRunInputsComponent {
  @Input() selectedProject?: Project;
  @Input() selectedModel?: string;
  columDef: any;
  loading: boolean = true;
  rowData: any;

  constructor(
    private columDefMacro: EEAOutputProjectMacrogwInputsUsedInCalculationColdef,
    private cdRef: ChangeDetectorRef,
    private readonly inputsUsedInCalculationsService: EEAOutputProjectInputsApiService
  ) { }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.columDef = this.columDefMacro.getColumnsDefinition();
    if (this.selectedProject) {
      this.inputsUsedInCalculationsService.getRunInputsUseInCalculationByModel(this.selectedProject?.projectPk!,this.selectedModel!).subscribe(async data => {
        if (data.length > 0) {
          this.rowData = data.filter(item=> item.model == this.selectedModel);
          this.loading = false;
        };
      });
      this.cdRef.detectChanges();
    }
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (this.selectedProject) {
      this.inputsUsedInCalculationsService.getRunInputsUseInCalculationByModel(this.selectedProject?.projectPk!,this.selectedModel!).subscribe(async data => {
        if (data.length > 0) {
          this.rowData = data.filter(item=> item.model == this.selectedModel);
          this.loading = false;
        };
      });
      this.cdRef.detectChanges();
    }
    this.loading = false;
  }
}
