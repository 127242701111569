<app-grid
  #grid
  ng-if="!show"
  [rowData]="rowData"
  [columnDefs]="columnsDef"
  [columnSizeMode]="columnSizeMode"
  [isMasterDetail]="true"
  [pagination]="true"
  [rowHeight]="rowHeight"
  [useAddAction]="isProjectOwnershipValid"
  addLabel="Add Scheme"
  [rowData]="rowData"
  [useDeleteAction]="true"
  [useModels]="true"
  [compartment]="compartment"
  [selectedProject]="selectedProject"
  [detailAutoRowHeigth]="true"
  [pagination]="true"
  [detailColumnDefs]="detailsColumnDef"
  (deleteEvent)="deleteRow($event)"
  (DeleteDetailEvent)="onDeleteDetails($event)"
  (selectionChangedEvent)="dropDownSelectionChanged($event)"
  (dropDownSelectionChangeEvent)="dropDownSelectionChanged($event)"
  (addEvent)="newRowAdded($event)"
  (onModelOptionsChangedEvent)="onModelOptionsChanged($event)"
  (onBlurInputText)="onBlurInputText($event)"
  (onClickButtonEvent)="onClickButtonEvent($event)"
  (listBoxSelectionChangeEvent)="listBoxSelectionChanged($event)"
  (onCheckboxSelectedEvent)="onCheckboxSelectedChanged($event)"
  (calendarSelectionChangeEvent)="onCalendarSelectionChanged($event)"
></app-grid>

<eea-gap-surfacewater-pwc-scenarios
  *ngIf="showScenarios()"
  [row]="selectedRow"
  [isProjectOwner]="isProjectOwnershipValid"
  (onSelectScenarios)="onSelectScenarios($event)"
/>

<app-eea-gap-surfacewater-pwc-optional-outputs
  *ngIf="showOptionalOutputs()"
  [row]="selectedRow"
  [isProjectOwner]="isProjectOwnershipValid"
  (onSelectOptionalOutputs)="onSelectOptionalOutputs($event)"
/>
