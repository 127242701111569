import { Injectable } from '@angular/core';
import { DropdownListRenderer } from 'src/app/shared/renderers/dropdown-list/dropdown-list.component';
import { DeleteButtonRenderer } from 'src/app/shared/renderers/delete-button/delete-button.component';
import { InputSearchRenderer } from 'src/app/shared/renderers/input-search/input-search.component';
import { ViewButtonRenderer } from 'src/app/shared/renderers/view-button/view-button.component';
import { CalendarRenderer } from 'src/app/shared/renderers/calendar/calendar.component';
import { Utils } from 'src/app/shared/utils/utils';
import { ColDef, RowNode } from 'ag-grid-community';
import { Constants } from 'src/app/shared/utils/constants';
import { CropDropdownListRenderer } from '../shared/renderers/crop-dropdown-list/crop-dropdown-list.component';
import { Project } from 'src/app/shared/models/project';
import { ColGroupDef } from 'ag-grid-community';
import { ButtonExpandGridRenderer } from 'src/app/shared/renderers/button-expand-grid/button-expand-grid.component';
import { InputNumberRenderer } from 'src/app/shared/renderers/input-number/input-number.component';
import { InputTextRenderer } from 'src/app/shared/renderers/input-text/input-text.component';
import { ListboxRenderer } from 'src/app/shared/renderers/listbox/listbox.component';
import { DuplicateButtonRenderer } from 'src/app/shared/renderers/duplicate-button/duplicate-button.component';
import { InputTextApplicationSchemeRenderer } from 'src/app/shared/renderers/input-text/input-text-application-scheme/input-text-application-scheme.component';

@Injectable({
    providedIn: 'root'
})
export class EEAGapSurfaceWaterColdef {

    constructor() { }

    public getColumnsDefinition(options: any, isProjectOwnershipValid: boolean): any[] {
        return [
            {
                headerName: '',
                children: [
                    {
                        headerName: 'Application Scheme',
                        headerComponent:'infoGridHeaderComponentComponent',
                        headerComponentParams: {
                          name: 'Application Scheme',
                          infoText: Constants.MESSAGES.APP_SCHEME_INFO,
                          popupWidth:450
                        },                        
                        wrapText: true,
                        field: 'name',
                        type: 'default',
                        resizable:true,
                        pinned:'left',
                        width: 300,
                        filter: 'agSetColumnFilter',
                        headerCheckboxSelection: isProjectOwnershipValid,
                        checkboxSelection: isProjectOwnershipValid,
                        cellRenderer:InputTextApplicationSchemeRenderer,
                        cellRendererParams: {
                          disabled: !isProjectOwnershipValid,
                          isRequired: true
                        },
                        editable: false,
                        cellEditorParams: {
                            maxLength: 200,
                        },
                    },
                    {
                        headerName: options?.geographiesList[0]?.source,
                        wrapText: true,
                        field: 'geographies',
                        type: 'default',
                        filter: 'agSetColumnFilter',
                        // hide: ((JSON.stringify(options.selectedModels) === JSON.stringify(new Array(Constants.MODELS.UK)))) || (options.geographySelected !== Constants.CROP_GEOGRAPHIES.USA), // TODO: Luis Miranda - uncomment when the new Geographies component is implemented
                        hide: true,
                        filterParams: {
                            cellRenderer: (pParams: any) => {
                                return Utils.formatFilterValues(pParams, options.geographiesList, 'key', 'name')
                            },
                        },

                        cellRenderer: 'listBoxRenderer',
                        cellRendererParams: {
                            options: options.geographiesList,
                            optionValue: 'key',
                            optionLabel: 'name',
                            checkbox: true,
                            filter: false,
                            multiple: true,
                            disabled: false,
                            validateModel: false,
                            compartment: Constants.COMPARTMENTS.SURFACE_WATER,
                        },
                        resizable: true,
                        sortable: true,
                        width: 150,
                        editable: isProjectOwnershipValid,
                    },
                    {
                        headerName: 'Crop (AppDate)',
                        wrapText: true,
                        field: 'cropAppDatePk',
                        type: 'select',
                        resizable:true,                        width: 200,
                        editable: false,
                        hide: (options.geographySelected == Constants.CROP_GEOGRAPHIES.USA) || (options.geographySelected == Constants.CROP_GEOGRAPHIES.UK),
                        filter: 'agSetColumnfilter',
                        filterParams: {
                            cellRenderer: (pParams: any) => {
                                return Utils.formatFilterValues(pParams, options.cropList, 'CropListPk', 'CropName')
                            },
                        },
                        cellRenderer: CropDropdownListRenderer,
                        cellRendererParams: (params: any) => {
                            return {
                                options: options.cropList,
                                purpose: Constants.CROP_PURPOSES.APP_DATE,
                                matchings: options.cropListMatching,
                                compartment: Constants.COMPARTMENTS.SURFACE_WATER,
                                geography: options.geographySelected,
                                optionValue: 'CropListPk',
                                optionLabel: 'CropName',
                                columnThatModifies: 'cropAppDatePk',
                                reloadCore: params?.data?.reloadCore,
                                disabled: !isProjectOwnershipValid,
                            }
                        },
                    },
                    {
                        headerName: 'Crop (Drift)',
                        wrapText: true,
                        field: 'cropInterceptionPk',
                        hide: (options.geographySelected != Constants.CROP_GEOGRAPHIES.UK),
                        resizable:true,
                        width: 220,
                        type: 'select',
                        editable: false,
                        cellRenderer: CropDropdownListRenderer,
                        cellRendererParams: (params: any) => {
                            return {
                                options: options.cropList,
                                purpose: Constants.CROP_PURPOSES.INTERCEPTION,
                                matchings: options.cropListMatching,
                                compartment: Constants.COMPARTMENTS.SURFACE_WATER,
                                geography: options.geographySelected,
                                optionValue: 'CropListPk',
                                optionLabel: 'CropName',
                                columnThatModifies: 'cropInterceptionPk',
                                reloadCore: params?.data?.reloadCore,
                                disabled: !isProjectOwnershipValid,
                            }
                        },
                        filter: 'agSetColumnFilter',
                        filterParams: {
                            cellRenderer: (pParams: any) => {
                                return Utils.formatFilterValues(pParams, options.cropList, 'CropListPk', 'CropName')
                            },
                        },
                    },
                    {
                        headerName: 'Crop (Interception)',
                        wrapText: true,
                        field: 'cropDriftInterceptionPk',
                        type: 'select',
                        resizable:true,
                        width: 270,
                        editable: false,
                        hide: (options.geographySelected == Constants.CROP_GEOGRAPHIES.UK),
                        filter: 'agSetColumnfilter',
                        filterParams: {
                            cellRenderer: (pParams: any) => {
                                return Utils.formatFilterValues(pParams, options.cropList, 'CropListPk', 'CropName')
                            },
                        },
                        cellRenderer: CropDropdownListRenderer,
                        cellRendererParams: (params: any) => {
                            return {
                                options: options.cropList,
                                purpose: Constants.CROP_PURPOSES.DRIFT_INTERCEPTION,
                                matchings: options.cropListMatching,
                                compartment: Constants.COMPARTMENTS.SURFACE_WATER,
                                geography: options.geographySelected,
                                optionValue: 'CropListPk',
                                optionLabel: 'CropName',
                                columnThatModifies: 'cropDriftInterceptionPk',
                                reloadCore: params?.data?.reloadCore,
                                disabled: !isProjectOwnershipValid,
                            }
                        }
                    },
                    {
                        headerName: 'Crop (Spray)',
                        wrapText: true,
                        field: 'cropSprayPk',
                        hide: (options.geographySelected != Constants.CROP_GEOGRAPHIES.UK),
                        resizable:true,                        width: 300,
                        editable: true,
                        type: 'select',
                        filter: 'agSetColumnFilter',
                        filterParams: {
                            cellRenderer: (pParams: any) => {
                                return Utils.formatFilterValues(pParams, options.cropList, 'CropListPk', 'CropName')
                            },
                        },
                        cellRenderer: CropDropdownListRenderer,
                        cellRendererParams: (params: any) => {
                            return {
                                options: options.cropList,
                                purpose: Constants.CROP_PURPOSES.SPRAY,
                                matchings: options.cropListMatching,
                                compartment: Constants.COMPARTMENTS.SURFACE_WATER,
                                geography: options.geographySelected,
                                optionValue: 'CropListPk',
                                optionLabel: 'CropName',
                                columnThatModifies: 'cropSprayPk',
                                reloadCore: params?.data?.reloadCore,
                                disabled: !isProjectOwnershipValid,
                            }
                        },

                    },
                    {
                        headerName: 'Crop',
                        wrapText: true,
                        field: 'cropPk',
                        type: 'select',
                        resizable:true,
                        width: 160,
                        editable: false,
                        hide: (options.geographySelected != Constants.CROP_GEOGRAPHIES.USA),
                        filter: 'agSetColumnfilter',
                        filterParams: {
                            cellRenderer: (pParams: any) => {
                                return Utils.formatFilterValues(pParams, options.cropList, 'CropListPk', 'CropName')
                            },
                        },
                        cellRenderer: DropdownListRenderer,
                        cellRendererParams: {
                            options: options.cropList,
                            optionValue: 'CropListPk',
                            optionLabel: 'CropName',
                            columnThatModifies: 'cropPk',
                            disabled: !isProjectOwnershipValid,

                        }
                    },
                    {
                        headerName: 'Number of Applications',
                        wrapText: true,
                        field: 'numberOfApplications',
                        type: 'select',
                        resizable:true,
                        width: 120,
                        editable: false,
                        filter: 'agSetColumnfilter',
                        cellRenderer: DropdownListRenderer,
                        cellRendererParams: (params: any) => {
                            return {
                                instance: this,
                                options: options.numerOfApplications,
                                optionValue: 'value',
                                optionLabel: 'value',
                                columnThatModifies: 'numberOfApplications',
                                height: '300px',
                                disabled: !isProjectOwnershipValid,
                            };
                        }
                    },
                    {
                        headerName: `Variable rates or intervals for ${Constants.MODELS.SWASH}`,
                        wrapText: true,
                        field: 'hasVariableRates',
                        width: 175,
                        minWidth: 175,
                        editable: false,
                        hide: options.geographySelected != Constants.CROP_GEOGRAPHIES.EUROPE_UNION,
                        type: 'select',
                        suppressSizeToFit: true,
                        filter: 'agSetColumnfilter',
                        filterParams: {
                            valueFormatter: (params: any) => {
                                return params.value ? Constants.AFFIRMATIVE_VALUES.YES : !Utils.isEmptyValue(params.value) ? Constants.AFFIRMATIVE_VALUES.NO : '(Blanks)';
                            }
                        },
                        cellRenderer: DropdownListRenderer,
                        cellRendererParams: (params: any) => {
                            return {
                                options: [{ value: true, key: Constants.AFFIRMATIVE_VALUES.YES }, { value: false, key: Constants.AFFIRMATIVE_VALUES.NO }],
                                columnThatModifies: 'hasVariableRates',
                                disabled: !isProjectOwnershipValid || params.data.numberOfApplications == 1 ? true : false,
                                optionLabel: 'key',
                                optionValue: 'value',
                                parent: this,
                            };
                        },
                        cellStyle: (params: any) => {
                            if (params.data.numberOfApplications == 1) {
                                return { pointerEvents: 'none', backgroundColor: '#f0f0f0' };
                            }
                            return null;
                        },
                        wrapHeaderText: true,
                    },
                    {
                        headerName: '',
                        field: 'expandButton',
                        minWidth: 150,
                        width: 150,
                        cellRenderer: ButtonExpandGridRenderer,
                        cellRendererParams: {
                        context: this,
                        dataComponent: this,
                        showLabel: 'Enter Inputs',
                        hideLabel: 'Hide Inputs',
                        showIcon: 'pi pi-arrow-down',
                        hideIcon: 'pi pi-arrow-right'
                        },
                        resizable:true,
                        suppressSizeToFit: true,
                        hide: options.geographySelected != Constants.CROP_GEOGRAPHIES.EUROPE_UNION,
                        type: 'default',
                        autoHeight: true,
                        editable: false,
                        cellStyle: (params: any) => {
                            if (params.data.numberOfApplications == 1 || params.data.hasVariableRates == false) {
                                return { pointerEvents: 'none', backgroundColor: '#f0f0f0' };
                            }
                            return null;
                        },
                        wrapText: true,
                        wrapHeaderText: true,
                    },
                    {
                        headerName: 'Application Interval (days)',
                        wrapText: true,
                        field: 'applicationInterval',
                        type: 'default',
                        resizable:true,
                        width: 125,
                        filter: 'agSetColumnfilter',
                        cellRenderer: InputNumberRenderer,
                        cellRendererParams:(params:any)=> {
                            return{
                                disabled: !isProjectOwnershipValid || params.data.numberOfApplications === 1,
                            }
                        },
                        editable: false,
                    },
                ]
            },
            {
                headerName: 'Rate (g/ha)',
                children: [
                    {
                        headerName: 'Default Active Ingredient',
                        wrapText: true,
                        field: 'defaultAI',
                        cellRenderer: '',
                        editable: false,
                    },
                ]
            },
            {
                headerName: '',
                children: [
                    {
                        headerName: 'BBCH (earliest)',
                        wrapText: true,
                        field: 'bbchEarliest',
                        type: 'select',
                        width: 100,
                        resizable:true,                        filter: 'agSetColumnfilter',
                        editable: false,
                        cellRenderer: InputSearchRenderer,
                        cellRendererParams: (params: any) => {
                            return {
                                instance: this,
                                options: options.bbch,
                                optionValue: 'value',
                                optionLabel: 'value',
                                columnThatModifies: 'bbchEarliest',
                                forceSelection: true,
                                disabled: !isProjectOwnershipValid,
                            };
                        }
                    },
                    {
                        headerName: 'Crop Coverage',
                        wrapText: true,
                        field: 'cropCoverage',
                        type: 'default',
                        filter: 'agSetColumnFilter',
                        resizable:true,
                        cellRenderer: InputTextRenderer,
                        cellRendererParams: {
                                disabled: true,
                        },
                        editable: false,
                    },
                    {
                        headerName: 'Interception (%)',
                        wrapText: true,
                        field: 'cropInterception',
                        type: 'default',
                        filter: 'agSetColumnFilter',
                        width: 110,
                        resizable:true,
                        cellRenderer: InputNumberRenderer,
                        cellRendererParams: {
                            disabled: !isProjectOwnershipValid,
                    },
                        editable: false,                    
                    },
                    {
                        headerName: 'First Application Date',
                        wrapText: true,
                        wrapHeaderText: true,
                        field: 'ukApplicationDate',
                        type: 'default',
                        filter: 'agSetColumnFilter',
                        width: 110,
                        resizable:true,
                        editable: false,
                        cellRenderer: CalendarRenderer,
                        cellRendererParams: (params: any) => {
                            const firstDate = params?.data?.applicationSchemeXApplicationWindow?.length > 0;
                            if (firstDate) {
                                params.data.ukApplicationDate = params?.data?.applicationSchemeXApplicationWindow[0].firstDate;
                            }
                            return {
                                instance: this,
                                columnThatModifies: 'ukApplicationDate',
                                highlightDate: new Date(2001, 0, 1),
                                disabled: !isProjectOwnershipValid,
                            };
                        },
                    },
                    {
                        headerName: 'Application Window',
                        wrapText: true,
                        field: 'appWindow',
                        type: 'default',
                        filter: 'agSetColumnFilter',
                        resizable:true,
                        width: 125,
                        editable: isProjectOwnershipValid,
                        hide: options.geographySelected !== Constants.CROP_GEOGRAPHIES.EUROPE_UNION || !options.selectedModels.includes(Constants.MODELS.SWASH),
                        cellRenderer: ViewButtonRenderer,
                    },
                    {
                        headerName: 'First Application Date',
                        wrapText: true,
                        field: 'firstApplicationDate',
                        type: 'default',
                        filter: 'agSetColumnFilter',
                        resizable:true,                        width: 150,
                        editable: false,
                        hide: options.geographySelected === Constants.CROP_GEOGRAPHIES.EUROPE_UNION || options.geographySelected === Constants.CROP_GEOGRAPHIES.UK,
                        cellRenderer: CalendarRenderer,
                        cellRendererParams: (params: any) => {
                            const firstDate = params?.data?.applicationSchemeXApplicationWindow?.length > 0;
                            if (firstDate) {
                                params.data.firstApplicationDate = firstDate
                            }
                            return {
                                instance: this,
                                columnThatModifies: 'firstApplicationDate',
                                highlightDate: new Date(2001, 0, 1),
                                disabled: !isProjectOwnershipValid,
                            };
                        }
                    },
                    {
                        headerName: 'Application Method',
                        wrapText: true,
                        field: 'applicationMethod',
                        type: 'select',
                        resizable:true,                        width: 200,
                        filter: 'agSetColumnfilter',
                        editable: false,
                        cellRenderer: DropdownListRenderer,
                        cellRendererParams: (params: any) => {
                            return {
                                instance: this,
                                options: options.applicationMethods,
                                optionValue: 'value',
                                optionLabel: 'value',
                                columnThatModifies: 'applicationMethod',
                                disabled: !isProjectOwnershipValid,
                            };
                        }
                    },
                    {
                        headerName: 'Chemical Application Method',
                        wrapText: true,
                        field: 'chemicalApplicationMethod',
                        type: 'select',
                        resizable:true,
                        width: 240,
                        filter: 'agSetColumnfilter',
                        editable: false,
                        cellRenderer: DropdownListRenderer,
                        cellRendererParams: (params: any) => {
                            const node = params.node;
                            let selectedAppMethod = node.data.applicationMethod;
                            let chemicalAppMethods = Constants.APPLICATION_METHOD_VALUES_SURFACEWATER[selectedAppMethod]?.map((key: string) => ({ value: key }));
                            return {
                                instance: this,
                                options: chemicalAppMethods ?? [],
                                optionValue: 'value',
                                optionLabel: 'value',
                                columnThatModifies: 'chemicalApplicationMethod',
                                disabled: !isProjectOwnershipValid,
                            };
                        }
                    },
                    {
                        headerName: 'Soil Depth (cm)',
                        wrapText: true,
                        field: 'soilDepth',
                        type: 'default',
                        filter: 'agSetColumnFilter',
                        resizable:true,
                        width: 110,
                        cellRenderer: InputNumberRenderer,
                        cellRendererParams: {
                                disabled: !isProjectOwnershipValid,
                        },
                        editable: false,

                    },
                    {
                        headerName: 'Nozzle drift reduction (10m buffer distance)',
                        wrapText: true,
                        field: 'nozzleDriftReduction10m',
                        type: 'default',
                        filter: 'agSetColumnFilter',
                        resizable:true,
                        width: 170,
                        cellRenderer: InputNumberRenderer,
                        cellRendererParams: {
                            disabled: !isProjectOwnershipValid,
                    },
                        editable: false,
                    },
                    {
                        headerName: 'Nozzle drift reduction (20m buffer distance)',
                        wrapText: true,
                        field: 'nozzleDriftReduction20m',
                        type: 'default',
                        filter: 'agSetColumnFilter',
                        resizable:true,
                        width: 170,
                        cellRenderer: InputNumberRenderer,
                        cellRendererParams: {
                            disabled: !isProjectOwnershipValid,
                    },
                        editable: false,
                    }
                ]
            },
            {
                headerName: '',
                colId: 'duplicate',
                field: 'duplicateButton',
                cellRenderer: DuplicateButtonRenderer,
                type: 'rightAligned',
                width: 130,
                cellRendererParams: {
                    disabled: !isProjectOwnershipValid,
                    mainTablePkName: "applicationSchemePk"
                }
            
            },            
            {
                headerName: '',
                colId: 'delete',
                field: 'deleteButton',
                cellRenderer: DeleteButtonRenderer,
                editable: false,
                type: 'rightAligned',
                width: 125,
                resizable:true,
                cellRendererParams: {
                    disabled: !isProjectOwnershipValid,
                }
            }               
        ];
    }

    public getApplicationColumnsDefinition(parentRowNode?: RowNode, isProjectOwnershipValid?: boolean): ColDef[] {
        return [
            {
                headerName: 'Location',
                headerCheckboxSelection: isProjectOwnershipValid,
                checkboxSelection: isProjectOwnershipValid,
                field: 'location',
                wrapText:true,
                type: 'default',
                flex:1.5,
                resizable:true,
                filter: 'agSetColumnFilter',
                cellEditor: InputTextRenderer,
                sort: "asc",
                cellRenderer: (params: any) => {
                    const node = params.node;
                    if (parentRowNode) {
                        const isSelected = node.data && parentRowNode.data.applicationSchemeXApplicationWindow?.some((x: any) => node.data.applicationDateSurfaceWaterPk === x.applicationDateSurfaceWaterPk);
                        if (isSelected) {
                            node.setSelected(true);
                        }
                    }
                    return node.data.location;
                },
                cellRendererParams: (params: any) => {
                    return {
                        disabled:true
                    };
                },
            },
            {
                headerName: 'Beginning Date',
                field: 'beginWindow',
                wrapText:true,
                flex:1.2,
                type: 'default',
                filter: 'agSetColumnFilter',
                resizable:true,                
                cellRenderer: CalendarRenderer,
                editable: false,
                cellRendererParams: (params: any) => {
                    return {
                        instance: this,
                        columnThatModifies: 'beginWindow',
                        highlightDate: new Date(2001, 0, 1),
                        disabled: !isProjectOwnershipValid,
                    };
                },
            },
            {
                headerName: 'DayNr',
                field: 'beginWindowJulian',
                editable: false,
                flex:0.9,
                type: 'default',
                filter: 'agSetColumnFilter',
                resizable:true,
                cellRenderer: InputNumberRenderer,
                cellRendererParams: (params: any) => {
                    return {
                        instance: this,
                        field: 'beginWindowJulian',
                        maxDecimals: 0,
                        min: 1,
                        max: 365,
                        excludeRange: true,
                        useGrouping: false,
                  }
                },
                valueGetter: (params: any) => {
                    const beginWindowDate = params.data.beginWindow ? params.data.beginWindow : (parentRowNode?.data.cropWithoutAppDates ? new Date(2001, 0, 1) : params.data.beginWindow);
                    if (beginWindowDate) {
                        let dayNumber = Utils.getDayOfYear(beginWindowDate);
                        params.data.beginWindowJulian = dayNumber;
                        return Utils.getDayOfYear(beginWindowDate);
                    }
                    return null;
                },
            },
            {
                headerName: 'End Date',
                field: 'endWindow',
                wrapText:true,
                type: 'default',
                flex:1.2,
                filter: 'agSetColumnFilter',
                resizable:true,                
                cellRenderer: CalendarRenderer,
                editable: false,
                cellRendererParams: (params: any) => {
                    return {
                        instance: this,
                        columnThatModifies: 'endWindow',
                        highlightDate: new Date(2001, 0, 1),
                        disabled: !isProjectOwnershipValid,
                    };
                },
            },
            {
                headerName: 'DayNr',
                field: 'endWindowJulian',
                wrapText:true,
                editable: false,
                flex:0.9,

                type: 'default',
                filter: 'agSetColumnFilter',
                resizable:true,
                cellRenderer: InputNumberRenderer,
                cellRendererParams: (params: any) => {
                    return {
                        instance: this,
                        field: 'endWindowJulian',
                        maxDecimals: 0,
                        min: 1,
                        max: 365,
                        excludeRange: true,
                        useGrouping: false,
                  }
                },
                valueGetter: (params: any) => {
                    const endWindow = params.data.endWindow ? params.data.endWindow : (parentRowNode?.data.cropWithoutAppDates ? new Date(2001, 0, 1) : params.data.endWindow);
                    if (endWindow) {
                        let dayNumber = Utils.getDayOfYear(endWindow);
                        params.data.endWindowJulian = dayNumber;
                        return dayNumber;
                    }
                    return null;
                },
            },
            {
                headerName: 'Emergence Day',
                field: 'emergencyDate',
                editable: false,
                flex:1,
                wrapText:true,
                type: 'default',
                filter: 'agSetColumnFilter',
                valueFormatter: Utils.convertDateWithoutYear,
                resizable:true,
                valueGetter: (params: any) => {
                    const emergencyDate = parentRowNode?.data.cropWithoutAppDates ? new Date(1999, 0, 1) : params.data.emergencyDate;
                    if (emergencyDate) {
                        return emergencyDate;
                    }
                    return null;
                },
            },
            {
                headerName: 'Harvest Day',
                field: 'harvestDate',
                editable: false,
                flex:1,

                wrapText:true,
                type: 'default',
                filter: 'agSetColumnFilter',
                valueFormatter: Utils.convertDateWithoutYear,
                resizable:true,
                valueGetter: (params: any) => {
                    const harvestDate = parentRowNode?.data.cropWithoutAppDates ? new Date(1999, 0, 31) : params.data.harvestDate;
                    if (harvestDate) {
                        return harvestDate;
                    }
                    return null;
                },
            }
        ]
    }

    public getInverseColumnsDefinition(options: any, { useNumberOfApplications, useIntervalBetweenApplications, useBBCH, useApplicationWindow, useRateGHA, useRateLBACRE }: Project, isProjectOwnershipValid: boolean): any[] {
        const inverseNumOfApplications = {
            headerName: 'Number of Applications',
            wrapText: true,
            children: [
                {
                    headerName: 'Min',
                    field: 'minNumberOfApplications',
                    type: 'select',
                    resizable:true,                    width: 100,
                    editable: false,
                    filter: 'agSetColumnfilter',
                    cellRenderer: DropdownListRenderer,
                    cellRendererParams: (params: any) => {
                        return {
                            options: options.numerOfApplications,
                            optionValue: 'value',
                            optionLabel: 'value',
                            height: '300px',
                            disabled: !isProjectOwnershipValid,
                        };
                    }
                },
                {
                    headerName: 'Max',
                    field: 'maxNumberOfApplications',
                    type: 'select',
                    resizable:true,                    width: 100,
                    editable: false,
                    filter: 'agSetColumnfilter',
                    cellRenderer: DropdownListRenderer,
                    cellRendererParams: (params: any) => {
                        return {
                            options: options.numerOfApplications,
                            optionValue: 'value',
                            optionLabel: 'value',
                            height: '300px',
                            disabled: !isProjectOwnershipValid,
                        };
                    }
                },
                {
                    headerName: 'Increment',
                    field: 'incrementNumberOfApplications',
                    type: 'select',
                    resizable:true,                    width: 100,
                    filter: 'agSetColumnfilter',
                    cellRenderer: DropdownListRenderer,
                    cellRendererParams: (params: any) => {
                        return {
                            options: options.numerOfApplications,
                            optionValue: 'value',
                            optionLabel: 'value',
                            height: '300px',
                            disabled: !isProjectOwnershipValid,
                        };
                    }
                }]
        };
        const numberOfApplications = {

            headerName: 'Number of Applications',
            wrapText: true,
                field: 'numberOfApplications',
                type: 'select',
                resizable:true,
                width: 120,
                editable: false,
                filter: 'agSetColumnfilter',
                cellRenderer: DropdownListRenderer,
                cellRendererParams: (params: any) => {
                    return {
                        options: options.numerOfApplications,
                        optionValue: 'value',
                        optionLabel: 'value',
                        columnThatModifies: 'numberOfApplications',
                        height: '300px',
                        disabled: !isProjectOwnershipValid,
                    };
                }
        };
        const inverseApplicationInterval = {
            headerName: 'Application Interval (days)',
            wrapText: true,
            children: [
                {
                    headerName: 'Min',
                    field: 'minApplicationInterval',
                    type: 'default',
                    resizable:true,                    width: 125,
                    filter: 'agSetColumnfilter',
                    cellRenderer: InputNumberRenderer,
                        cellRendererParams:{
                                disabled: !isProjectOwnershipValid
                        },
                    editable: false,
                },
                {
                    headerName: 'Max',
                    field: 'maxApplicationInterval',
                    type: 'default',
                    resizable:true,                    width: 125,
                    filter: 'agSetColumnfilter',
                    cellRenderer: InputNumberRenderer,
                    cellRendererParams: {
                            disabled: !isProjectOwnershipValid,
                    },
                editable: false,
                },
                {
                    headerName: 'Increment',
                    field: 'incrementApplicationInterval',
                    type: 'default',
                    resizable:true,                    width: 125,
                    filter: 'agSetColumnfilter',
                    cellRenderer: InputNumberRenderer,
                    cellRendererParams:{
                            disabled: !isProjectOwnershipValid,
                    },
                editable: false,
                }],
        };
        const applicationInterval = {
            headerName: 'Application Interval (days)',
            wrapText: true,
                field: 'applicationInterval',
                type: 'default',
                resizable:true,
                width: 150,
                filter: 'agSetColumnfilter',
                cellRenderer: InputNumberRenderer,
                cellRendererParams:(params:any)=> {
                    return{
                        disabled: !isProjectOwnershipValid || params.data.numberOfApplications === 1,
                    }
                },
            editable: false,
        };

        const inverseBBCH = {
            headerName: 'BBCH (earliest)',
            wrapText: true,
            children: [
                {
                    headerName: 'Min',
                    field: 'minBbchEarliest',
                    type: 'select',
                    width: 100,
                    resizable:true,                    filter: 'agSetColumnfilter',
                    editable: false,
                    cellRenderer: InputSearchRenderer,
                    cellRendererParams: (params: any) => {
                        return {
                            options: options.bbch,
                            optionValue: 'value',
                            optionLabel: 'value',
                            forceSelection: true,
                            disabled: !isProjectOwnershipValid,
                        };
                    }
                },
                {
                    headerName: 'Max',
                    field: 'maxBbchEarliest',
                    type: 'select',
                    width: 100,
                    resizable:true,                    filter: 'agSetColumnfilter',
                    editable: false,
                    cellRenderer: InputSearchRenderer,
                    cellRendererParams: (params: any) => {
                        return {

                            options: options.bbch,
                            optionValue: 'value',
                            optionLabel: 'value',
                            instance: this,
                            columnThatModifies: 'maxBbchEarliest',
                            forceSelection: true,
                            disabled: !isProjectOwnershipValid,
                        };
                    }
                },
                {
                    headerName: 'Increment',
                    field: 'incrementBbchEarliest',
                    type: 'select',
                    width: 100,
                    resizable:true,                    filter: 'agSetColumnfilter',
                    editable: false,
                    cellRenderer: InputSearchRenderer,
                    cellRendererParams: (params: any) => {
                        return {
                            options: options.bbch,
                            optionValue: 'value',
                            optionLabel: 'value',
                            forceSelection: true,
                            disabled: !isProjectOwnershipValid,
                        };
                    }
                }
            ]
        };
        const BBCH = {
            headerName: 'BBCH (earliest)',
            wrapText: true,
                field: 'bbchEarliest',
                type: 'select',
                width: 100,
                resizable:true,                filter: 'agSetColumnfilter',
                editable: false,
                cellRenderer: InputSearchRenderer,
                cellRendererParams: (params: any) => {
                    return {
                        options: options.bbch,
                        optionValue: 'value',
                        optionLabel: 'value',
                        forceSelection: true,
                        disabled: !isProjectOwnershipValid,
                    };
                }
        };

        const RateLBACRE = {
                headerName: 'Rate (lb/acre)',
                wrapText: true,
                field: 'defaultAILBACRE',
                cellRenderer: '',
                editable: isProjectOwnershipValid,
        }
        const inverseRateLBACRE = {
            headerName: 'Rate (lb/acre)',
            children: [
                {
                    headerName: 'Default Active Ingredient',
                    wrapText: true,
                    field: 'defaultAILBACRE',
                    cellRenderer: '',
                    editable: isProjectOwnershipValid,
                },
            ]
        }

        const RateGHA = {
            headerName: 'Rate (g/ha)',
            wrapText: true,
            wrapHeaderText:true,
            field: 'defaultAIGHA',
            cellRenderer: '',
            editable: isProjectOwnershipValid,
    }
    const inverseRateGHA = {
            headerName: 'Rate (g/ha)',
            children:[
                {
                    headerName: 'Default Active Ingredient',
                    wrapText: true,
                    wrapHeaderText:true,
                    field: 'defaultAIGHA',
                    cellRenderer: '',
                    editable: isProjectOwnershipValid,
                },
            ]
    };

        return [
            {
                headerName: '',
                children: [
                    {
                        headerName: 'Application Scheme',
                        wrapText: true,
                        field: 'name',
                        type: 'default',
                        pinned:'left',
                        resizable:true,
                        width: 300,
                        filter: 'agSetColumnFilter',
                        headerCheckboxSelection: isProjectOwnershipValid,
                        checkboxSelection: isProjectOwnershipValid,
                        cellRenderer: InputTextApplicationSchemeRenderer,
                        cellRendererParams:{
                                disabled: !isProjectOwnershipValid,
                                isRequired: true
                        },
                            editable: false,
                        cellEditorParams: {
                            maxLength: 200,
                        },
                    },
                    {
                        headerName: 'Sub-Geography',
                        wrapText: true,
                        field: 'geographies',
                        type: 'default',
                        hide: (options.geographySelected !== Constants.CROP_GEOGRAPHIES.USA),
                        filter: 'agSetColumnFilter',
                        cellRenderer: ListboxRenderer,
                        cellRendererParams:(params:any)=> {
                            return {
                            options: options.geographiesList,
                            optionValue: 'key',
                            optionLabel: 'name',
                            checkbox: true,
                            filter: false,
                            multiple: true,
                            disabled: false,
                            validateModel: false,
                            compartment: 'Surface Water',
                            }
                        },
                        resizable: true,
                        sortable: true,
                        width: 150,
                        editable: false,
                    },
                    {
                        headerName: 'Crop (AppDate)',
                        wrapText: true,
                        field: 'cropAppDatePk',
                        type: 'select',
                        resizable:true,                            
                        width: 200,
                        editable: false,
                        hide: (options.geographySelected == Constants.CROP_GEOGRAPHIES.USA) || (options.geographySelected == Constants.CROP_GEOGRAPHIES.UK),
                        filter: 'agSetColumnfilter',
                        filterParams: {
                            cellRenderer: (pParams: any) => {
                                return Utils.formatFilterValues(pParams, options.cropList, 'CropListPk', 'CropName')
                            },
                        },
                        cellRenderer: CropDropdownListRenderer,
                        cellRendererParams: (params: any) => {
                            return {
                                options: options.cropList,
                                purpose: Constants.CROP_PURPOSES.APP_DATE,
                                matchings: options.cropListMatching,
                                compartment: Constants.COMPARTMENTS.SURFACE_WATER,
                                geography: options.geographySelected,
                                optionValue: 'CropListPk',
                                optionLabel: 'CropName',
                                columnThatModifies: 'cropAppDatePk',
                                reloadCore: params?.data?.reloadCore,
                                disabled: !isProjectOwnershipValid,
                            }
                        },
                    },
                    {
                        headerName: 'Crop (Drift)',
                        wrapText: true,
                        field: 'cropInterceptionPk',
                        hide: (options.geographySelected != Constants.CROP_GEOGRAPHIES.UK),
                        resizable:true,
                        width: 220,
                        type: 'select',
                        editable: false,
                        cellRenderer: CropDropdownListRenderer,
                        cellRendererParams: (params: any) => {
                            return {
                                options: options.cropList,
                                purpose: Constants.CROP_PURPOSES.INTERCEPTION,
                                matchings: options.cropListMatching,
                                compartment: Constants.COMPARTMENTS.SURFACE_WATER,
                                geography: options.geographySelected,
                                optionValue: 'CropListPk',
                                optionLabel: 'CropName',
                                columnThatModifies: 'cropInterceptionPk',
                                reloadCore: params?.data?.reloadCore,
                                disabled: !isProjectOwnershipValid,
                            }
                        },
                        filter: 'agSetColumnFilter',
                        filterParams: {
                            cellRenderer: (pParams: any) => {
                                return Utils.formatFilterValues(pParams, options.cropList, 'CropListPk', 'CropName')
                            },
                        },
                    },
                    {
                        headerName: 'Crop (Interception)',
                        wrapText: true,
                        field: 'cropDriftInterceptionPk',
                        type: 'select',
                        resizable:true,
                        width: 270,
                        editable: false,
                        hide: (options.geographySelected == Constants.CROP_GEOGRAPHIES.UK),
                        filter: 'agSetColumnfilter',
                        filterParams: {
                            cellRenderer: (pParams: any) => {
                                return Utils.formatFilterValues(pParams, options.cropList, 'CropListPk', 'CropName')
                            },
                        },
                        cellRenderer: CropDropdownListRenderer,
                        cellRendererParams: (params: any) => {
                            return {
                                options: options.cropList,
                                purpose: Constants.CROP_PURPOSES.DRIFT_INTERCEPTION,
                                matchings: options.cropListMatching,
                                compartment: Constants.COMPARTMENTS.SURFACE_WATER,
                                geography: options.geographySelected,
                                optionValue: 'CropListPk',
                                optionLabel: 'CropName',
                                columnThatModifies: 'cropDriftInterceptionPk',
                                reloadCore: params?.data?.reloadCore,
                                disabled: !isProjectOwnershipValid,
                            }
                        }
                    },
                    {
                        headerName: 'Crop (Spray)',
                        wrapText: true,
                        field: 'cropSprayPk',
                        hide: (options.geographySelected != Constants.CROP_GEOGRAPHIES.UK),
                        resizable:true,
                        width: 300,
                        editable: true,
                        type: 'select',
                        filter: 'agSetColumnFilter',
                        filterParams: {
                            cellRenderer: (pParams: any) => {
                                return Utils.formatFilterValues(pParams, options.cropList, 'CropListPk', 'CropName')
                            },
                        },
                        cellRenderer: CropDropdownListRenderer,
                        cellRendererParams: (params: any) => {
                            return {
                                options: options.cropList,
                                purpose: Constants.CROP_PURPOSES.SPRAY,
                                matchings: options.cropListMatching,
                                compartment: Constants.COMPARTMENTS.SURFACE_WATER,
                                geography: options.geographySelected,
                                optionValue: 'CropListPk',
                                optionLabel: 'CropName',
                                columnThatModifies: 'cropSprayPk',
                                reloadCore: params?.data?.reloadCore,
                                disabled: !isProjectOwnershipValid,
                            }
                        },
                    },
                    {
                        headerName: 'Crop',
                        field: 'cropPk',
                        type: 'select',
                        resizable:true,
                        width: 160,
                        editable: false,
                        hide: (options.geographySelected != Constants.CROP_GEOGRAPHIES.USA),
                        filter: 'agSetColumnfilter',
                        filterParams: {
                            cellRenderer: (pParams: any) => {
                                return Utils.formatFilterValues(pParams, options.cropList, 'CropListPk', 'CropName')
                            },
                        },
                        cellRenderer: DropdownListRenderer,
                        cellRendererParams: {
                            options: options.cropList,
                            optionValue: 'CropListPk',
                            optionLabel: 'CropName',
                            columnThatModifies: 'cropPk',
                            disabled: !isProjectOwnershipValid,

                        }
                    },
                    useNumberOfApplications ? inverseNumOfApplications : numberOfApplications,
                    useIntervalBetweenApplications ? inverseApplicationInterval : applicationInterval,
                ]
            },
            {
                headerName:'Rate (g/ha)',
                children:[
                    useRateGHA ? inverseRateGHA : RateGHA,
                ]
            },
            {
                headerName:'Rate (lb/acre)',
                children:[
                    useRateLBACRE ? inverseRateLBACRE : RateLBACRE,
                ]
            },
            {
                headerName: 'Rate (lb/acre)',
                children: [
                    {
                        headerName: 'Default Active Ingredient',
                        wrapText: true,
                        field: 'defaultAILBACRE',
                        cellRenderer: '',
                        editable: isProjectOwnershipValid,
                    },
                ]
            },
            {
                headerName: '',
                children: [
                    useBBCH ? inverseBBCH : BBCH,
                    {
                        headerName: 'Crop Coverage',
                        wrapText: true,
                            field: 'cropCoverage',
                            type: 'default',
                            filter: 'agSetColumnFilter',
                            resizable:true,
                            width:150,
                            cellRenderer: InputTextRenderer,
                            cellRendererParams: {
                                    disabled: true,
                            },
                            editable: false,
                    },
                    {
                        headerName: 'Interception (%)',
                        wrapText: true,
                            field: 'cropInterception',
                            type: 'default',
                            filter: 'agSetColumnFilter',
                            width: 110,
                            resizable:true,
                            cellRenderer: InputNumberRenderer,
                            cellRendererParams: (params: any) => {
                                return {
                                    instance: this,
                                    columnThatModifies: 'cropInterception',
                                    disabled: !isProjectOwnershipValid,
                                };
                            },
                            editable: false,
                    },
                    {
                        headerName: 'Application Window',
                        wrapText: true,
                            field: 'appWindow',
                            type: 'default',
                            filter: 'agSetColumnFilter',
                            resizable:true,
                            width: 125,
                            editable: false,
                            hide: !useApplicationWindow && options.geographySelected !== Constants.CROP_GEOGRAPHIES.EUROPE_UNION || !options.selectedModels.includes(Constants.MODELS.SWASH),
                            cellRenderer: ViewButtonRenderer,
                    },
                    {
                        headerName: 'First Application Date',
                        wrapText: true,
                        hide: options.geographySelected === Constants.CROP_GEOGRAPHIES.EUROPE_UNION,
                        children: [
                            {
                                headerName: 'Min',
                                field: 'minApplicationDate',
                                type: 'default',
                                filter: 'agSetColumnFilter',
                                width: 150,
                                resizable:true,                                editable: false,
                                hide: options.geographySelected === Constants.CROP_GEOGRAPHIES.EUROPE_UNION,
                                cellRenderer: CalendarRenderer,
                                cellRendererParams: (params: any) => {
                                    return {
                                        instance: this,
                                        columnThatModifies: 'minApplicationDate',
                                        highlightDate: new Date(2001, 0, 1),
                                        disabled: !isProjectOwnershipValid,
                                    };
                                },
                            },
                            {
                                headerName: 'Max',
                                field: 'maxApplicationDate',
                                type: 'default',
                                filter: 'agSetColumnFilter',
                                width: 150,
                                resizable:true,                                editable: false,
                                hide: options.geographySelected === Constants.CROP_GEOGRAPHIES.EUROPE_UNION,
                                cellRenderer: CalendarRenderer,
                                cellRendererParams: (params: any) => {
                                    return {
                                        instance: this,
                                        columnThatModifies: 'maxApplicationDate',
                                        highlightDate: new Date(2001, 0, 1),
                                        disabled: !isProjectOwnershipValid,
                                    };
                                },
                            },
                            {
                                headerName: 'Increment',
                                field: 'incrementApplicationDate',
                                type: 'default',
                                filter: 'agSetColumnFilter',
                                width: 100,
                                resizable:true,                                hide: options.geographySelected === Constants.CROP_GEOGRAPHIES.EUROPE_UNION,
                                cellRenderer: InputNumberRenderer,
                                cellRendererParams: (params: any) => {
                                    return {
                                        instance: this,
                                        columnThatModifies: 'incrementApplicationDate',
                                        disabled: !isProjectOwnershipValid,
                                    };
                                },
                                editable: false,
                            }]
                    },
                    {
                        headerName: 'First Application Date',
                        wrapText: true,
                            field: 'firstApplicationDate',
                            type: 'default',
                            filter: 'agSetColumnFilter',
                            resizable:true,                            width: 150,
                            editable: false,
                            hide: options.geographySelected === Constants.CROP_GEOGRAPHIES.EUROPE_UNION || options.geographySelected === Constants.CROP_GEOGRAPHIES.UK,
                            cellRenderer: CalendarRenderer,
                            cellRendererParams: (params: any) => {
                                const firstDate = params?.data?.applicationSchemeXApplicationWindow?.length > 0;
                                if (firstDate) {
                                    params.data.firstApplicationDate = firstDate
                                }
                                return {
                                    instance: this,
                                    columnThatModifies: 'firstApplicationDate',
                                    highlightDate: new Date(2001, 0, 1),
                                    disabled: !isProjectOwnershipValid,
                                };
                            }
                    },
                    {
                        headerName: 'Application Method',
                        wrapText: true,
                            field: 'applicationMethod',
                            type: 'select',
                            resizable:true,                            width: 200,
                            filter: 'agSetColumnfilter',
                            editable: false,
                            cellRenderer: DropdownListRenderer,
                            cellRendererParams: {
                                options: options.applicationMethods,
                                optionValue: 'value',
                                optionLabel: 'value',
                                disabled: !isProjectOwnershipValid,
                            }
                    },
                    {
                        headerName: 'Chemical Application Method',
                        wrapText: true,
                            field: 'chemicalApplicationMethod',
                            type: 'select',
                            resizable:true,
                            width: 240,
                            filter: 'agSetColumnfilter',
                            editable: false,
                            cellRenderer: DropdownListRenderer,
                            cellRendererParams: (params: any) => {
                                let chemicalAppMethods = Constants.APPLICATION_METHOD_VALUES_SURFACEWATER[params.node.data.applicationMethod]?.map((key: string) => ({ value: key }));
                                return {
                                    options: chemicalAppMethods ?? [],
                                    optionValue: 'value',
                                    optionLabel: 'value',
                                    disabled: !isProjectOwnershipValid,
                                };
                            }
                    },
                    {
                        headerName: 'Soil Depth (cm)',
                        wrapText: true,
                            field: 'soilDepth',
                            type: 'default',
                            filter: 'agSetColumnFilter',
                            resizable:true,
                            width: 110,
                            cellRenderer: InputNumberRenderer,
                            cellRendererParams: {
                                disabled: !isProjectOwnershipValid,
                        },
                            editable: false,
                    },
                    {
                        headerName: 'Nozzle drift reduction (10m buffer distance)',
                        wrapText: true,
                            field: 'nozzleDriftReduction10m',
                            type: 'default',
                            filter: 'agSetColumnFilter',
                            resizable:true,
                            width: 170,
                            cellRenderer: InputNumberRenderer,
                            cellRendererParams: {
                                disabled: !isProjectOwnershipValid,
                        },
                            editable: false,
                    },
                    {
                        headerName: 'Nozzle drift reduction (20m buffer distance)',
                        wrapText: true,
                            field: 'nozzleDriftReduction20m',
                            type: 'default',
                            filter: 'agSetColumnFilter',
                            resizable:true,
                            width: 170,
                            cellRenderer: InputNumberRenderer,
                            cellRendererParams: {
                                    disabled: !isProjectOwnershipValid,
                            },
                            editable: false,
                    }
                ]
            },  
            {
                headerName: '',
                colId: 'delete',
                field: 'deleteButton',
                cellRenderer: DeleteButtonRenderer,
                editable: false,
                type: 'rightAligned',
                width: 125,
                resizable:true,
                cellRendererParams: {
                    disabled: !isProjectOwnershipValid,
                }
            }
        ];
    }

    public getInverseApplicationColumnsDefinition(parentRowNode?: RowNode, isProjectOwnershipValid?: boolean): any[] {
        return [
            {
                headerName: '',
                children: [
                    {
                        headerName: 'Location',
                        headerCheckboxSelection: true,
                        checkboxSelection: true,
                        field: 'location',
                        editable: false,
                        type: 'default',
                        flex:1.5,
                        resizable:true,
                        filter: 'agSetColumnFilter',
                        cellEditor: 'agTextCellEditor',
                        sort: "asc",
                        cellRenderer: (params: any) => {
                            const node = params.node;
                            if (parentRowNode) {
                                const isSelected = node.data && parentRowNode.data.applicationSchemeXApplicationWindow?.some((x: any) => node.data.applicationDateSurfaceWaterPk === x.applicationDateSurfaceWaterPk);
                                if (isSelected) {
                                    node.setSelected(true);
                                }
                            }
                            return node.data.location;
                        },
                        cellRendererParams: (params: any) => {
                            return {
                                instance: this,
                            };
                        },
                    }
                ],
            },
            {
                headerName: 'Beginning Date',
                wrapText: true,
                children: [
                    {
                        headerName: 'Min',
                        field: 'min',
                        type: 'default',
                        flex:1.2,
                        filter: 'agSetColumnFilter',
                        resizable:true,
                        hide: false,
                        editable: false,
                        validationType: 'string',
                        cellRenderer: CalendarRenderer,
                        cellRendererParams: (params: any) => {
                            return {
                                instance: this,
                                columnThatModifies: 'min',
                                highlightDate: new Date(2001, 0, 1),
                                disabled: !isProjectOwnershipValid,
                            };
                        },
                    },
                    {
                        headerName: 'Max',
                        field: 'max',
                        type: 'default',
                        flex:1.2,
                        filter: 'agSetColumnFilter',
                        resizable:true,
                        hide: false,
                        editable: false,
                        validationType: 'string',
                        cellRenderer: CalendarRenderer,
                        cellRendererParams: (params: any) => {
                            return {
                                instance: this,
                                columnThatModifies: 'max',
                                highlightDate: new Date(2001, 0, 1),
                                disabled: !isProjectOwnershipValid,
                            };
                        },
                    },
                    {
                        headerName: 'Increment',
                        field: 'increment',
                        type: 'default',
                        filter: 'agSetColumnFilter',
                        flex:1,
                        resizable:true,
                        hide: false,
                        cellRenderer: InputNumberRenderer,
                        cellRendererParams: (params: any) => {
                            return {
                                instance: this,
                                columnThatModifies: 'increment',
                                disabled: !isProjectOwnershipValid,
                            };
                        },
                        editable: false,
                    }
                ]
            },
            {
                headerName: '',
                children:
                    [
                        {
                            headerName: 'DayNr',
                            field: 'beginWindowJulian',
                            wrapText:true,
                            flex:0.9,
                            editable: false,
                            hide: true,
                            type: 'default',
                            filter: 'agSetColumnFilter',
                            resizable:true,
                            valueGetter: (params: any) => {
                                const beginWindowDate = parentRowNode?.data.cropWithoutAppDates ? new Date(2001, 0, 1) : params.data.beginWindow;
                                if (beginWindowDate) {
                                    return Utils.getDayOfYear(beginWindowDate);
                                }
                                return null;
                            },
                        }
                    ]
            },
            {
                headerName: '',
                children:
                    [
                        {
                            headerName: 'End Date',
                            field: 'endWindow',
                            flex:1.2,
                            type: 'default',
                            filter: 'agSetColumnFilter',
                            resizable:true,
                            editable: false,
                            hide: true,
                            cellRenderer: CalendarRenderer,
                            cellRendererParams: (params: any) => {
                                return {
                                    instance: this,
                                    columnThatModifies: 'endWindow',
                                    highlightDate: new Date(2001, 0, 1),
                                    disabled: !isProjectOwnershipValid,
                                };
                            },
                        }
                    ]
            },
            {
                headerName: '',
                children:
                    [
                        {
                            headerName: 'DayNr',
                            field: 'endWindowJulian',
                            editable: false,
                            hide: true,
                            flex:0.9,
                            type: 'default',
                            filter: 'agSetColumnFilter',
                            resizable:true,
                            cellRenderer: InputNumberRenderer,
                            cellRendererParams: (params: any) => {
                                params.data.isInverseModeling = true;
                                return {
                                    instance: this,
                                    field: 'endWindowJulian',
                                    maxDecimals: 0,
                                    min: 1,
                                    max: 365,
                                    excludeRange: true,
                                    useGrouping: false,
                              }
                            },
                            valueGetter: (params: any) => {
                                const endWindow = parentRowNode?.data.cropWithoutAppDates ? new Date(2001, 0, 1) : params.data.endWindow;
                                if (endWindow) {
                                    return Utils.getDayOfYear(endWindow);
                                }
                                return null;
                            },
                        }
                    ]
            },
            {
                headerName: '',
                children:
                    [
                        {
                            headerName: 'Emergence Day',
                            field: 'emergencyDate',
                            flex:1,
                            editable: false,
                            type: 'default',
                            filter: 'agSetColumnFilter',
                            valueFormatter: Utils.convertDate,
                            resizable:true
                        }
                    ]
            },
            {
                headerName: '',
                children:
                    [
                        {
                            headerName: 'Harvest Day',
                            field: 'harvestDate',
                            editable: false,
                            flex:1,
                            type: 'default',
                            filter: 'agSetColumnFilter',
                            valueFormatter: Utils.convertDate,
                            resizable:true
                        }
                    ]
            }
        ]
    }

    configureDetailGrid(options: any, isProjectOwnershipValid: boolean): ColGroupDef[] {
        return [
            {
                headerName: '',
                children: [
                    {
                        headerName: 'Application Number',
                        field: 'application_number',
                        width: 110,
                        resizable:true,
                        filter: 'agSetColumnFilter',
                        type: 'default',
                        editable: false,
                        sort: "asc",
                        cellRenderer: InputNumberRenderer,
                        cellRendererParams: (params: any) => {
                            return {
                                disabled: true,
                            };
                        },
                    },
                ]
            },
            {
                headerName: 'Rate (g/ha)',
                children: [
                    {
                        headerName: 'Default Active Ingredient',
                        field: 'defaultAI',
                        cellRenderer: '',
                        editable: !isProjectOwnershipValid,
                    },
                ]
            },
        ]
    }
}
