import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Endpoint, EndpointGet, EndpointSaveFormat } from '../../models/endpoint';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RunEndpointFilter } from '../../models/echo/run-endpoint-filter';
import { MetaboliteNPrecursors } from '../../models/echo/metabolite-n-precursors';
import { HttpApiService } from '../http.api.service';

@Injectable({
  providedIn: 'root'
})
export class EndpointApiService extends HttpApiService {
  override apiUrl: string = environment.ECHO_Api_URL;

  getRunEndpoints(pFilters: RunEndpointFilter): Observable<Endpoint[]> {
    const body = JSON.stringify(pFilters);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    const query = `api/runEndpoints`;
    return this
      .put(query, body, headers)
      .pipe(map((data: any) => data.Value));
  }

  saveAndUpdateEndpoints( endpointSaveFormat: EndpointSaveFormat[] ): Observable<boolean> {
    const body = JSON.stringify(endpointSaveFormat);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    const query = `api/endpointFunctions/saveAndUpdateEndpoints`;
    return this
      .put(query, body, headers)
      .pipe(map((data: any) => data.Value));
  }

  getEndpoints( datasetPk: number ): Observable<EndpointGet[]> {
    const query = `api/endpointFunctions/getEndpoints?datasetPk=${datasetPk}`;
    return this.get(query).pipe(map((data: any) => data.Value));
  }
  
  getPrecursorsByMetaboliteByDataSet(dataSetPk: number): Observable<MetaboliteNPrecursors[]> {
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    const endpoint = `api/precursorsByMetaboliteByDataSet/${dataSetPk}`;
    return this
      .get(endpoint, headers)
      .pipe(map((data: any) => data.Value));
  }

  setOrderToMetabolites(metabolites: MetaboliteNPrecursors[]): Observable<any> {
    const body = JSON.stringify(metabolites);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    const endpoint = `api/setOrderToMetabolites`;
    return this
      .put(endpoint, body, headers)
      .pipe(map((data: any) => data.Value));
  }

}
