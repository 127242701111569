import { Injectable } from '@angular/core';
import { ERAConstants } from '../../environmental-risk-assessment.constants';
import { Constants } from 'src/app/shared/utils/constants';
import { ERAPecEccAquaticLogicService } from './era-pec-ecc-aquatic.logic.service';
import { CalendarRenderer } from 'src/app/shared/renderers/calendar/calendar.component';
import { InputTextRenderer } from 'src/app/shared/renderers/input-text/input-text.component';
import { DuplicateButtonRenderer } from 'src/app/shared/renderers/duplicate-button/duplicate-button.component';
import { InputTextApplicationSchemeRenderer } from 'src/app/shared/renderers/input-text/input-text-application-scheme/input-text-application-scheme.component';

@Injectable({
  providedIn: 'root',
})

export class ERAPecEccAquaticColDef {

  constructor(private readonly logicService: ERAPecEccAquaticLogicService) {}

  public getColumnsDefinition(geography: string,  isProjectOwnershipValid: boolean){
    const isUSA: boolean = geography == Constants.GEOGRAPHYS.USA;
    const isEU: boolean = geography == Constants.GEOGRAPHYS.EUROPE_UNION;
  
    return [
      {
        headerName: 'Model',
        field: 'modelName',
        width: isUSA ? 150 : 205,
        cellRenderer: 'dropdownlistRenderer',
        cellRendererParams: (params: any) => {
          return {
            options: this.logicService.modelsDropdown,
            optionValue: 'optionLabel',
            optionLabel: 'optionLabel',
            filterable: true,
            disabled: this.logicService.isDataFromRun(params.data) || !isProjectOwnershipValid,
          }
        },
        filter: 'agSetColumnFilter',
        wrapText: false,
        resizable: true,
        sortable: true,
        editable: false,
        pinned: 'left'
      },
      {
        headerName: 'Data Set',
        field: 'dataSet',
        width: 160,
        cellRenderer: 'inputTextRender',
        cellRendererParams: (params: any) => {
          return {
            disabled: this.logicService.isDataFromRun(params.data) || !isProjectOwnershipValid,
          }
        },
        filter: 'agSetColumnFilter',
        wrapText: false,
        resizable: true,
        sortable: true,
        editable: false,
        pinned: 'left'
      },
      {
        headerName: 'Substance',
        field: 'substance',
        width: 195,
        cellRenderer: 'dropdownlistRenderer',
        cellRendererParams: (params: any) => {
          return {
            options: this.logicService.substances,
            optionValue: 'optionValue',
            optionLabel: 'optionLabel',
            filterable: true,
            disabled: this.logicService.isDataFromRun(params.data) || !isProjectOwnershipValid,
          }
        },
        filter: 'agSetColumnFilter',
        wrapText: false,
        resizable: true,
        sortable: true,
        editable: false,
        pinned: 'left'
      },
      {
        headerName: 'Application Scheme',
        field: 'applicationScheme',
        width: 160,
        cellRenderer: InputTextRenderer,
        cellRendererParams: (params: any) => {
          return {
            disabled: this.logicService.isDataFromRun(params.data) || !isProjectOwnershipValid,
          }
        },
        filter: 'agSetColumnFilter',
        wrapText: false,
        resizable: true,
        sortable: true,
        editable: false,
        pinned: 'left'
      },
      {
        headerName: 'Crop',
        field: 'cropListPk',
        width: 155,
        cellRenderer: 'dropdownlistRenderer',
        cellRendererParams: (params: any) => {
          const modelPk = params.data.modelPk;
          const compartment = this.logicService.getCompartment(modelPk, geography);
          return {
            options: modelPk ? this.logicService.crops.filter(c => c.type == compartment) : [],
            optionValue: 'optionValue',
            optionLabel: 'optionLabel',
            filterable: true,
            disabled: this.logicService.isDataFromRun(params.data) || !isProjectOwnershipValid,
          };
        },
        filter: 'agSetColumnFilter',
        wrapText: false,
        resizable: true,
        sortable: true,
        editable: false,
        pinned: 'left',
        hide:isEU,
      },
      
      {
        headerName: 'Region',
        field: 'regionOrCountry',
        width: 145,
        cellRenderer: 'dropdownlistRenderer',
        cellRendererParams: (params: any) => {
          return {
            options: this.logicService.geographies,
            optionValue: 'optionLabel',
            optionLabel: 'optionLabel',
            filterable: true,
            disabled: this.logicService.isDataFromRun(params.data) || !isProjectOwnershipValid,
          }
        },
        filter: 'agSetColumnFilter',
        wrapText: false,
        resizable: true,
        sortable: true,
        editable: false,
        pinned: 'left',
        hide: isEU
      },
      {
        headerName: 'Scenario FOCUS',
        field: 'scenarioFocus',
        width: 120,
        cellRenderer: 'dropdownlistRenderer',
        cellRendererParams: (params: any) => {
          return {
            options: this.logicService.fillDropdownOptionsFromSingleList(this.logicService.getScenarioFocusListValues(params.data.modelName)),
            optionValue: 'optionValue',
            optionLabel: 'optionLabel',
            filterable: true,
            disabled: this.logicService.isDataFromRun(params.data) || !isProjectOwnershipValid,
          }
        },
        filter: 'agSetColumnFilter',
        wrapText: false,
        resizable: true,
        sortable: true,
        editable: false,
        hide: isUSA
      },
      {
        headerName: 'Scenarios',
        field: 'scenarios',
        width: 140,
        cellRenderer: 'dropdownlistRenderer',
        cellRendererParams: (params: any) => {
          return {
            options: this.logicService.fillDropdownOptionsFromSingleList(ERAConstants.SCENARIOS),
            optionValue: 'optionValue',
            optionLabel: 'optionLabel',
            filterable: true,
            disabled: this.logicService.isDataFromRun(params.data) || !isProjectOwnershipValid || this.logicService.enableColumnForStep3And4(params.data.scenarioFocus),
          }
        },
        filter: 'agSetColumnFilter',
        wrapText: false,
        resizable: true,
        sortable: true,
        editable: false,
        hide: isUSA
      },
      {
        headerName: 'Water Body',
        field: 'waterbody',
        width: isUSA ? 150 : 120,
        cellRenderer: 'dropdownlistRenderer',
        cellRendererParams: (params: any) => {
          return {
            options: this.logicService.waterbodies,
            optionValue: 'optionValue',
            optionLabel: 'optionLabel',
            filterable: true,
            disabled: this.logicService.isDataFromRun(params.data) || !isProjectOwnershipValid || this.logicService.enableColumnForStep3And4(params.data.scenarioFocus),
          }
        },
        filter: 'agSetColumnFilter',
        wrapText: false,
        resizable: true,
        sortable: true,
        editable: false
      },
      {
        headerName: 'Buffer Distance (m)',
        field: 'bufferDistance',
        width: 115,
        cellRenderer: 'dropdownlistRenderer',
        cellRendererParams: (params: any) => {
          return {
            options: this.logicService.fillDropdownOptionsFromSingleList(ERAConstants.BUFFER),
            optionValue: 'optionValue',
            optionLabel: 'optionLabel',
            filterable: true,
            disabled: this.logicService.isDataFromRun(params.data) || !isProjectOwnershipValid || this.logicService.enableColumnForStep4(params.data.scenarioFocus),
          }
        },
        filter: 'agSetColumnFilter',
        wrapText: false,
        resizable: true,
        sortable: true,
        editable: false,
        hide: isUSA
      },
      {
        headerName: 'Nozzle Drfit Reduction (%)',
        field: 'nozzleDriftReduction',
        width: 110,
        cellRenderer: 'inputNumberRenderer',
        cellRendererParams:  (params: any) => {
            return {
            field: 'nozzleDriftReduction',
            maxDecimals: 2,
            disabled: this.logicService.isDataFromRun(params.data) || !isProjectOwnershipValid || this.logicService.enableColumnForStep4(params.data.scenarioFocus),
          }
        },
        filter: 'agSetColumnFilter',
        wrapText: false,
        resizable: true,
        sortable: true,
        editable: false,
        hide: isUSA
      },
      {
        headerName: 'Region',
        field: 'region',
        width: 155,
        cellRenderer: 'dropdownlistRenderer',
        cellRendererParams: (params: any) => {
          return {
            options: this.logicService.fillDropdownOptionsFromSingleList(ERAConstants.REGIONS),
            optionValue: 'optionValue',
            optionLabel: 'optionLabel',
            filterable: true,
            disabled: this.logicService.isDataFromRun(params.data) || !isProjectOwnershipValid || this.logicService.enableColumnForStep2(params.data.scenarioFocus),
          }
        },
        filter: 'agSetColumnFilter',
        wrapText: false,
        resizable: true,
        sortable: true,
        editable: false,
        hide: isUSA
      },
      {
        headerName: 'Period',
        field: 'period',
        width: 140,
        cellRenderer: 'dropdownlistRenderer',
        cellRendererParams: (params: any) => {
          return {
            options: this.logicService.fillDropdownOptionsFromSingleList(ERAConstants.PERIODS),
            optionValue: 'optionValue',
            optionLabel: 'optionLabel',
            filterable: true,
            disabled: this.logicService.isDataFromRun(params.data) || !isProjectOwnershipValid || this.logicService.enableColumnForStep2(params.data.scenarioFocus),
          }
        },
        filter: 'agSetColumnFilter',
        wrapText: false,
        resizable: true,
        sortable: true,
        editable: false,
        hide: isUSA
      },
      {
        headerName: 'Max PECsw (µg/L)',
        field: 'maxPecSw',
        width: 95,
        cellRenderer: 'inputNumberRenderer',
        cellRendererParams: (params: any) => {
            return {
            field: 'maxPecSw',
            maxDecimals: 2,
            disabled: this.logicService.isDataFromRun(params.data) || !isProjectOwnershipValid,
          }
        },
        filter: 'agSetColumnFilter',
        wrapText: false,
        resizable: true,
        sortable: true,
        editable: false,
        hide: isUSA
      },
      {
        headerName: '21 d PECsw,twa (µg/L)',
        field: 'pecSw21d',
        width: 95,
        cellRenderer: 'inputNumberRenderer',
        cellRendererParams:  (params: any) => {
          return {
            field: 'pecSw21d',
            maxDecimals: 2,
            disabled: this.logicService.isDataFromRun(params.data) || !isProjectOwnershipValid,
          }
        },
        filter: 'agSetColumnFilter',
        wrapText: false,
        resizable: true,
        sortable: true,
        editable: false,
        hide: isUSA
      },
      {
        headerName: 'Max PECsed (µg/kg)',
        field: 'maxPecSed',
        width: 95,
        cellRenderer: 'inputNumberRenderer',
        cellRendererParams: (params: any) => {
          return {
            field: 'maxPecSed',
            maxDecimals: 2,
            disabled: this.logicService.isDataFromRun(params.data) || !isProjectOwnershipValid,
          }
        },
        filter: 'agSetColumnFilter',
        wrapText: false,
        resizable: true,
        sortable: true,
        editable: false,
        hide: isUSA
      },
      {
        headerName: '1-day Avg',
        field: 'avg1day',
        width: 95,
        cellRenderer: 'inputNumberRenderer',
        cellRendererParams:  (params: any) => {
          return {
            field: 'avg1day',
            maxDecimals: 2,
            disabled: this.logicService.isDataFromRun(params.data) || !isProjectOwnershipValid,
          }
        },
        filter: 'agSetColumnFilter',
        wrapText: false,
        resizable: true,
        sortable: true,
        editable: false,
        hide: isEU
      },
      {
        headerName: '21-day Avg',
        field: 'avg21day',
        width: 95,
        cellRenderer: 'inputNumberRenderer',
        cellRendererParams:  (params: any) => {
          return {
            field: 'avg21day',
            maxDecimals: 2,
            disabled: this.logicService.isDataFromRun(params.data) || !isProjectOwnershipValid,
          }
        },
        filter: 'agSetColumnFilter',
        wrapText: false,
        resizable: true,
        sortable: true,
        editable: false,
        hide: isEU
      },
      {
        headerName: '60-day Avg',
        field: 'avg60day',
        width: 95,
        cellRenderer: 'inputNumberRenderer',
        cellRendererParams: (params: any) => {
          return {
            field: 'avg60day',
            maxDecimals: 2,
            disabled: this.logicService.isDataFromRun(params.data) || !isProjectOwnershipValid,
          }
        },
        filter: 'agSetColumnFilter',
        wrapText: false,
        resizable: true,
        sortable: true,
        editable: false,
        hide: isEU
      },
      {
        headerName: 'BBCH (earliest)',
        field: 'bbchEarliest',
        width: 160,
        cellRenderer: 'inputTextRender',
        cellRendererParams: (params: any) => {
          return {
            disabled: this.logicService.isDataFromRun(params.data) || !isProjectOwnershipValid,
          }
        },
        filter: 'agSetColumnFilter',
        wrapText: false,
        resizable: true,
        sortable: true,
        editable: false,
      },
      {
        headerName: 'Dominant Entry Route',
        field: 'dominantEnrtyRoute',
        width: 140,
        cellRenderer: 'dropdownlistRenderer',
        cellRendererParams: (params: any) => {
          return {
            options: this.logicService.fillDropdownOptionsFromSingleList(ERAConstants.DOMINANT_MIGRATION_GROUP),
            optionValue: 'optionValue',
            optionLabel: 'optionLabel',
            filterable: true,
            disabled: this.logicService.isDataFromRun(params.data) || !isProjectOwnershipValid,
          }
        },
        filter: 'agSetColumnFilter',
        wrapText: false,
        resizable: true,
        sortable: true,
        editable: false,
        hide: isUSA
      },
      {
        headerName: 'Global Max SW Date',
        field: 'globalMaxSwDate',
        width: 200,
        cellRenderer: 'inputTextRender',
        cellRendererParams: (params: any) => {
          return {
            disabled: this.logicService.isDataFromRun(params.data) || !isProjectOwnershipValid,
          }
        },
        filter: 'agSetColumnFilter',
        wrapText: false,
        resizable: true,
        sortable: true,
        editable: false,
      },
      {
        headerName: 'Application Date',
        field: 'applicationDate',
        width: 200,
        cellRenderer: 'inputTextRender',
        cellRendererParams: (params: any) => {
          return {
            disabled: this.logicService.isDataFromRun(params.data) || !isProjectOwnershipValid,
          }
        },
        filter: 'agSetColumnFilter',
        wrapText: false,
        resizable: true,
        sortable: true,
        editable: false,
      },
      {
        headerName: '',
        colId: 'duplicate',
        field: 'duplicateButton',
        cellRenderer: DuplicateButtonRenderer,
        type: 'rightAligned',
        width: 130,
        cellRendererParams: (params: any) =>{
            return {
              disabled: this.logicService.isDataFromRun(params.data) || !isProjectOwnershipValid,
              mainTablePkName: "resultSurfaceWaterPk",
              instantDuplication:true
            }
        }        
      },     
      {
        headerName: '',
        width: 125,
        minWidth: 125,
        editable: false,
        colId: 'action',
        cellRenderer: 'deleteButtonRenderer',
        cellRendererParams: (params: any) => {
          return {
            disabled: this.logicService.isDataFromRun(params.data) || !isProjectOwnershipValid,
          }
        },
        type: 'rightAligned',
      }
    ];
  }
}
