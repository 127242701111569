<div class="grid" *ngIf="!isLoading">
  <div class="col-12 p-3 mt-3">
    <app-grid #grid
      [domLayout]="'autoHeight'"
      [columnDefs]="columnDefs"
      [style]="style"
      [rowHeight]="rowHeight"
      [rowMultiSelectWithClick]="true"
      [rowSelection]="'multiple'"
      [suppressRowClickSelection]="true"
      [rowData]="rowData"
      [useAddAction]="isProjectOwnershipValid"
      addLabel="Add PPE Scheme"
      (deleteEvent)="saveData($event)"
      (saveEvent)="saveData($event)"
      (refreshEvent)="getData()"
      [paginationPageSize]= 12
      (addEvent)="newRowAdded($event)"
      (onBlurInputText)="onBlurInputText($event)"
      (onSelectedRowEvent)="onSelectedRow($event)"
      (onRowSelectedEmit)="onSelectionChanged($event)"
    ></app-grid>
  </div>
</div>
<app-loading
  class="mt-2 flex align-items-center justify-content-center"
  *ngIf="isLoading"
></app-loading>
