<div class="grid border-bottom-5 border-dotted border-300 border-x-none border-top-none pt-5 pb-2">
    <div class="col-12 flex justify-content-center">
        <div class="col-7 ml-8">
            <div class="grid">
                <div class="col-12 flex justify-content-start">
                    <label *ngIf="currentUserCanRunModels" class="pt-3 text-secundary text-xl">Create a new project or select from existing:</label>
                    <label *ngIf="!currentUserCanRunModels" class="pt-3 text-secundary text-xl">Select from existing projects:</label>
                </div>
                <div *ngIf="currentUserCanRunModels" class="col-12 mt-2 flex justify-content-start">
                    <label class="pt-3 text-secundary text-xl"><i class="pi pi-arrow-right" style="font-size: 1rem; color: orange"></i> Create new project:</label>
                    <app-button class="ml-4 mt-2" [icon]="'pi pi-plus'" [iconPos]="'right'" [rounded]="true" [raised]="true" [disabled]="isESAUDLRunProjectView"
                        [severity]="'warning'" (onClickEvent)="initNewProject()"></app-button>
                </div>
                <div class="col-12 flex justify-content-start pt-5">
                    <div class="pt-2 pr-3">
                        <label class="text-secundary text-xl"><i class="pi pi-arrow-right" style="font-size: 1rem; color: orange"></i>  Select from existing:</label>
                    </div>
                    <div class="col-6">
                        <div class="flex flex-column h-full">
                            <p-dropdown #selectedProjectDDL [(ngModel)]="selectedProject"  [options]="isESAUDL ? searchUdlFilteredProjects : searchFilteredProjects"
                                [placeholder]="getPlaceHolder()" [showClear]="false" [filter]="true"
                                filterBy="name, projectPk" [filterFields]="['name','projectPk']"  scrollHeight="600px"
                                (onChange)="onSelectionChanged($event.value)">
                                <ng-template pTemplate="selectedItem">
                                    <div class="flex align-items-center text-lg"
                                        *ngIf="selectedProject && selectedProject.projectPk > 0">
                                        <div>
                                            <p class="project"><b>{{ selectedProject.name }}
                                                </b><em>{{selectedProject.projectPk}}</em></p>
                                        </div>
                                    </div>
                                    <div class="flex align-items-center text-lg"
                                        *ngIf="!selectedProject">
                                        <div>
                                            <p class="project h-1-5"></p>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template let-project pTemplate="item">
                                    <div class="flex align-items-center text-lg">
                                        <div>
                                            <p><b>{{ project.name }} </b><em>{{project.projectPk}}</em></p>
                                        </div>
                                    </div>
                                </ng-template>
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="col ml-3">
                        <app-button [label]="'My Projects'" [size]="'large'" [text]="true" [plain]="true"
                            [icon]="selectedProjectApiService.myProjectsFilter ? 'pi pi-check-square': 'pi pi-stop'" [iconPos]="'left'"
                            (onClickEvent)="userFilterProjects(!selectedProjectApiService.myProjectsFilter)"></app-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-4 flex justify-content-end flex-wrap">
            <div class="align-self-end mb-1" *ngIf="isEEA">
                <div class="ml-6">
                    <img src="assets/img/ppt.png" style="width: 40px; height: 40px;">
                </div>
                <div>
                    <a href="https://agcompany.sharepoint.com/:p:/r/teams/ras/regsci/Shared Documents/BaMS Training.pptx?d=we6d25c8c4f72485f8d7a838b30dd420e&csf=1&web=1" style="color: #0069BE;" target="_blank">Slide-deck</a>
                    <a href="https://agcompany.sharepoint.com/teams/ras/regsci/_layouts/15/stream.aspx?id=%2Fteams%2Fras%2Fregsci%2FShared Documents%2FBaMS Training Session - _2 (Attend one of the two)-20240912_100233-Meeting Recording.mp4&referrer=StreamWebApp.Web&referrerScenario=AddressBarCopied.view.6d359f2c-71c8-4f7b-bba4-a07dd0d16d10" style="margin-left: 10px; color: #0069BE" target="_blank">Training video</a>
                </div>
            </div>

            <app-button class="ml-5 align-self-end" [label]="'Advanced Filters'" [size]="'large'" [text]="true" [plain]="true"
                [icon]="'pi pi-filter'" [iconPos]="'left'" [disabled]="isESAUDLRunProjectView"
                (onClickEvent)="showAdvancedFiltersDialog()"></app-button>
        </div>
        <div class="col">

        </div>
    </div>
</div>
<app-advanced-filters-dialog (rowSelected)="handleAdvancedFilterRow($event)" *ngIf="this.advancedFiltersVisibles"></app-advanced-filters-dialog>
