import { Component, DestroyRef, EventEmitter, Input, OnDestroy, Output, inject } from '@angular/core';
import { Project } from 'src/app/shared/models/project';
import { Compartment } from 'src/app/shared/models/echo/compartment';
import { EndpointParameterCompound, EndpointParameterDetail } from 'src/app/shared/models/endpoint-parameter';
import { DataSet } from 'src/app/shared/models/echo/data-set';
import { DataSetApiService } from 'src/app/shared/services/echo/data-set.api.service';
import { Constants } from 'src/app/shared/utils/constants';
import { ProjectXMolecule } from 'src/app/shared/models/project-x-molecule';
import { MetaboliteBamsApiService } from '../../../shared/services/metabolite-bams.api.service';
import { CompoundPkAndName } from 'src/app/shared/models/compound';
import { MetabolitePkAndName, MetaboliteXModel } from 'src/app/shared/models/echo/metabolite';
import { TabMenuLogicService } from 'src/app/shared/services/tab-menu.logic.service';
import { MetaboliteApiService } from 'src/app/shared/services/echo/metabolite.api.service';
import { UserLogicService } from 'src/app/shared/services/user.logic.service';
import { SelectedProjectApiService } from 'src/app/shared/components/selected-project/selected-project.api.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EndpointDatasetLogicService } from 'src/app/shared/components/endpoint-dataset/endpoint-dataset.logic.service';
import { Molecule, MoleculePkAndName } from 'src/app/shared/models/echo/molecule';

@Component({
  selector: 'app-eea-endpoints-by-compartment',
  templateUrl: './eea-endpoints-by-compartment.component.html',
  styleUrls: ['./eea-endpoints-by-compartment.component.css']
})
export class EEAEndpointsByCompartmentComponent {
  @Input() selectedProject?: Project;
  @Input() compartmentList: Compartment[] = [];
  @Input() compartment: string;
  @Input() menuService!: TabMenuLogicService;
  @Input() subTabMenuService!: TabMenuLogicService;
  @Input() isProjectOwnershipValid: boolean = false;

  @Output() isValid = new EventEmitter<boolean>();

  public coreDefaultValues: EndpointParameterDetail[];
  public dataset: DataSet;
  public isLoading: boolean;
  public metabolitesOptions: MetabolitePkAndName[];
  public moleculeMetabolites: MetabolitePkAndName[];
  public moleculePk: number;
  public compoundPk: number;
  public precursorOptions: CompoundPkAndName[];
  public projectPk: number;
  public selectedCompound: EndpointParameterCompound | undefined;
  public selectedMetabolitePk?: number;
  public selectedModels: string[];
  public showDetail: boolean;
  public isProjectDataSetOwnershipValid: boolean;
  public isPwc: boolean = false;

  private projects: Project[] = [];
  private datasetPk: number;
  private metabolitesPk: number[];
  private allMetabolites: MetabolitePkAndName[] = [];
  private allMolecules: MoleculePkAndName[] = [];
  readonly environmentalAssessment = Constants.ENVIRONMENTAL_ASSESSMENTS.ENVIRONMENTAL_EXPOSURE_ASSESSMENT;
  destroyRef = inject(DestroyRef);

  constructor(private readonly dataSetApiService: DataSetApiService,
    private readonly metaboliteBamsApiService: MetaboliteBamsApiService,
    private readonly MetaboliteEchoApiService: MetaboliteApiService,
    private readonly userLogicService: UserLogicService,
    private readonly selectedProjectAPIService: SelectedProjectApiService,
    private readonly endpoinDataSetLogicService: EndpointDatasetLogicService) {
    this.compartment = '';
    this.coreDefaultValues = [];
    this.dataset = {} as DataSet;
    this.datasetPk = 0;
    this.isLoading = false;
    this.metabolitesOptions = [];
    this.moleculeMetabolites = [];
    this.metabolitesPk = [];
    this.moleculePk = 0;
    this.compoundPk = 0;
    this.precursorOptions = [];
    this.projectPk = 0;
    this.selectedModels = [];
    this.showDetail = false;
    this.isProjectDataSetOwnershipValid = false;
  }

  ngOnInit(): void {
    this.setIsPwc();
    this.initSubscribes();
  }

  public getDatasetDataOutput(dataset: any): void {
    this.dataset = dataset;
    this.dataset.compoundPk = this.dataset.substanceType === Constants.SUBSTANCE_TYPES.ACTIVE ? dataset.moleculePk : dataset.metabolitePk;
    this.projectPk = dataset.projectPk;
    this.moleculePk = dataset.moleculePk;
    this.compoundPk = this.dataset.compoundPk!;
    this.datasetPk = this.dataset.dataSetPk ? this.dataset.dataSetPk : 0;
    this.isProjectDataSetOwnershipValid = this.isProjectDataSetOwnership(dataset);

    if( this.isPwc ){
      this.isLoading = false;
      this.getSelectedCompound(this.dataset);
      return;
    }

    this.getCoreParametersDefaultValues();
    this.fillMetabolitesAndPrecursorDropdown();
  }

  private isProjectDataSetOwnership(dataSet: any): boolean {
    if(!this.isProjectOwnershipValid) return false;
    return dataSet?.createdBy != null ? dataSet?.createdBy === this.userLogicService?.profile?.displayName :
    this.projects.find((project) => project.projectPk === dataSet.originalProject)?.createdBy === this.userLogicService?.profile?.displayName;
  }

  public getSelectedModels(models: any): void {
    this.selectedModels = models;
  }

  public getSelectedMetabolite(metabolitePk: number): void {
    this.selectedCompound = {
      compoundPk: metabolitePk,
      compoundType: 'Metabolite',
      datasetPk: this.datasetPk,
      projectPk: this.selectedProject?.projectPk ?? -1,
      activePk: this.moleculePk,
      compoundName: this.allMetabolites.find(o => o.metabolitePk == metabolitePk)!.metaboliteName
    };
    this.selectedMetabolitePk = metabolitePk;
    this.fillPrecursorDropdown();
  }

  private initSubscribes(): void {
    this.selectedProjectAPIService.projects.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(projects => this.projects = projects);
  }

  public getSelectedActiveIngredient(compoundPk: number): void {
    if (compoundPk == undefined)
      this.selectedCompound = undefined;
    else{
      if(compoundPk === this.dataset.compoundPk){
        this.selectedCompound = {
          compoundPk: compoundPk,
          compoundType: 'Active',
          datasetPk: this.datasetPk,
          projectPk: this.selectedProject?.projectPk ?? -1,
          activePk: compoundPk,
          compoundName: this.dataset.activeIngredient!
        };
        this.selectedMetabolitePk = undefined;
      }else{
        this.getSelectedMetabolite (this.dataset.metabolitePk!)
      }
    }
  }

  public getSelectedCompound(dataSet: DataSet): void {
    if (dataSet.compoundPk == undefined)
      this.selectedCompound = undefined;
    else{
      dataSet.substanceType == Constants.SUBSTANCE_TYPES.ACTIVE ?
          this.getSelectedActiveIngredient(dataSet.moleculePk!) :
          this.getSelectedMetabolite (dataSet.metabolitePk!)
    }
  }

  public showDetailOutput(show: boolean): void {
    this.showDetail = show;
    this.selectedCompound = show ? this.selectedCompound : undefined;
  }

  private getCoreParametersDefaultValues(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.getCoreDataSet().then((dataset: DataSet[]) => {
        this.dataSetApiService.getCoreEndpointsByDataSets(dataset).subscribe({
          next: (data: any) => {
            data?.forEach((x: any) => {
              x.endpoints.forEach((e: any) => {
                const type: string = e.substanceType;
                const pk: number = e.metabolitePk ? e.metabolitePk : e.moleculePk;
                e.groupedEndpointFieldValues.forEach((g: any) => {
                  this.coreDefaultValues.push({
                    type: type,
                    pk: pk,
                    dataValueName: g.key,
                    value: g.value
                  });
                });

              });
            });
            this.isLoading = false;
            resolve();
          },
          error: (error: Error) => {
            reject(error);
          }
        });
      });
    });
  }

  private getCoreDataSet(): Promise<DataSet[]> {
    this.isLoading = true;
    return new Promise<DataSet[]>((resolve, reject) => {
      this.dataSetApiService.getCoreDataSetByProjectPkAndMoleculePk(this.projectPk, this.moleculePk).subscribe({
        next: (dataset: DataSet[]) => {
          resolve(dataset);
        },
        error: (error: Error) => {
          reject(error);
        }
      });
    });
  }

  private fillMetabolitesAndPrecursorDropdown(): void {
    this.metabolitesOptions.length = 0;
    this.getMetabolitesByProjectAndMolecule().then((metabolitesXModels: MetaboliteXModel[]) => {
      this.setMetabolitesPkByCompartment(metabolitesXModels);
      this.getMetabolitesPkAndName();
      this.getAllMoleculesPkAndName();
    });
  }

  private getMetabolitesByProjectAndMolecule(): Promise<MetaboliteXModel[]> {
    const projectXMolecule: ProjectXMolecule = { projectPk: this.projectPk, moleculepk: this.moleculePk };
    return new Promise<MetaboliteXModel[]>((resolve, reject) => {
      this.metaboliteBamsApiService.getMetabolitesByProjectAndMolecule(projectXMolecule).subscribe({
        next: (metabolitesXModels: MetaboliteXModel[]) => {
          resolve(metabolitesXModels);
        },
        error: (error: Error) => {
          reject(error);
        }
      });
    });
  }

  private setMetabolitesPkByCompartment(metabolitesXModels: MetaboliteXModel[]): void {
    const currentModels: string[] | undefined = Constants.SPECIFIC_MODELS_BY_COMPARTMENT.find(m => m.compartment == this.compartment)?.models;
    const metabolitesPks: number[] = [];
    metabolitesXModels.forEach(metaboliteXModel => {
      if (currentModels?.some(c => c == metaboliteXModel.model))
        metabolitesPks.push(metaboliteXModel.metabolitePK);
    });
    this.metabolitesPk = metabolitesPks;
  }

  private getAllMoleculesPkAndName(): void {
    this.allMolecules = this.endpoinDataSetLogicService.allMolecules;
  }

  private getMetabolitesPkAndName(): void {
    this.allMetabolites = this.endpoinDataSetLogicService.allMetabolites;
    this.metabolitesPk.forEach(metabolitePk => {
      const metabolite = this.allMetabolites.find(c => c.metabolitePk == metabolitePk);
      if (!this.metabolitesOptions.some(option => option.metaboliteName == metabolite?.metaboliteName))
        this.metabolitesOptions.push(metabolite!);
    });
    this.moleculeMetabolites = [...this.metabolitesOptions];
    this.metabolitesOptions = this.metabolitesOptions.filter(m => m.metabolitePk != this.compoundPk);
    
    if (this.selectedProject)
      this.metabolitesOptions = this.metabolitesOptions.filter(m => this.selectedProject?.projectXCompoundXModel?.some(PxCxM => PxCxM.MetabolitePk === m.metabolitePk && (PxCxM.disabled == undefined || PxCxM.disabled == false)));

    this.fillPrecursorDropdown();
    this.getSelectedCompound(this.dataset);
  }

  private fillPrecursorDropdown(): void {
    this.precursorOptions.length = 0;
    const { activeIngredient, moleculePk, substanceType, metabolitePk } = this.dataset;
    let compoundPk =  (substanceType === Constants.SUBSTANCE_TYPES.ACTIVE ? moleculePk : metabolitePk) ?? 0;
    let compoundName =  (substanceType === Constants.SUBSTANCE_TYPES.ACTIVE ? activeIngredient : this.allMetabolites?.find(x => x.metabolitePk == metabolitePk)?.metaboliteName) ?? '';
    this.precursorOptions.push({ compoundName: compoundName, compoundPk: compoundPk, compoundType: substanceType! });
    this.fillGroundwaterDropDownPrecursorOptions(this.precursorOptions[0]);
  }

  private fillGroundwaterDropDownPrecursorOptions(parentPrecursor: CompoundPkAndName): void {
    if (this.compartment == Constants.COMPARTMENTS.GROUND_WATER)
      {
        this.moleculeMetabolites.filter(m => m.metaboliteName !== this.selectedCompound?.compoundName && parentPrecursor.compoundPk !== m.metabolitePk).forEach(m => {
          this.precursorOptions.push({ compoundName: m.metaboliteName, compoundPk: m.metabolitePk, compoundType: Constants.SUBSTANCE_TYPES.METABOLITE })
        });
      }
  }

  public getIsDatasetValid(isValid: boolean): void {
    this.isValid.emit(isValid);
  }

  private setIsPwc(): void {
    this.isPwc = this.selectedProject?.projectXCompoundXModel?.map(PxCxM => PxCxM.ModelName)
    .some(model => model === Constants.MODELS.PWC) ?? false;
  }
}
