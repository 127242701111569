<p-dialog [(visible)]="visible" [style]="dialogStyle" (onHide)="close()" [maximizable]="true">
  <div>
    <p-dropdown class="h-full" #selectedProjectDDL [(ngModel)]="selectedProjectCopy" [options]="filteredProjectsCopy"
      [placeholder]="'Select a Project'" optionLabel="name" [showClear]="false" [filter]="true"
      filterBy="name, projectPk" [filterFields]="['name','projectPk']" scrollHeight="700px"
      (onChange)="onSelectionChanged($event)">
      <ng-template pTemplate="selectedItem">
        <div class="flex align-items-center text-lg"
          *ngIf="selectedProjectCopy && selectedProjectCopy.projectPk > 0 && !loading">
          <div>
            <p class="project"><b>{{ selectedProjectCopy.name }}
              </b><em>{{selectedProjectCopy.projectPk}}</em></p>
          </div>
        </div>
        <div class="flex align-items-center text-lg" *ngIf="!selectedProjectCopy">
          <div>
            <p class="project h-1-5"></p>
          </div>
        </div>
      </ng-template>
      <ng-template let-project pTemplate="item">
        <div class="flex align-items-center text-lg template-item">
          <div>
            <p><b>{{ project.name }} </b><em>{{project.projectPk}}</em></p>
          </div>
        </div>
      </ng-template>
    </p-dropdown>
  </div>
  <p-scrollPanel class="col-12 mt-4 scrollpanel-style" *ngIf="showDataSetGrid">
    <p class="col-12 mb-4 text-lg font-bold" >Please select the dataset(s) you want to include. Select if you want to create a copy or just a reference of the dataset(s) selected. When creating a copy, assign a new dataset name. </p>
    <app-grid #grid [columnDefs]="columnDefs" class="grid-style" [rowHeight]="rowHeight" [rowData]="rowData" [useAddAction]="false" (dropDownSelectionChangeEvent)="onGridSelectionChanged($event)"
             [useDeleteAction]="false" (onBlurInputText)="onBlurInputText($event)" [suppressRowClickSelection]="true" [rowSelection]="'multiple'" (onRowSelectedEmit)="onRowSelected($event)"></app-grid>
  </p-scrollPanel>
  <app-loading class="col-12 mt-2 flex align-items-center justify-content-center" *ngIf="isLoading"></app-loading>
  <ng-template pTemplate="footer">
    <p-button icon="pi pi-check" (click)="closeDialog()" label="Copy Project" pAutoFocus [autofocus]="true" [disabled]="disableButton"></p-button>
  </ng-template>

</p-dialog>

<p-blockUI [blocked]="blockUi">
  <i class="pi pi-spin pi-spinner blockui-style"></i>
</p-blockUI>
