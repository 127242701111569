import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpApiService } from 'src/app/shared/services/http.api.service';
import { ApplicationScheme } from 'src/app/shared/models/pwc/application-scheme';
import { PWCInputs } from 'src/app/shared/models/run/pwc-inputs';

@Injectable({
  providedIn: 'root'
})
export class EEAGapApiService extends HttpApiService{
  override apiUrl: string = environment.BAMS_Api_URL;

  
  getApplicationSchemesByProjectAndCompartment(pProjectPk: number, pCompartmentPk: number): Observable<any[]> {
    return this
      .get(`api/applicationSchemesByProjectAndCompartment/${pProjectPk}/${pCompartmentPk}`)
      .pipe(map((data: any) => data.Value));
  }

  getApplicationSchemesByProjectWithAllCompartments(pProjectPk: number): Observable<any[]> {
    return this
      .get(`api/GetApplicationSchemesByProjectWithAllCompartments/${pProjectPk}`)
      .pipe(map((data: any) => data.Value));
  }
  
  getApplicationSchemesByProjectAndCompartmentPWC(pProjectPk: number, pCompartmentPk: number): Observable<ApplicationScheme[]> {
    return this
      .get(`api/applicationSchemesByProjectAndCompartmentPWC/${pProjectPk}/${pCompartmentPk}`)
      .pipe(map((data: any) => data.Value));
  }

  save(pData: any): Observable<any> {
    const body = JSON.stringify(pData);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    let applicationScheme = `api/applicationSchemes`;
    return this
      .put(applicationScheme, body,  headers ).
      pipe(map((data: any) => data.Value));
  }

  getAndCopyApplicationSchemesByProjectAndCompartment(pProjectPk?: number, projectNew?: number): Observable<number> {
    return this
      .get(`api/GetAndCopyApplicationSchemesByProjectAndCompartment/${pProjectPk}/${projectNew}`)
      .pipe(map((data: any) => data.Value));
  }

  savePWC(pData: any): Observable<any> {
    const body = JSON.stringify(pData);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    let applicationScheme = `api/applicationSchemesPWC`;
    return this
      .put(applicationScheme, body,  headers ).
      pipe(map((data: any) => data.Value));
  }
  
  DeleteDetailsPWC(pk: number): Observable<any> {
    let applicationScheme = `api/applicationSchemesXApplication/${pk}`;
    return this
      .delete(applicationScheme).
      pipe(map((data: any) => data.Value));
  }

  runPwc(pwcList: PWCInputs[]) {
    const body = JSON.stringify(pwcList);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    const query = `api/runPwc`;
    return this
      .put(query, body,  headers ).
      pipe(map((data: any) => data));
  }

  getScenarioFilesPaths(statesXCrops: any): Observable<any> {
    return this
      .put(`api/PWC/GetScenarioFilesPaths`, statesXCrops)
      .pipe(map((data: any) => data.Value));
  }
}
