import { Component, DestroyRef, EventEmitter, Input, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Compartment } from 'src/app/shared/models/echo/compartment';
import { Project } from 'src/app/shared/models/project';
import { DataSetApiService } from 'src/app/shared/services/echo/data-set.api.service';
import { Constants } from 'src/app/shared/utils/constants';
import { EEAEndpointsLogicService } from './eea-endpoints.logic.service';
import { Message } from 'primeng/api';
import { TabMenuLogicService } from 'src/app/shared/services/tab-menu.logic.service';
import { EndpointDatasetLogicService } from 'src/app/shared/components/endpoint-dataset/endpoint-dataset.logic.service';
import { CustomTabMenuItem } from 'src/app/shared/models/custom-tab-menu-item';
import { EEALogicService } from '../../environmental-exposure-assessment/environmental-exposure-assessment.logic.service';
import { InverseModelingEEALogicService } from 'src/app/inverse-modeling-environmental-exposure-assesment/inverse-modeling-environmental-exposure-assesment/inverse-modeling-environmental-exposure-assesment.logic.service';
import { UserLogicService } from 'src/app/shared/services/user.logic.service';

@Component({
  selector: 'app-eea-endpoints',
  templateUrl: './eea-endpoints.component.html',
  styleUrls: ['./eea-endpoints.component.css']
})
export class EEAEndpointsComponent implements OnInit, OnDestroy {
  @Input() isInverseModeling: boolean = false;
  @Input() selectedProject?: Project;
  @Input() menuService!: TabMenuLogicService;

  @Output() isValid = new EventEmitter<boolean>();

  activeItem!: any;
  compartmentList: Compartment[] = [];
  destroyRef = inject(DestroyRef)
  afterSearch: boolean = false;
  message!: Message;
  loading: boolean = false;
  loadingWizard: boolean = false;
  nextIndex?: number;
  tabsToDisplay: CustomTabMenuItem[] = [];
  isProjectOwnershipValid: boolean = false;
  pwcFlag: boolean = false;
  public isEndpointByCompartmentValid: boolean = true;

  constructor(private datasetService: DataSetApiService,
    private EEALogicService: EEALogicService,
    public EEAEndpointsLogicService: EEAEndpointsLogicService,
    private InverseModelingEEALogicService: InverseModelingEEALogicService,
    private readonly endpointDataSetLogicService: EndpointDatasetLogicService,
    private userLogicService: UserLogicService) { }

  ngOnInit(): void {    
    let isInit = true;
    this.endpointDataSetLogicService.isInverseModeling = this.isInverseModeling;
    this.setLoadingState(true);

    this.isProjectOwnershipValid = this.userLogicService.verifyProjectOwnership(this.selectedProject?.createdBy);

    this.menuService.activeIndexChanged.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => this.menuService.setSuccess(true));

    this.EEAEndpointsLogicService.activeIndexChanged.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => this.EEAEndpointsLogicService.setSuccess(true));

    if (!this.isInverseModeling) {
      this.EEALogicService.usedModelsSubject.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((usedModels: string[]) => {
        this.tabsToDisplay = this.EEAEndpointsLogicService.items.filter(item => usedModels.map(model=> model.toLowerCase()).includes(item.model ? item.model.toLowerCase(): ''));    
        if (this.selectedProject?.geography === Constants.CROP_GEOGRAPHIES.UK) {
          this.tabsToDisplay = this.tabsToDisplay.filter(item => item.label !== Constants.EXPOSURE_ASSESSMENT_ENDPOINTS[0].label);
        }
        if(this.tabsToDisplay.length === 1) {
          isInit = false;
          this.EEAEndpointsLogicService.setActiveItem(this.tabsToDisplay[0]);
        }
        if(this.tabsToDisplay.length === 2 && this.selectedProject?.geography === Constants.CROP_GEOGRAPHIES.USA) {
          isInit = false;
          this.EEAEndpointsLogicService.setActiveItem(this.tabsToDisplay[1]);
        }
      });
    }

    if (this.isInverseModeling) {
      this.InverseModelingEEALogicService.usedModelsSubject.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((usedModels: string[]) => {
        this.tabsToDisplay = this.EEAEndpointsLogicService.items.filter(item => usedModels.map(model=> model.toLowerCase()).includes(item.model ? item.model.toLowerCase(): ''));        
        if (this.selectedProject?.geography === Constants.CROP_GEOGRAPHIES.UK) {
          this.tabsToDisplay = this.tabsToDisplay.filter(item => item.label !== Constants.EXPOSURE_ASSESSMENT_ENDPOINTS[0].label);
        }
        if(this.tabsToDisplay.length === 1) {
          isInit = false;
          this.EEAEndpointsLogicService.setActiveItem(this.tabsToDisplay[0]);
        }
      });
    }
    this.isPWC();
    this.EEAEndpointsLogicService.activeItem.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(activeItem => this.activeItem = isInit ? {} : activeItem);
    this.EEAEndpointsLogicService.success.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(success => {
      this.loadingWizard = false;
      if (success) {
        const nextItem = this.EEAEndpointsLogicService.items.find(i => i.index == this.nextIndex);
        if (nextItem && !isInit)
          this.EEAEndpointsLogicService.setActiveItem(nextItem);
      }
      isInit = false;
    });

    this.getCompartments();
  }

  onActiveIndexChange(index: number) {
    this.nextIndex = index;
    this.EEAEndpointsLogicService.setActiveIndexChanged(true, index);
  }

  getCompartments() {
    this.datasetService.getCompartments(Constants.CONST_COMPARTMENT).subscribe((compartment => {
      this.compartmentList = compartment;
      this.setLoadingState(false);
    }));
  }

  setLoadingState(pState: boolean): void {
    this.loading = pState;
    this.afterSearch = !pState;
  }

  ngOnDestroy() {
    this.endpointDataSetLogicService.updateSelectedDataSet(undefined);
    this.EEAEndpointsLogicService.resetActiveItem();
  }

  public getIsEndpointByCompartmentValid(isValid: boolean): void {
    this.isEndpointByCompartmentValid = isValid;
    this.isValid.emit(isValid);
  }

  isPWC() {
    if (this.selectedProject?.projectXCompoundXModel?.find(a => a.ModelName === Constants.MODELS.PWC)) {
      this.tabsToDisplay = Constants.EXPOSURE_ASSESSMENT_ENDPOINTS_PWC;
      this.pwcFlag = true;
    } else {
      this.pwcFlag = false;
    }
  }
}
