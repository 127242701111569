import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { ExcelWriterService } from '../../services/excel-writer.service';

@Component({
  selector: 'app-action-buttons-panel',
  templateUrl: './action-buttons-panel.component.html',
  styleUrls: ['./action-buttons-panel.component.css']
})
export class ActionButtonsPanelRenderer implements OnInit {
  private onDownloadCompleteSubscription: Subscription | undefined;

  @Input() isSaving!: boolean;

  @Input() useSaveAction: boolean = false;
  @Input() useAddAction: boolean = false;
  @Input() useServerExcelExportAction: boolean = false;
  @Input() addLabel: string = 'Add';
  @Input() useCustomAction: boolean = false;
  @Input() customLabel: string = '';
  
  @Output() onBtServerExcelExportEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() onBtAddEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() onBtSaveEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() onBtnCustomEvent: EventEmitter<any> = new EventEmitter<any>();
  public isDownloading : boolean = false;
  
  constructor(private exportService: ExcelWriterService) {}
  ngOnInit() {
    this.onDownloadCompleteSubscription = this.exportService.downloadCompleted.subscribe(() => {
        this.isDownloading = false; 
    });
}
  add() {
    this.onBtAddEvent.emit()
  }

  save() {
    this.onBtSaveEvent.emit();
  }

  custom(){
    this.onBtnCustomEvent.emit();
  }

  onBtServerExcelExport() {
    this.isDownloading = true; 
    this.onBtServerExcelExportEvent.emit(); 
  }

  ngOnDestroy() {
    if (this.onDownloadCompleteSubscription) {
      this.onDownloadCompleteSubscription.unsubscribe();
    }
  }

}