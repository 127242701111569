import { Component, Input, OnChanges, OnInit, SimpleChanges, } from '@angular/core';
import { EeaGapNonCoreCompartmentComponent } from '../eea-gap-non-core-compartment/eea-gap-non-core-compartment.component';
import { Constants } from 'src/app/shared/utils/constants';
import { EEAGapApiService } from '../eea-gap.api.service';
import { catchError, forkJoin, take } from 'rxjs';
import { CropList } from 'src/app/shared/models/crop-list';
import { EEAGapSoilColdef } from './eea-gap-soil.coldef';
import { EEAGapLogicService } from '../eea-gap.logic.service';
import { EEAGAPMenuLogicService } from '../eea-gap/eea-gap-menu.logic.service';
import { ApplicationDateApiService } from 'src/app/shared/services/application-date.api.service';
import { Utils } from 'src/app/shared/utils/utils';
import { DatePipe } from '@angular/common';
import { RowNode } from 'ag-grid-enterprise';
import { GapApplicationSchemeLogicService } from 'src/app/shared/services/gap-application-scheme.logic.service';
import { ExcelWriterService } from 'src/app/shared/services/excel-writer.service';

@Component({
  selector: 'app-eea-gap-soil',
  templateUrl: '../eea-gap-non-core-compartment/eea-gap-non-core-compartment.component.html',
  styleUrls: ['./eea-gap-soil.component.css']
})
export class EEAGapSoilComponent extends EeaGapNonCoreCompartmentComponent implements OnInit, OnChanges {
  override compartment: string = Constants.COMPARTMENTS.SOIL;

  @Input() override isProjectOwnershipValid: boolean = false;

  constructor(
    private gapSoilApiService: EEAGapApiService,
    private gapSoilColdef: EEAGapSoilColdef,
    private gapLogicSoilService: EEAGapLogicService,
    EEAGAPMenuLogicService: EEAGAPMenuLogicService,
    private applicationDateSoilApiService: ApplicationDateApiService,
    datepipe: DatePipe,
    gapApplicationSchemeLogicService: GapApplicationSchemeLogicService,
    gapExcelWriterService: ExcelWriterService,) {
    super(gapSoilApiService, gapLogicSoilService, EEAGAPMenuLogicService, applicationDateSoilApiService, datepipe, gapApplicationSchemeLogicService, gapExcelWriterService);
  }

  override fillInitValues(changes: SimpleChanges): void {
    let promises = [
      this.gapLogicSoilService.getCompartments(),
      this.gapLogicSoilService.getRegionCountry(changes['selectedProject'].currentValue),
      this.gapLogicSoilService.getCropInterceptions(),
      this.gapLogicSoilService.getAllCropList(),
      this.gapLogicSoilService.getCropListMatchings(),
    ]
    Promise.all(promises)
      .then((results) => {
        this.compartmentList = results[0];
        this.geographiesList = results[1];
        this.cropList = results[3];
        this.cropListMatchings = results[4];
        this.getApplicationSchemesByProjectAndCompartment(changes['selectedProject'].currentValue.projectPk, this.getCoreCompartment());
      })
  }

  override getColDefFilteredBySelectedModels() {
    if (!this.selectedProject) return;
    let models = this.gapLogicSoilService.getModelsByCompartment(this.compartment, this.selectedProject);
    let columnsByModel = this.gapLogicSoilService.getDisplayedColumnsByModel(models, this.selectedProject?.geography!);

    const colDefParams = {
      bbch: this.gapLogicSoilService.bbch,
      numerOfApplications: this.gapLogicSoilService.numerOfApplications,
      geographiesList: this.geographiesList,
      cropList: (this.selectedProject?.geography == Constants.CROP_GEOGRAPHIES.USA) ? this.cropList?.filter((x: CropList) => x.Geography === Constants.CROP_GEOGRAPHIES.USA) : this.cropList,
      geographySelected: this.selectedProject?.geography,
      cropListMatching: this.cropListMatchings,
      simulatorYear: this.gapLogicSoilService.simulatorYear,
    };

    const columnsDefinition = this.isInverseModeling
      ? this.gapSoilColdef.getInverseColumnsDefinition(colDefParams, this.selectedProject, this.isProjectOwnershipValid)
      : this.gapSoilColdef.getColumnsDefinition(colDefParams, this.isProjectOwnershipValid);

    return this.gapLogicSoilService.getFilteredColumns([...Constants.GAP_CORE_FIELDS, ...new Set(columnsByModel), ...this.fixedColumns], columnsDefinition);
  }

  override getApplicationSchemesByProjectAndCompartment(projectPk: number, compartmentPk: number) {
    if (projectPk != 0) {
      this.loading = true;
      forkJoin({
        applicationSchemes: this.gapSoilApiService.getApplicationSchemesByProjectAndCompartment(projectPk, compartmentPk),
      })
        .pipe(
          catchError((error) => {
            console.error(error);
            this.loading = false;
            return [];
          })
        )
        .subscribe({
          next: ({ applicationSchemes }) => {
            if (!this.selectedProject) return;
            this.gapLogicSoilService.addActiveIngredientsRatesToGrid(applicationSchemes, this.getColDefFilteredBySelectedModels(), this.isInverseModeling, this.selectedProject, this.isProjectOwnershipValid, this.compartment).then(() => {
              this.rowData = this.gapLogicSoilService.rowData;
              this.rowData.forEach((x: any) => {
                let selectedModels = (this.selectedProject?.projectXCompoundXModel?.map(model => model?.ModelName) ?? []) as string[];
                let interceptionModel = Utils.getFirstMatchBetweenTwoArrays(Constants.MODELS_WITH_INTERCEPTIONS, selectedModels);
                x.selectedInterceptionModel = interceptionModel;
                if (x.interception == null)
                  this.gapLogicSoilService.setCropInterceptionByCropAndBBCH(x, this.compartment);
              });
              this.columsDef = this.gapLogicSoilService.columsDef;
              this.refreshSelectedValuesTimeout();
              this.setLoadingState(false);
            });
          },
        });
    }
  }

  override saveData(dataTransaction: any[]) {
    this.gapLogicSoilService.createTransactionsForRates(dataTransaction, this.grid, this.isInverseModeling);
    if (dataTransaction.length == 0) {
      this.EEAGAPMenuLogicService.setSuccess(true);
      return;
    }

    if (!this.selectedProject) return;
    this.gapLogicSoilService.setParametersToNewRows(dataTransaction, this.compartmentPk, this.isInverseModeling, this.selectedProject?.projectPk, this.isCore, this.compartment, this.cropListMatchings, this.selectedProject?.geography);
    this.gapLogicSoilService.setActiveIngredientsRate(dataTransaction, this.isCore, this.compartmentList, this.isInverseModeling, this.compartment);
    dataTransaction?.forEach((x: any) => {
      x.row.tillage = x.row.tillage == 'Yes' ? true : (x.row.tillage != undefined ? false : null);
      x.row.soilDepth = x.row.soilDepth == undefined ? 5 : x.row.soilDepth;
      x.row.plateauSoilDepth = x.row.plateauSoilDepth == undefined ? 5 : x.row.plateauSoilDepth;
    });
    this.gapSoilApiService.save(dataTransaction).pipe(take(1)).subscribe({
      next: () => {
        this.grid.afterSaveSuccess();
        this.EEAGAPMenuLogicService.setSuccess(true);
      },
      error: (err: any) => {
        console.warn(err);
        this.EEAGAPMenuLogicService.setSuccess(false);
      }
    });
  }

  override onDropDownSelectionChanged(event: any) {
    event.row.geographies = event.row.applicationSchemeXGeography?.length > 0 ? this.geographiesList.filter((x: any) => event.row.applicationSchemeXGeography.map((y: any) => y.geographyPk).includes(x.key)) : [];
    if (event.field === Constants.GAP_FIELD_NAMES.INTERCEPTION_PK || event.field === Constants.GAP_FIELD_NAMES.BBCH) {
      let selectedModels = (this.selectedProject?.projectXCompoundXModel?.map(model => model?.ModelName) ?? []) as string[];
      let interceptionModel = Utils.getFirstMatchBetweenTwoArrays(Constants.MODELS_WITH_INTERCEPTIONS, selectedModels);
      event.row.selectedInterceptionModel = interceptionModel;
      if (event.field === Constants.GAP_FIELD_NAMES.INTERCEPTION_PK) {
        event.row.cropInterceptionPk = event.value;
      }
      this.gapLogicSoilService.setCropInterceptionByCropAndBBCH(event.row, this.compartment);
    }
    if (event.field === Constants.GAP_FIELD_NAMES.APPLICATION_NUMBER) {
      if (event.value === 1) {
        event.row.applicationInterval = 1;
      }
      this.grid.gridApi.redrawRows();
    }
    this.grid.gridApi.refreshCells();
  }

  override onFirstDataRendered(params: any): void {
    if (params?.type === Constants.GRID_EVENTS.FIRST_DATA_RENDERED) {
      this.grid.gridApi.forEachNode((node: RowNode) => {
        if (node?.data?.coreApplicationSchemePk) {
          node!.data.reloadCore = this.validateCoreCropPK(node, this.compartment, this.selectedProject?.geography!, this.cropListMatchings);
        }
        if (node?.data?.numberOfApplications === 1) node!.data.applicationInterval = 1;
      });
      this.grid.gridApi.redrawRows();
      this.grid.gridApi.forEachNode((node: RowNode) => { node!.data.reloadCore = false; });
    }
  }

}
