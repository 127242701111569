//AgGrid Imports
import { AgGridModule } from 'ag-grid-angular';

import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ESASetupComponent } from './esa-setup/esa-setup/esa-setup.component';
import { ESASetupInputsComponent } from './esa-setup/esa-setup-inputs/esa-setup-inputs.component';
import { ESASetupModelsToBeRunComponent } from './esa-setup/esa-setup-models-to-be-run/esa-setup-models-to-be-run.component';
import { ESACopyProjectDialogComponent } from './esa-setup/shared/esa-copy-project-dialog/esa-copy-project-dialog.component';
import { ESARunProjectComponent } from './esa-run-project/esa-run-project.component';
import { FormsModule } from '@angular/forms';
import { OrderByPipe } from 'src/app/shared/pipes/order-by.pipe';
import { SharedModule } from 'src/app/shared/shared.module';
import { UDLOverlapToolComponent } from './udl-overlap-tool/udl-overlap-tool.component';

@NgModule({
  declarations: [
    ESASetupInputsComponent,
    ESASetupModelsToBeRunComponent,
    ESASetupComponent,
    ESACopyProjectDialogComponent,
    ESARunProjectComponent,
    UDLOverlapToolComponent
  ],
  imports: [
    AgGridModule,
    FormsModule,
    CommonModule,
    SharedModule
  ],
  providers:[
    OrderByPipe,
    DatePipe
  ],
  exports:[
    ESACopyProjectDialogComponent,
    ESASetupComponent
  ]
})
export class UDLOverlapToolModule { }
