export const environment = {
  production: false,
  envName: 'qa',
  redirectUri: 'https://bams-app-test.se.research.corteva.com/bamsui/',
  BAMS_Api_URL: 'https://fsd-bams-func-test.azurewebsites.net/',
  ECHOURI: 'https://fsd-echo-app-test.azurewebsites.net/echoui/#/external/',
  ECHO_Api_URL: 'https://fsd-echo-func-test.azurewebsites.net/',
  GAPURI: 'https://fsd-gap-app-test.azurewebsites.net/gapui/#/external/',
  GAP_Api_URL: 'https://fsd-gap-func-test.azurewebsites.net/',
  ListManagerCoreApiURL: 'https://USINI2AS019.phibred.com/QA/Services/CoreApi_ListManager/',
};