<div *ngIf="!isLoading" class="col-12 p-3 mt-3">
  <app-grid #grid [domLayout]="'autoHeight'" [columnDefs]="columnDefs" [rowMultiSelectWithClick]="true"
    [rowSelection]="'multiple'" [suppressRowClickSelection]="true" [style]="style" [rowData]="rowData"
    [useAddAction]="true" addLabel="Add Scheme" (addEvent)="newRowAdded($event)"
    [useOnlyEventNewRow]="true" (deleteEvent)="saveData($event, false)" (saveEvent)="saveData($event)"
    (refreshEvent)="getData()" (onSelectedRowEvent)="onSelectedRow($event)" 
    (dropDownSelectionChangeEvent)="dropDownSelectionChangeEvent($event)" (onBlurInputText)="onBlurInputText($event)"
    (onRowSelectedEmit)="onSelectionChanged($event)" [animateRows]="true" [groupDisplayType]="'custom'">
  </app-grid>
</div>
<app-loading class="mt-2 flex align-items-center justify-content-center" *ngIf="isLoading"></app-loading>