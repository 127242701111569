import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-button-expand-grid',
  templateUrl: './button-expand-grid.component.html',
  styleUrls: ['./button-expand-grid.component.css']
})
export class ButtonExpandGridRenderer implements ICellRendererAngularComp {

  public iconClass?: string;
  public label?: string;
  public disabled: boolean = false;

  private showIcon: string = 'pi pi-chevron-right';
  private hideIcon: string = 'pi pi-chevron-down';

  private showLabel: string = 'Show';
  private hideLabel: string = 'Hide';

  private parent: any;
  private params: any;

  refresh(): boolean {
    return false;
  }

  agInit(params: any,): void {
    this.params = params;
    this.parent = params.context;
    this.showIcon = params.showIcon ?? this.showIcon;
    this.hideIcon = params.hideIcon ?? this.hideIcon;
    this.showLabel = params.showLabel ?? this.showLabel;
    this.hideLabel = params.hideLabel ?? this.hideLabel;
    this.iconClass = this.params.data.isExpanded ? this.showIcon : this.hideIcon;
    this.label = this.params.data.isExpanded ? this.hideLabel : this.showLabel;
    this.disabled = params.disabled ? params.disabled : false;
  }

  onClick(): void {
    this.params.data.isExpanded = !this.params.data.isExpanded;
    this.iconClass = this.params.data.isExpanded ? this.showIcon : this.hideIcon;
    this.parent.onButtonExpandClick(this.params);
  }
}