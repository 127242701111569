import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DataSet } from '../../models/echo/data-set';
import { SearchObject } from '../../models/search-object';
import { ImportDataSet } from '../../models/echo/import-data-set';
import { HttpApiService } from '../http.api.service';
import { Compartment } from '../../models/echo/compartment';
import { MetaboliteNPrecursors } from '../../models/echo/metabolite-n-precursors';

@Injectable({
  providedIn: 'root'
})
export class DataSetApiService extends HttpApiService {
  override apiUrl: string = environment.ECHO_Api_URL;

  getDataSets(): Observable<DataSet[]> {
    return this.get(`api/datasets`).pipe(map((data: any) => data.Value));
  }

  getDataSetsWithEndpointsByFilter(pFilters: SearchObject): Observable<DataSet[]> {
    const body = JSON.stringify(pFilters);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    const endpoint = `api/dataSetsWithEndpoints`;
    return this
      .put(endpoint, body, headers)
      .pipe(map((data: any) => data.Value));
  }

  importDataSetInfo(pCopyDataSet: ImportDataSet): Observable<any> {
    const body = JSON.stringify(pCopyDataSet);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    const endpoint = `api/importDataSetInfo`;
    return this
      .post(endpoint, body, headers)
      .pipe(map((data: any) => data.Value));
  }

  getDataSetsWithRegion(pSection: string): Observable<DataSet[]> {
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    let endpoint = `api/dataSetsWithRegions/${pSection}`; //Ref, T01
    return this
      .put(endpoint, headers)
      .pipe(map((data: any) => data.Value));
  }

  getEndpointsByDataSets(pDataSets: DataSet[]): Observable<any[]> {
    const body = JSON.stringify(pDataSets);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    const endpoint = `api/endPointsByDataSets`;
    return this
      .put(endpoint, body, headers)
      .pipe(map((data: any) => data.Value));
  }
  getEndpointsByDataSetsExpoTox(pDataSets: DataSet[]): Observable<any[]> {
    const body = JSON.stringify(pDataSets);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    const endpoint = `api/endPointsByDataSetsExpoTox`;
    return this
      .put(endpoint, body, headers)
      .pipe(map((data: any) => data.Value));
  }


  getEndpointsByDataSetsTaxon(pDataSets: DataSet[], taxonGroup: string): Observable<DataSet[]> {
    const body = JSON.stringify(pDataSets);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    const endpoint = `api/ecotoxEndpoints/${taxonGroup}`;
    return this
      .put(endpoint, body, headers)
      .pipe(map((data: any) => data.Value));
  }

  saveData(pData: any): Observable<any> {
    const body = JSON.stringify(pData);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    const endpoint = `api/dataSet`;
    return this
      .post(endpoint, body, headers).
      pipe(map((data: any) => data.Value));
  }

  getCompartments(compartment: string): Observable<Compartment[]> {
    let endpoint = `api/compartmentForDataSet?value=${compartment}`;
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this
      .get(endpoint, headers)
      .pipe(map((data: any) => data.Value));
  }

  getDataSetsProjectByCompartment(projectPk: number, source: string): Observable<DataSet[]> {
    let endpoint = `api/datasetsByProject?projectPk=${projectPk}&source=${source}`;
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this
      .get(endpoint, headers)
      .pipe(map((data: any) => data.Value));
  }

  GetCoreDatasetsByProject(projectPk: number): Observable<DataSet[]> {
    let endpoint = `api/coreDatasetsByProject?projectPk=${projectPk}`;
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this
      .get(endpoint, headers)
      .pipe(map((data: any) => data.Value));
  }

  saveCoreDataSet(pData: any): Observable<any> {
    const body = JSON.stringify(pData);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    const endpoint = `api/coreDataSet`;
    return this
      .post(endpoint, body, headers).
      pipe(map((data: any) => data.Value));
  }

  getCoreEndpointsByDataSets(pDataSets: DataSet[]): Observable<any[]> {
    const body = JSON.stringify(pDataSets);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    const endpoint = `api/coreEndpointsByDataSets`;
    return this
      .put(endpoint, body, headers)
      .pipe(map((data: any) => data.Value));
  }

  getCoreDataSetByProjectPkAndMoleculePk(projectPk: number, moleculePk: number): Observable<DataSet[]> {
    let endpoint = `api/GetCoreDataSetByProjectPkAndMoleculePk/${projectPk}/${moleculePk}`;
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this
      .get(endpoint, headers)
      .pipe(map((data: any) => data.Value));
  }

  getEndpointsByDataSetsByTaxonGroup(pDataSets: DataSet[], taxonGroup: string): Observable<any[]> {
    const body = JSON.stringify(pDataSets);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    const endpoint = `api/ecotoxEndpoints/${taxonGroup}`;
    return this
      .put(endpoint, body, headers)
      .pipe(map((data: any) => data.Value));
  }

  setDataSetByProject(pData: any): Observable<any> {
    const body = JSON.stringify(pData);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    const endpoint = `api/DatasetByProject/Save`;
    return this
      .post(endpoint, body, headers).
      pipe(map((data: any) => data.Value));
  }

  removeDataSetByProjectAndPk(projectPk: number, dataSetPks: number[]): Observable<any> {
    const body = JSON.stringify(dataSetPks);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    const endpoint = `api/deleteDataSetByProjectAndPk/${projectPk}`;
    return this
      .put(endpoint, body, headers)
      .pipe(map((data: any) => data.Value));
  }

  getAllDatasetByProjectPk(projectPk: number): Observable<any> {
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    const endpoint = `api/getAllDatasetByProjectPk/${projectPk}`;
    return this
      .get(endpoint, headers)
      .pipe(map((data: any) => data.Value));
  }
}
