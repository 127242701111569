import { Injectable } from '@angular/core';
import { ColGroupDef } from 'ag-grid-enterprise';
import { DropdownListRenderer } from 'src/app/shared/renderers/dropdown-list/dropdown-list.component';
import { InputNumberRenderer } from 'src/app/shared/renderers/input-number/input-number.component';
import { InputTextRenderer } from 'src/app/shared/renderers/input-text/input-text.component';
import { HHRAConstants } from '../../hhra-constants';
import { HhraGapApiService } from './hhra-gap-non-dietary.api.service';
import { DuplicateButtonRenderer } from 'src/app/shared/renderers/duplicate-button/duplicate-button.component';
import { InputTextApplicationSchemeRenderer } from 'src/app/shared/renderers/input-text/input-text-application-scheme/input-text-application-scheme.component';

@Injectable({
    providedIn: 'root',
})

export class HhraGapNonDietaryColdef {

    constructor(private hhraGapApiService: HhraGapApiService) { }
    public getColumnsDefinition(isProjectOwnershipValid: boolean, isOPEXFormulationTypeSolid: boolean): ColGroupDef[] {
        return [
            {
                headerName: '',
                children: [
                    {
                        headerName: 'Application Scheme',
                        field: 'name',
                        type: 'default',
                        width: 300,
                        cellRenderer: InputTextApplicationSchemeRenderer,
                        headerCheckboxSelection: isProjectOwnershipValid,
                        checkboxSelection: isProjectOwnershipValid,
                        cellRendererParams: {
                            disabled: !isProjectOwnershipValid,
                            isRequired: true
                        },
                        editable: false,
                        cellEditorParams: {
                            maxLength: 200,
                        },
                        filter: true,
                        pinned: 'left',
                        cellStyle: (params: { data: { automatic: any } }) => {
                            return params.data.automatic ? { color: '#aaaaaa' } : null;
                        },
                    },
                    {
                        headerName: 'Crop Type',
                        field: 'cropType',
                        type: 'default',
                        filter: 'agSetColumnFilter',
                        cellRenderer: DropdownListRenderer,
                        cellRendererParams: {
                            options: HHRAConstants.HHRA_CROP_TYPES,
                            optionLabel: 'name',
                            optionValue: 'name',
                            link: ['activity', 'indoorOutdoorApplication', 'applicationMethod', 'applicationEquipment', 'season'],
                            disabled: !isProjectOwnershipValid,
                        },
                        wrapText: true,
                        resizable: true,
                        sortable: true,
                        width: 270,
                        editable: false,
                    },
                    {
                        headerName: 'Activity',
                        field: 'activity',
                        type: 'default',
                        filter: 'agSetColumnFilter',
                        cellRenderer: DropdownListRenderer,
                        cellRendererParams: {
                            options: HHRAConstants.HHRA_CROP_ACTIVITIES,
                            optionValue: 'name',
                            optionLabel: 'name',
                            checkbox: true,
                            filter: false,
                            filteredBy: [HHRAConstants.HHRA_RELATED_FIELDS_INDEX.CROP_TYPE],
                            multiple: true,
                            disabled: !isProjectOwnershipValid,
                        },
                        wrapText: true,
                        resizable: true,
                        sortable: true,
                        width: 325,
                        editable: false,
                    },
                    {
                        headerName: 'Indoor or Outdoor Application',
                        field: 'indoorOutdoorApplication',
                        type: 'default',
                        filter: 'agSetColumnFilter',
                        cellRenderer: DropdownListRenderer,
                        cellRendererParams: {
                            options: HHRAConstants.HHRA_INDOOR_OUTDOOR_APPLICATION,
                            optionValue: 'name',
                            optionLabel: 'name',
                            disabled: !isProjectOwnershipValid,
                            filteredBy: [HHRAConstants.HHRA_RELATED_FIELDS_INDEX.CROP_TYPE],
                            link: isOPEXFormulationTypeSolid ? ['applicationEquipment', 'applicationMethod'] : ['applicationEquipment'],
                        },
                        wrapText: true,
                        resizable: true,
                        sortable: true,
                        width: 150,
                        editable: false,
                    },
                    {
                        headerName: 'Number of Applications',
                        field: 'numberOfApplications',
                        type: 'select',
                        suppressSizeToFit: true,
                        width: 120,
                        editable: false,
                        filter: 'agSetColumnfilter',
                        cellRenderer: DropdownListRenderer,
                        cellRendererParams: (params: any) => {
                            return {
                                instance: this,
                                options: Array.from({ length: 10 }, (_, i) => i + 1),
                                columnThatModifies: 'numberOfApplications',
                                height: '300px',
                                disabled: !isProjectOwnershipValid,
                            };
                        }
                    },
                    {
                        headerName: 'Application Interval',
                        field: 'applicationInterval',
                        width: 110,
                        suppressSizeToFit: true,
                        cellRenderer: InputNumberRenderer,
                        cellRendererParams: {
                            disabled: !isProjectOwnershipValid,
                        },
                        filter: 'agSetColumnFilter',
                        type: 'default',
                        editable: false,
                    },
                    {
                        headerName: 'Max. Application rate of the product [L or kg/ha]',
                        wrapText: true,
                        resizable: true,
                        editable: false,
                        sortable: true,
                        field: 'maxProductApplicationRate',
                        cellRenderer: InputNumberRenderer,
                        cellRendererParams: {
                            disabled: !isProjectOwnershipValid,
                        },
                        width: 170,
                        type: 'default',
                        filter: 'agSetColumnFilter',
                    }
                ]
            },
            {
                headerName: 'Concentration of active substance [g a.s./L or Kg]',
                children: []
            },
            {
                headerName: '',
                children: [
                    {
                        headerName: 'Application Water Volume (L/ha)',
                        children: [
                            {
                                headerName: 'Min',
                                editable: false,
                                wrapText: true,
                                resizable: true,
                                sortable: true,
                                cellRenderer: InputNumberRenderer,
                                cellRendererParams: {
                                    disabled: !isProjectOwnershipValid,
                                },
                                field: 'applicationWaterVolumeMin',
                                width: 100,
                                type: 'default',
                                filter: 'agSetColumnFilter',
                            },
                            {
                                headerName: 'Max',
                                editable: false,
                                wrapText: true,
                                resizable: true,
                                sortable: true,
                                field: 'applicationWaterVolumeMax',
                                width: 100,
                                type: 'default',
                                filter: 'agSetColumnFilter',
                                cellRenderer: InputNumberRenderer,
                                cellRendererParams: {
                                    disabled: !isProjectOwnershipValid,
                                },
                            }
                        ]
                    }
                ]
            },
            {
                headerName: '',
                children: [
                    {
                        headerName: 'Application Method',
                        field: 'applicationMethod',
                        type: 'default',
                        filter: 'agSetColumnFilter',
                        cellRenderer: DropdownListRenderer,
                        cellRendererParams: {
                            options: isOPEXFormulationTypeSolid ? HHRAConstants.HHRA_APPLICATION_METHODS_GRANULES : HHRAConstants.HHRA_APPLICATION_METHODS_SPRAY,
                            optionValue: 'name',
                            optionLabel: 'name',
                            filteredBy: isOPEXFormulationTypeSolid ? [HHRAConstants.HHRA_RELATED_FIELDS_INDEX.INDOOR_OUTDOOR_APPLICATION] : [HHRAConstants.HHRA_RELATED_FIELDS_INDEX.CROP_TYPE],
                            link: ['applicationEquipment', 'season', 'bufferStrip', 'applicationClosedCab'],
                            disabled: !isProjectOwnershipValid,
                        },
                        wrapText: true,
                        resizable: true,
                        sortable: true,
                        width: 200,
                        editable: false,
                    },
                    {
                        headerName: 'Application equipment',
                        field: 'applicationEquipment',
                        type: 'default',
                        filter: 'agSetColumnFilter',
                        cellRenderer: DropdownListRenderer,
                        cellRendererParams: {
                            options: isOPEXFormulationTypeSolid ? HHRAConstants.HHRA_APPLICATION_EQUIPMENT_GRANULES : HHRAConstants.HHRA_APPLICATION_EQUIPMENT_SPRAY,
                            optionValue: 'name',
                            optionLabel: 'name',
                            filteredBy: [HHRAConstants.HHRA_RELATED_FIELDS_INDEX.CROP_TYPE, HHRAConstants.HHRA_RELATED_FIELDS_INDEX.INDOOR_OUTDOOR_APPLICATION, isOPEXFormulationTypeSolid ? HHRAConstants.HHRA_RELATED_FIELDS_INDEX.APPLICATION_METHODS_GRANULES : HHRAConstants.HHRA_RELATED_FIELDS_INDEX.APPLICATION_METHODS_SPRAY],
                            checkbox: true,
                            filter: false,
                            link: ['applicationClosedCab'],
                            multiple: true,
                            disabled: !isProjectOwnershipValid,
                        },
                        wrapText: true,
                        resizable: true,
                        sortable: true,
                        width: 200,
                        editable: false,
                    },
                    {
                        headerName: 'Closed cab (RMM)',
                        field: 'applicationClosedCab',
                        type: 'default',
                        filter: 'agSetColumnFilter',
                        cellRenderer: DropdownListRenderer,
                        cellRendererParams: {
                            options: HHRAConstants.HHRA_CLOSED_CAB,
                            filteredBy: [HHRAConstants.HHRA_RELATED_FIELDS_INDEX.APPLICATION_METHODS_SPRAY, HHRAConstants.HHRA_RELATED_FIELDS_INDEX.APPLICATION_EQUIPMENT],
                            optionValue: 'value',
                            optionLabel: 'name',
                            link: ['applicationClosedCab'],
                            disabled: !isProjectOwnershipValid,
                        },
                        wrapText: true,
                        resizable: true,
                        sortable: true,
                        width: 180,
                        minWidth: 180,
                        editable: false,
                    },
                    {
                        headerName: 'Buffer strip',
                        field: 'bufferStrip',
                        type: 'default',
                        filter: 'agSetColumnFilter',
                        cellRenderer: DropdownListRenderer,
                        cellRendererParams: {
                            options: HHRAConstants.HHRA_BUFFER_STRIPS,
                            optionValue: 'name',
                            optionLabel: 'name',
                            checkbox: true,
                            filter: false,
                            multiple: true,
                            disabled: !isProjectOwnershipValid,
                        },
                        wrapText: true,
                        resizable: true,
                        sortable: true,
                        width: 110,
                        editable: false,
                    },
                    {
                        headerName: 'Season',
                        field: 'season',
                        type: 'default',
                        filter: 'agSetColumnFilter',
                        cellRenderer: DropdownListRenderer,
                        cellRendererParams: {
                            options: HHRAConstants.HHRA_SEASONS,
                            optionValue: 'name',
                            optionLabel: 'name',
                            filteredBy: [HHRAConstants.HHRA_RELATED_FIELDS_INDEX.CROP_TYPE, isOPEXFormulationTypeSolid ? HHRAConstants.HHRA_RELATED_FIELDS_INDEX.APPLICATION_METHODS_GRANULES : HHRAConstants.HHRA_RELATED_FIELDS_INDEX.APPLICATION_METHODS_SPRAY],
                            checkbox: true,
                            filter: false,
                            multiple: true,
                            disabled: !isProjectOwnershipValid,
                        },
                        wrapText: true,
                        resizable: true,
                        sortable: true,
                        width: 200,
                        editable: false,
                    },
                    {
                        headerName: 'Drift reduction',
                        field: 'driftReduction',
                        type: 'default',
                        filter: 'agSetColumnFilter',
                        cellRenderer: DropdownListRenderer,
                        cellRendererParams: {
                            options: HHRAConstants.HHRA_DRIFT_REDUCTION,
                            optionValue: 'name',
                            optionLabel: 'name',
                            checkbox: true,
                            filter: false,
                            multiple: true,
                            disabled: !isProjectOwnershipValid,
                        },
                        wrapText: true,
                        resizable: true,
                        sortable: true,
                        width: 110,
                        editable: false,
                    }
                ]
            },
            
            {
                headerName: '',
                children: [
                   {
                        headerName: '',
                        colId: 'duplicate',
                        field: 'duplicateButton',
                        cellRenderer: DuplicateButtonRenderer,
                        type: 'rightAligned',
                        width: 130,
                        cellRendererParams: {
                            disabled: isProjectOwnershipValid,
                            mainTablePkName: "applicationSchemePk"
                        }        
                    },                    
                    {
                    colId: 'delete',
                    width: 125,
                    minWidth: 125,
                    editable: false,
                    cellRenderer: 'deleteButtonRenderer',
                    cellRendererParams: {
                        disabled: !isProjectOwnershipValid
                    },
                    type: 'rightAligned'
                }]
            }
        ];
    }

    public async getUSAColumnsDefinition(isProjectOwnershipValid: boolean): Promise<any> {
        this.hhraGapApiService.getHumanHealthApplicationSchemeExposureScenarios();
        return [
            {
                headerName: 'Exposure Scheme',
                width: 400,
                showRowGroup: 'name',
                cellRenderer: "agGroupCellRenderer",
                headerCheckboxSelection: isProjectOwnershipValid,
                checkboxSelection: isProjectOwnershipValid,
                cellRendererParams: {
                    disabled: !isProjectOwnershipValid,
                    suppressCount: true,
                    innerRenderer: InputTextRenderer
                },
                editable: false,
                filter: true,
                pinned: 'left',
                cellStyle: (params: { data: { automatic: any } }) => {
                    return params.data?.automatic ? { color: '#aaaaaa' } : null;
                },
            }, {
                headerName: 'Exposure Scenario',
                children: [
                    {
                        headerName: 'Worker Activity',
                        field: 'workerActivity',
                        type: 'default',
                        filter: 'agSetColumnFilter',
                        cellRenderer: DropdownListRenderer,
                        cellRendererParams: {
                            options: await this.hhraGapApiService.getUniqueExposureScenarioValue('workerActivity'),
                            optionValue: 'label',
                            optionLabel: 'value',
                            disabled: !isProjectOwnershipValid,
                            link: ['workerActivity', 'formulation', 'applicationEquipment', 'applicationType', 'cropTargetCategory'],
                            filterAll: true,
                        },
                        wrapText: true,
                        resizable: true,
                        sortable: true,
                        width: 155,
                        editable: false,
                    },
                    {
                        headerName: 'Formulation',
                        field: 'formulation',
                        type: 'default',
                        filter: 'agSetColumnFilter',
                        cellRenderer: DropdownListRenderer,
                        cellRendererParams: {
                            options: await this.hhraGapApiService.getUniqueExposureScenarioValue('formulation'),
                            optionValue: 'label',
                            optionLabel: 'value',
                            disabled: !isProjectOwnershipValid,
                            link: ['workerActivity', 'formulation', 'applicationEquipment', 'applicationType', 'cropTargetCategory'],
                            filterAll: true,
                        },
                        wrapText: true,
                        resizable: true,
                        sortable: true,
                        width: 250,
                        editable: false,
                    },
                    {
                        headerName: 'Application Equipment',
                        field: 'applicationEquipment',
                        type: 'default',
                        filter: 'agSetColumnFilter',
                        cellRenderer: DropdownListRenderer,
                        cellRendererParams: {
                            options: await this.hhraGapApiService.getUniqueExposureScenarioValue('applicationEquipment'),
                            optionValue: 'label',
                            optionLabel: 'value',
                            disabled: !isProjectOwnershipValid,
                            link: ['workerActivity', 'formulation', 'applicationEquipment', 'applicationType', 'cropTargetCategory'],
                            filterAll: true,
                        },
                        wrapText: true,
                        resizable: true,
                        sortable: true,
                        width: 350,
                        editable: false,
                    },
                    {
                        headerName: 'Application Type',
                        field: 'applicationType',
                        type: 'default',
                        filter: 'agSetColumnFilter',
                        cellRenderer: DropdownListRenderer,
                        cellRendererParams: {
                            options: await this.hhraGapApiService.getUniqueExposureScenarioValue('applicationType'),
                            optionValue: 'label',
                            optionLabel: 'value',
                            disabled: !isProjectOwnershipValid,
                            link: ['workerActivity', 'formulation', 'applicationEquipment', 'applicationType', 'cropTargetCategory'],
                            filterAll: true,
                        },
                        wrapText: true,
                        resizable: true,
                        sortable: true,
                        width: 250,
                        editable: false,
                    }
                ]
            },
            {
                headerName: '',
                children: [
                    {
                        headerName: 'Crop / Target Category',
                        field: 'cropTargetCategory',
                        filter: 'agSetColumnFilter',
                        cellRenderer: DropdownListRenderer,
                        cellRendererParams: {
                            options: await this.hhraGapApiService.getUniqueExposureScenarioValue('cropTargetCategory'),
                            optionValue: 'label',
                            optionLabel: 'value',
                            disabled: !isProjectOwnershipValid,
                            link: ['workerActivity', 'formulation', 'applicationEquipment', 'applicationType', 'cropTargetCategory'],
                            filterAll: true,
                        },
                        wrapText: true,
                        resizable: true,
                        sortable: true,
                        width: 400,
                        editable: false,
                    }
                ]
            },
            {
                headerName: 'Application Rate',
                children: []
            },
            {
                headerName: 'Level of PPE or Engineering Control',
                children: [
                    {
                        headerName: 'Dermal',
                        field: 'dermal',
                        type: 'default',
                        filter: 'agSetColumnFilter',
                        cellRenderer: DropdownListRenderer,
                        cellRendererParams: {
                            options: HHRAConstants.HHRA_USA_DERMAL_UOM,
                            optionValue: 'name',
                            optionLabel: 'name',
                            disabled: !isProjectOwnershipValid,
                        },
                        wrapText: true,
                        resizable: true,
                        sortable: true,
                        width: 140,
                        editable: false,
                    },
                    {
                        headerName: 'Inhalation',
                        field: 'inhalation',
                        type: 'default',
                        filter: 'agSetColumnFilter',
                        cellRenderer: DropdownListRenderer,
                        cellRendererParams: {
                            options: HHRAConstants.HHRA_USA_INHALATION_UOM,
                            optionValue: 'name',
                            optionLabel: 'name',
                            disabled: !isProjectOwnershipValid,
                        },
                        wrapText: true,
                        resizable: true,
                        sortable: true,
                        width: 145,
                        editable: false,
                    }
                ]
            },
            {
                headerName: '',
                children: [                                    
                    {
                        colId: 'delete',
                        width: 125,
                        minWidth: 125,
                        editable: false,
                        cellRenderer: 'deleteButtonRenderer',
                        cellRendererParams: {
                            disabled: !isProjectOwnershipValid,
                    },
                    type: 'rightAligned'
                }]
            },
            {
                field: 'name',
                rowGroup: true,
                hide: true,
                width: 400,
            }
        ];
    }
}