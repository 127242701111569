<app-selected-project [module]="module"></app-selected-project>
<div *ngIf="!isProjectOwnershipValid && selectedProject" class="isProjectOwnershipValid">
    <p>You can not modify or run someone's project</p>
</div>
<div *ngIf="selectedProject" class="pt-1 pr-5 pb-5 pl-5">
    <app-wizard class="surface-200" [items]="HHRALogicService.items" [message]="message" [loading]="loading"
        [activeItem]="activeItem" [selectedProject]="selectedProject" [selectedProjectStatus]="selectedProjectStatus" [isSetupValid]="isSetupValid"
        (activeIndexChangeEmitter)="onActiveIndexChange($event)"></app-wizard>
    <div class="shadow-8 surface-0 p-3">
        <ng-container *ngIf="activeItem?.index == 0">
            <app-eea-setup [environmentalAssessment]="environmentalAssessments" [menuService]="HHRALogicService"  [modelsCollection]="modelsCollection" [disciplinePk]="disciplinePk" [columnDef]="columnDef" [isHHRA]="true" (isSetupValidEvent)="validateSetup($event)"></app-eea-setup>
        </ng-container>

        <ng-container *ngIf="activeItem?.index == 1">
            <app-human-health-risk-assessment-endpoints [selectedProject]="selectedProject" [menuService]="HHRALogicService"></app-human-health-risk-assessment-endpoints>
        </ng-container>

         <ng-container *ngIf="activeItem?.index == 2">
            <app-hhra-gap [selectedProject]="selectedProject" [menuService]="HHRALogicService" [isProjectOwnershipValid]="isProjectOwnershipValid" (isValid)="getIsValidApplicationScheme($event)"></app-hhra-gap>
        </ng-container>

        <ng-container *ngIf="activeItem?.index == 3">
                <app-personal-protection-equipment-scheme [selectedProject]="selectedProject" [menuService]="HHRALogicService" [isProjectOwnershipValid]="isProjectOwnershipValid" (isValid)="getIsValidApplicationScheme($event)" ></app-personal-protection-equipment-scheme>
        </ng-container>

        <ng-container *ngIf="activeItem?.index == 4">
            <app-hhra-project-qc (isProjectRunning)="validateQCResult($event)" [selectedProject]="selectedProject" [menuService]="HHRALogicService"></app-hhra-project-qc>
        </ng-container>

        <ng-container *ngIf="activeItem?.index == 5">
            <app-hhra-run-risk-assessment (isProjectRunning)="validateRunProjectTab()" [selectedProject]="selectedProject" [menuService]="HHRALogicService" [isProjectOwnershipValid]="isProjectOwnershipValid" ></app-hhra-run-risk-assessment>
        </ng-container>
    </div>
</div>
