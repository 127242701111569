import { Component, DestroyRef, EventEmitter, Input, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { Message } from 'primeng/api';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Project } from 'src/app/shared/models/project';
import { Compartment } from 'src/app/shared/models/echo/compartment';
import { EEAGAPMenuLogicService } from './eea-gap-menu.logic.service';
import { CustomTabMenuItem } from 'src/app/shared/models/custom-tab-menu-item';
import { EEALogicService } from '../../environmental-exposure-assessment/environmental-exposure-assessment.logic.service';
import { InverseModelingEEALogicService } from 'src/app/inverse-modeling-environmental-exposure-assesment/inverse-modeling-environmental-exposure-assesment/inverse-modeling-environmental-exposure-assesment.logic.service';
import { Constants } from 'src/app/shared/utils/constants';
import { UserLogicService } from 'src/app/shared/services/user.logic.service';
import { HHRALogicService } from 'src/app/human-health-risk-assessments/human-health-risk-assessment/human-health-risk-assessment.logic.service';

@Component({
  selector: 'app-eea-gap',
  templateUrl: './eea-gap.component.html',
  styleUrls: ['./eea-gap.component.css']
})
export class EEAGapComponent implements OnInit, OnDestroy {
  @Input() isInverseModeling: boolean = false;
  @Input() selectedProject?: Project;
  @Input() menuService!: EEALogicService | InverseModelingEEALogicService | HHRALogicService;
  @Input() pwcFlag!: boolean;

  @Output() isValid = new EventEmitter<boolean>();
  activeItem!: any;
  destroyRef = inject(DestroyRef);
  afterSearch: boolean = false;
  compartmentList: Compartment[] = [];
  message!: Message;
  loading: boolean = false;
  nextIndex?: number;
  tabsToDisplay: CustomTabMenuItem[] = [];
  isProjectOwnershipValid: boolean = false;
  isSetupValid: boolean = false;
  public isApplicationSchemeValid: boolean = true;

  constructor(public EEAGAPMenuLogicService: EEAGAPMenuLogicService,
    private userLogicService: UserLogicService) { }

  ngOnDestroy(): void {
    this.EEAGAPMenuLogicService.resetActiveItem();
  }

  ngOnInit(): void {
    let isInit = true;
    this.menuService.activeIndexChanged.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => this.menuService.setSuccess(true));
    this.EEAGAPMenuLogicService.activeIndexChanged.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => this.EEAGAPMenuLogicService.setSuccess(true));
    this.isProjectOwnershipValid = this.userLogicService.verifyProjectOwnership(this.selectedProject?.createdBy);
    
    this.menuService.usedModelsSubject.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((usedModels: string[]) => {
      this.tabsToDisplay = this.EEAGAPMenuLogicService.items.filter(item => usedModels.map(model => model.toLocaleLowerCase()).includes(item.model ? item.model.toLocaleLowerCase() : ''));
      if (this.isInverseModeling || this.selectedProject?.geography === Constants.CROP_GEOGRAPHIES.UK) {
        this.tabsToDisplay = this.tabsToDisplay.filter(item => item.label !== Constants.EXPOSURE_ASSESSMENT_GAPS[0].label);
      }

      this.tabsToDisplay = this.isInverseModeling ?  this.tabsToDisplay.filter(item => item.label !== Constants.COMPARTMENTS.SURFACE_WATER) : this.tabsToDisplay.filter(item => item.label !== Constants.COMPARTMENTS.AQUATIC);
      if(this.tabsToDisplay.length === 1) {
        isInit = false;
        this.EEAGAPMenuLogicService.setActiveItem(this.tabsToDisplay[0]);
      }
      
      if(this.tabsToDisplay.length === 2 && this.selectedProject?.geography === Constants.CROP_GEOGRAPHIES.USA) {
        isInit = false;
        this.EEAGAPMenuLogicService.setActiveItem(this.tabsToDisplay[1]);
      }
    });
    this.isPWC();
    
    this.EEAGAPMenuLogicService.activeItem.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(activeItem => this.activeItem =  isInit ? {} : activeItem);
    this.EEAGAPMenuLogicService.success.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(success => {
      this.loading = false;
      if (success) {
        const nextItem = this.EEAGAPMenuLogicService.items.find(i => i.index == this.nextIndex);
        if (nextItem  && !isInit)
          this.EEAGAPMenuLogicService.setActiveItem(nextItem);
      }
      isInit = false;
    });
  }

  onActiveIndexChange(index: number) {
    this.nextIndex = index;
    this.EEAGAPMenuLogicService.setActiveIndexChanged(true, index);
  }

  setLoadingState(pState: boolean): void {
    this.loading = pState;
    this.afterSearch = !pState;
  }

  isPWC() {
    if (this.selectedProject?.projectXCompoundXModel?.find(a => a.ModelName === Constants.MODELS.PWC)) {
      this.tabsToDisplay = Constants.EXPOSURE_ASSESSMENT_GAPS_PWC;
      this.pwcFlag = true;
    } else {
      this.pwcFlag = false;
    }
  }

  public getIsApplicationSchemeValid(isValid: boolean): void {
    this.isApplicationSchemeValid = isValid;
    this.isValid.emit(isValid);
  }
}
