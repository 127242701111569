import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Formulation } from 'src/app/shared/models/formulation';
import { Project } from 'src/app/shared/models/project';
import { ESACopyProjectDialogComponent } from '../shared/esa-copy-project-dialog/esa-copy-project-dialog.component';
import { EEAGapApiService } from 'src/app/environmental-exposure-assessment/eea-gap/eea-gap.api.service';
import { firstValueFrom, take } from 'rxjs';
import { EndpointDatasetService } from 'src/app/shared/components/endpoint-dataset/endpoint-dataset.service';
import { SelectedProjectApiService } from 'src/app/shared/components/selected-project/selected-project.api.service';
import Swal from 'sweetalert2';
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete';
import { FormulationApiService } from 'src/app/shared/services/echo/formulation.api.service';
import { UserLogicService } from 'src/app/shared/services/user.logic.service';
import { PersonalProtectionEquipmentSchemeSharedApiService } from 'src/app/shared/services/personal-protection-equipment-scheme-shared.api.service';
import { Constants } from 'src/app/shared/utils/constants';

@Component({
  selector: 'app-esa-setup-inputs',
  templateUrl: './esa-setup-inputs.component.html',
  styleUrls: ['./esa-setup-inputs.component.css']
})
export class ESASetupInputsComponent implements OnChanges {

  @Input() selectedProject?: Project;
  @Input() currentUserName: string = "";
  @Input() isProjectOwnershipValid: boolean = false;

  createdBy: string = "";

  @Output() onQueryFilterChangeEvent = new EventEmitter<string>();
  @Output() isSetupValidEvent = new EventEmitter<boolean>();

  isProjectNameValid: boolean = true;
  isProjectNameDuplicated: boolean = false;
  loading: boolean = false;
  isArchiveButtonDisabled: boolean = true;
  @ViewChild('appDialog') copyprojectDialog!: ESACopyProjectDialogComponent;

  constructor(private gapGWApiService: EEAGapApiService,
    public dataSetService: EndpointDatasetService,
    private userService: UserLogicService,
    public projectApiService: SelectedProjectApiService,
    private personalProtectionEquipmentSchemeSharedApiService: PersonalProtectionEquipmentSchemeSharedApiService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedProject']) {
      this.createdBy = this.selectedProject ? this.selectedProject.createdBy ? this.selectedProject.createdBy : "" : this.currentUserName;
      this.validateSelectedProject();
    }

    this.setArchiveButtonStatus();
  }

  onQueryFilterEvent({ query }: AutoCompleteCompleteEvent) {
    this.onQueryFilterChangeEvent.emit(query);
  }

  async projectNameChanged(value: string) {
    this.isProjectNameValid = value.length > 0;
    this.isProjectNameDuplicated = false;
    await firstValueFrom(this.projectApiService.projects).then(projects => {
      if (projects.find(p => p.name == value && p.name != '' && p.projectPk != this.selectedProject?.projectPk)) {
        this.isProjectNameValid = false;
        this.isProjectNameDuplicated = true;
      }
    });
    if (this.selectedProject)
      this.selectedProject.name = value;

    this.validateSetupInfoInProject();
  }

  projectDescriptionChanged(value: string) {
    if (this.selectedProject)
      this.selectedProject.description = value;
  }

  validateSelectedProject() {
    this.isProjectNameValid = this.selectedProject?.name ? this.selectedProject?.name.length > 0 : false;

    this.validateSetupInfoInProject();
  }

  validateSetupInfoInProject() {
    if (this.selectedProject?.name && this.isProjectNameValid) {
      this.isSetupValidEvent.emit(true);
      return;
    }
    this.isSetupValidEvent.emit(false);
  }

  copyProject() {
    if (this.selectedProject?.name != "")
      this.copyprojectDialog.showDialog(true, this.selectedProject?.projectPk);
  }

  onSaveDataProject(event: any) {
    const projectPkCopy = event.projectPkCopy;
    const datasetList = event.datasetList;

    this.loading = true;
    if (this.selectedProject?.projectPk == -1) {
      this.onCopyProject(projectPkCopy, -1, this.selectedProject?.name, datasetList);
    }
    else if (projectPkCopy != 0 && this.selectedProject?.projectPk != -1) {
      Swal.fire({
        text: 'Do you want to overwrite the project data?',
        showCancelButton: true,
        confirmButtonColor: '#0069be',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        icon: 'warning'
      }).then((result) => {
        if (result.value) {
          this.onCopyProject(projectPkCopy, this.selectedProject?.projectPk, '', datasetList);
        }
        else {
          this.loading = false;
        }
      });
    }
  }

  onCopyProject(projectPkCopy: number, projectPk?: number, nameProject?: string, datasetList?: any[]) {
    this.projectApiService.getAndCopyProject(projectPkCopy, projectPk, nameProject, this.currentUserName).pipe(take(1)).subscribe(projectNew => {
      this.gapGWApiService.getAndCopyApplicationSchemesByProjectAndCompartment(projectPkCopy, projectNew.projectPk)
        .pipe(take(1)).subscribe(applicationSchemes => {
          this.dataSetService.getAndCopyCoreDatasetsByProject(projectNew, datasetList)
            .subscribe((result) => {
              this.personalProtectionEquipmentSchemeSharedApiService.getAndCopyPersonalProtectionEquipmentSchemes(projectPkCopy, projectNew.projectPk)
                .subscribe(() => {
                  this.projectApiService.setSelectedProject(projectNew);
                  this.loading = false;
                  Swal.fire({
                    text: 'Data Saved Successfully',
                    confirmButtonColor: '#0069be',
                    confirmButtonText: 'Ok',
                  });
                });
            });
        });
    });
  }

  archiveProject() {
    if (this.selectedProject?.name != undefined && this.selectedProject?.name != '')
      if (this.selectedProject?.name != undefined && this.selectedProject?.name != '')
        Swal.fire({
          text: 'Do you want to archive the current project?',
          showCancelButton: true,
          confirmButtonColor: '#0069be',
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          icon: 'warning'
        }).then((result) => {
          if (result.value) {
            if (this.selectedProject) {
              this.selectedProject.active = false;
              this.projectApiService.saveProject(Constants.ENVIRONMENTAL_ASSESSMENTS.ESA_ASSESMENT_UDL).subscribe(data => {
                this.loading = false;
                Swal.fire({
                  text: 'Project archived successfully',
                  confirmButtonColor: '#0069be',
                  confirmButtonText: 'Ok',
                  icon: 'success'
                }).then((result) => {
                  if (result.value)
                    location.reload();
                });
              });
            }
          }
          else {
            this.loading = false;
          }
        });
  }

  setArchiveButtonStatus() {
    this.isArchiveButtonDisabled = !this.selectedProject || this.userService.profile.displayName !== this.selectedProject?.createdBy || !this.userService.currentUserCanRunModels();
  }
}
