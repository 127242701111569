import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UdlControlledVocabulary } from '../models/udl-controlled-vocabulary.model';
import { EsaUdlOverlapToolService } from './esa-udl-overlap-tool.service';

@Injectable({
  providedIn: 'root'
})

export class UdlOverlapToolContainerService {
  private CropUseSampleFileKey = 'CropUseSampleFile';
  private PCTSampleFileKey = 'PCTSampleFile';
  private OnOffConsiderationsSampleFileKey = 'OnOffConsiderationsSampleFile';
  private IndirectRelationshipsSampleFileKey = 'IndirectRelationshipsSampleFile';

  constructor(private http: HttpClient, private udlOverlapToolService: EsaUdlOverlapToolService) {

  }

  downloadDefaultCropUseFile() {
    return this.getFileByKey(this.CropUseSampleFileKey);
  }

  downloadDefaultPCTFile() {
    return this.getFileByKey(this.PCTSampleFileKey);
  }

  downloadDefaultOnOffConsiderationsFile() {
    return this.getFileByKey(this.OnOffConsiderationsSampleFileKey);
  }

  downloadDefaultIndirectRelationshipsFile() {
    return this.getFileByKey(this.IndirectRelationshipsSampleFileKey);
  }

  private getFileByKey(key: string) {
    return this.udlOverlapToolService.getControlledVocabularyItemsByKey(key).subscribe((data: UdlControlledVocabulary[]) => {
      const filename = data[0].long_value || data[0].short_value || '';
      const url = `${environment.BAMS_Api_URL}api/downloaddefaultfile/${filename}`;
      this.http.get(url, { responseType: 'json' }).subscribe((result: any) => {
        
        // convert the base64 string to a binary string
        const base64String = atob(result['FileContents']);
        const base64Uint8Array = new Uint8Array(base64String.length);
        for (let i = 0; i < base64String.length; i++) {
            base64Uint8Array[i] = base64String.charCodeAt(i);
        }
        
        // get the file contents as a blob
        const blob = new Blob([base64Uint8Array], { type: result['ContentType'] });

        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(blob);
        downloadLink.download = filename;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);  
      });
    });
  }
}