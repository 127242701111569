import { Component, EventEmitter, Output } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-duplicate-button',
  templateUrl: './duplicate-button.component.html',
  styleUrls: ['./duplicate-button.component.css']
})

export class DuplicateButtonRenderer implements ICellRendererAngularComp {
  disabled = false;
  isGroupRendered = false;
  params: any;
  parent: any;
  mainTablePkName: string = "";
  instantDuplication: boolean = false;
  agInit(params: any): void {
    this.isGroupRendered = params.node.group;    
    this.params = params;
    this.parent = params.context;
    this.mainTablePkName = params.mainTablePkName;
    this.instantDuplication = params.instantDuplication;
  }


  refresh(params: ICellRendererParams<any, any>): boolean {
    return false;
  }

  onDuplicateClick(params:any)
  {
    this.parent.duplicateRow(this.params.node.id, this.mainTablePkName, this.instantDuplication);
  }
}
