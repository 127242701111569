import { Component, DestroyRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, inject } from '@angular/core';
import { DropdownListRenderer } from 'src/app/shared/renderers/dropdown-list/dropdown-list.component';
import { DataSet } from 'src/app/shared/models/echo/data-set';
import { DataSetApiService } from 'src/app/shared/services/echo/data-set.api.service';
import { Constants } from 'src/app/shared/utils/constants';
import { GridComponent } from 'src/app/shared/components/grid/grid.component';
import { DropdownFilterRenderer } from 'src/app/shared/renderers/dropdown-filter/dropdown-filter.component';
import { UsersLogicService } from 'src/app/shared/services/users.logic.service';
import { Utils } from 'src/app/shared/utils/utils';
import { TabMenuLogicService } from 'src/app/shared/services/tab-menu.logic.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EcotoxicologyApiService } from 'src/app/shared/services/echo/ecotoxicology.api.service';
import { catchError, finalize, forkJoin, of, switchMap, take } from 'rxjs';
import { TriggersEcotoxService } from 'src/app/administrator/regulatory-models/triggers-ecotox/triggers-ecotox.service';
import { TriggerEcotox } from 'src/app/shared/models/trigger-ecotox';
import { GeographyApiService } from 'src/app/shared/services/echo/geography.api.service';
import { Catalog } from 'src/app/shared/models/echo/catalog';
import { TaxonMatching } from 'src/app/shared/models/echo/taxon-matching';
import { ERAEcotoxDatasetsComponent } from '../era-ecotox-datasets/era-ecotox-datasets.component';
import Swal from 'sweetalert2';
import { EndpointDatasetService } from 'src/app/shared/components/endpoint-dataset/endpoint-dataset.service';
import { Project } from 'src/app/shared/models/project';
import { TaxonEndpointMatchingApiService } from 'src/app/shared/services/echo/taxon-endpoint-matching.api.service';
import { MetaboliteApiService } from 'src/app/shared/services/echo/metabolite.api.service';
import { Metabolite } from 'src/app/shared/models/echo/metabolite';
import { ActiveIngredientApiService } from 'src/app/shared/services/echo/active-ingredient.api.service';
import { Molecule } from 'src/app/shared/models/echo/molecule';
import { ProjectXCompoundXModel } from 'src/app/shared/models/project-x-compound-x-model';
import { DuplicateButtonRenderer } from 'src/app/shared/renderers/duplicate-button/duplicate-button.component';

@Component({
  selector: 'app-era-ecotox-endpoints',
  templateUrl: './era-ecotox-endpoints.component.html',
  styleUrls: ['./era-ecotox-endpoints.component.css'],
})
export class ERAEcotoxEndpointsComponent implements OnInit, OnDestroy {
  @Input() endpointComponent!: ERAEcotoxDatasetsComponent;
  @Input() dataSet!: DataSet;
  @Input() menuService!: TabMenuLogicService;
  @Input() subTabMenuService!: TabMenuLogicService;
  @Input() regionPk: any;
  @Input() taxonGroup?: string;
  @Input() isProjectOwnershipValid: boolean = false;
  @Input() selectedProject?: Project;
  @Input() source!: string;

  @Output() detailTransactionToSave = new EventEmitter<any>();
  @Output() emitSavedDatasetpk = new EventEmitter<number>();

  style: string = 'width: auto; height: 350px; text-align: left; z-index:-1;font-size:10px; color: #656565;';
  isLoading!: boolean;
  columnDefs: any = [];
  endpointTypeIdentity: any;
  endpointTypesList: any = [];
  endpointsList: any = [];
  rowData: any = [];
  dataToSave: any;
  saveRef?: string;
  destroyRef = inject(DestroyRef);
  geographies: Catalog[] = [];
  triggers: TriggerEcotox[] = [];
  selectedGeographie: any;
  speciesCatalog: Catalog[] = [];
  taxonList: TaxonMatching[] = [];
  mainGeography?: string = '';
  showAlert: boolean = false;
  projectPk!: number;
  metaboliteList: Catalog[] = [];
  activeIngredientList: Catalog[] = [];

  @ViewChild('grid') grid!: GridComponent;

  constructor(public dataSetService: EndpointDatasetService,
    private usersService: UsersLogicService,
    private dataSetApiService: DataSetApiService,
    private ecotoxicologyApiService: EcotoxicologyApiService,
    private triggerEcotoxService: TriggersEcotoxService,
    private geograpthyAPIService: GeographyApiService,
    private taxonEndpointMatchingApiService: TaxonEndpointMatchingApiService,
    private metaboliteApiService: MetaboliteApiService,
    private activeIngredientApiService: ActiveIngredientApiService
  ) { }


  ngOnDestroy(): void {
    if (this.grid?.transactionList) {

      if(this.grid?.transactionList.length > 0){
        this.emitSavedDatasetpk.emit(this.dataSet.dataSetPk);
      }


      this.dataToSave = this.grid.transactionList;
      this.setDataToSave();
      this.detailTransactionToSave.emit(this.dataToSave);
    }
  }

  deleteRow(event: any) {
    this.save(event);
  }

  ngOnInit(): void {
    this.projectPk = this.selectedProject?.projectPk ?? -1;
    this.mainGeography = this.selectedProject?.geography==Constants.GEOGRAPHYS.UK? Constants.GEOGRAPHYS.EUROPE_UNION: this.selectedProject?.geography!;
    this.getEcotoxData();
  }

  initSubscribes() {
    this.menuService.activeIndexChanged.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.saveRef = this.menuService.saveRef;
      this.saveData(this.grid?.transactionList)
    });
    this.subTabMenuService.activeIndexChanged.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.saveRef = this.subTabMenuService.saveRef;
      this.saveData(this.grid?.transactionList)
    });
  }

  getData(): void {
    this.speciesCatalog = this.endpointComponent.speciesCatalog;
    this.getEndpointData();
  }

  getEndpointData() {
    forkJoin([
      this.dataSetApiService.getEndpointsByDataSetsTaxon([this.dataSet], this.taxonGroup!),
      this.taxonEndpointMatchingApiService.GetAllTaxonValueFromEndPointsByGeographyAndTaxonGroup(this.mainGeography!, this.taxonGroup!),
      this.metaboliteApiService.getMetabolitesByMolecule(this.endpointComponent.moleculePk),
      this.activeIngredientApiService.getMoleculeByMoleculePks([this.endpointComponent.moleculePk])
    ]).subscribe(([data, taxons, metabolites, actives]) => {
      this.taxonList = taxons;
      this.setMetaboliteList(metabolites);
      this.setActiveIngredientList(actives)
      this.setEndpointTypeList();
      this.filterAllRowData(data);
      this.addEndpointsToFieldValueList();
      this.configureGridRiskAssessment();
      this.isLoading = false;
    });
  }

  setEndpointTypeList() {
    let tempList: any[] = [];
    this.taxonList.forEach((taxonMatch: TaxonMatching) => {
      if (!tempList.includes(taxonMatch.endpointType)) {
        tempList.push(taxonMatch.endpointType)
      }
    })
    tempList.forEach((x: any) => {
      this.endpointTypesList.push({ endpointTypePk: x })
    })
  }

  getEcotoxData() {
    this.isLoading = true;
    forkJoin([
      this.triggerEcotoxService.getTaxonTriggerList(),
      this.geograpthyAPIService.getRegionCountryCatalog()
    ])
      .pipe(
        finalize(() => {
          switchMap(([triggers, geographies]) => {
            return of([triggers, geographies]);
          }),
            catchError((error) => {
              console.error('Error in forkJoin:', error);
              return of([]);
            });
        })
      )
      .subscribe(([triggers, geographies]) => {
        this.geographies = geographies.filter(
          (x: Catalog) => x.key === this.regionPk
        );

        this.triggers = triggers.filter(
          (x: any) =>
            x.geography && x.geography.trim() === this.mainGeography
        );
        this.endpointComponent.taxonCatalogList = [];

        this.triggers.forEach((x: TriggerEcotox) => {
          if (x.taxonGroup === this.taxonGroup) {
            var newCatalog: Catalog = {
              key: x.id,
              name: x.description,
            };
            this.endpointComponent.taxonCatalogList.push(newCatalog);
          }
        });

        this.getData();
        this.initSubscribes();
      });
  }

  filterAllRowData(data: any): void {
    this.rowData = data[0].endpoints;
    this.filterRowDataByEndpointTypePreselectedValue();
  }

  filterRowDataByEndpointTypePreselectedValue(): void {
    this.rowData = this.rowData.filter(
      (x: any) => x.disciplinePk === this.endpointComponent.mainDiscipline
    );
    this.rowData = this.processGroupedEndpoints();
  }

  processGroupedEndpoints(): any[] {
    this.rowData.forEach((row: any) => {
      if (row.groupedEndpointFieldValues?.length > 0)
        Constants.PEC_SURFACEWATER_ENDPOINTS.forEach((endpoint) => {
          if (
            row.groupedEndpointFieldValues.find(
              (x: any) => x.key.toLowerCase() == endpoint.name
            )
          )
            row[endpoint.field] = row.groupedEndpointFieldValues.filter(
              (x: any) => x.key.toLowerCase() == endpoint.name
            )[0].value;
        });
    });
    return this.rowData;
  }

  saveData(data: any) {
    if (data === undefined) {
      this.menuService.setSuccess(true);
      this.subTabMenuService.setSuccess(true);
      return;
    }

    if (data.length <= 0) {
      this.menuService.setSuccess(true);
      this.subTabMenuService.setSuccess(true);
      return;
    }

    this.dataToSave = data;
    this.setDataToSave();
    this.save(this.dataToSave);
  }

  save(data: any) {
    this.ecotoxicologyApiService.saveData(data).subscribe({
      next: () => {
        this.saveRef === Constants.WIZARD_MENU_REF.Master ? this.menuService.setSuccess(true) : this.subTabMenuService.setSuccess(true);
      },
      error: (err) => {
        this.saveRef === Constants.WIZARD_MENU_REF.Master ? this.menuService.setSuccess(false) : this.subTabMenuService.setSuccess(false);
      }
    })
  }

  onSelectionChanged(event: any) {
    this.validatedDataSetName(this.newRowDataSetObject(this.validateDdlSelections(event)));
    this.setTaxonExpusure(event);
    this.setSpeciesDefaultValue(event);
    this.grid.gridApi.redrawRows();
  }

  setSpeciesDefaultValue(event: any) {
    if (Constants.DEFAULT_VALUE_SPECIES_LIST_TERRESTRIAL_VERTEBRATES.includes(event.speciesScientificName) && event.speciesBodyWeight === undefined) {
      event.speciesBodyWeight = 350;
    }
  }

  validatedDataSetName(row: any) {
    if (row?.description != undefined && row?.newRow === true && this.showAlert === false)
      this.dataSetService.existDataSetNameByProjectMolecule(row?.description,
        this.projectPk,
        row?.moleculePk).pipe(take(1)).subscribe((exist: any) => {
          if (exist) {
            this.showAlert = true;
            Swal.fire({
              title: `${row.description}, This Dataset name already exists on selected active ingredient.`,
              text: 'Please use a different name',
              confirmButtonColor: '#0069be',
              confirmButtonText: 'Ok',
              icon: 'error',
            });
          } else {
            this.showAlert = false;
          }
        });
  }


  validateDdlSelections(pData: any) {
    if (pData.dataSetPk == undefined) {
      pData.dataSetPk = -1;
    }
    if (pData.moleculePk == -1) {
      pData.moleculePk == this.dataSet.moleculePk;

    }
    return pData;
  }

  public newRowDataSetObject(data: any) {
    let newRow: DataSet = {
      dataSetPk: data?.dataSetPk,
      description: data.row?.description,
      dataSetsToImport: [],
      hasEndpoints: false,
      moleculePk: data.row?.moleculePk,
      source: this.source,
      projectPk: this.projectPk,
      activeIngredient: '',
      substanceType: 'Active',
    };
    data = newRow;
    return data;
  }

  setTaxonExpusure(event: any) {
    if (
      event.endpointTypePk !== undefined &&
      event.endpointValue !== undefined &&
      event.speciesScientificName !== undefined
    ) {
      let endpointType = event.endpointTypePk;
      let endpoint = event.endpointValue;
      let speciesPk = this.speciesCatalog.filter((species: Catalog) => species.name == event.speciesScientificName)[0]?.key;

      let searchTaxon = this.taxonList.filter((taxon: TaxonMatching) => taxon.endpointType == endpointType && taxon.endpoints?.includes(endpoint) && taxon.species?.includes(speciesPk!))[0]?.taxonPk;

      event.taxonExposureDuration = searchTaxon !== undefined ? searchTaxon : event.taxonExposureDuration;
    }
  }

  addEndpointsToFieldValueList(): void {
    this.endpointComponent.customDatafieldGroups.forEach((item) => {
      item.endpoint.forEach((endpoint) => {
        if (
          endpoint != null &&
          this.endpointsList
            .map((x: any) => x.endpointValue)
            .indexOf(endpoint) === -1
        ) {
          this.endpointsList.push({ endpointValue: endpoint });
        }
      });
      this.endpointsList.sort((a: any, b: any) =>
        a.endpointValue.localeCompare(b.endpointValue)
      );
    });
  }

  DDLFilterCellRenderer(params: any): string {
    let value =
      params.values.find(
        (obj: any) => obj[params.valueField] == params.value
      ) != undefined
        ? params.values.find(
          (obj: any) => obj[params.valueField] == params.value
        )[params.textField]
        : params.value;
    return '<span>' + value + '</span>';
  }

  setSubstancesNames(params: any) {
    if (params.data.substanceType === Constants.SUBSTANCE_TYPES.ACTIVE) {
      return this.activeIngredientList;
    }
    if (params.data.substanceType === Constants.SUBSTANCE_TYPES.METABOLITE) {
      return this.metaboliteList;
    }
    return;
  }

  setEndpointValueNames(params: any) {
    let tempList: string[] = [];
    if (params.data.endpointTypePk === undefined) return [];

    if (
      this.taxonList.filter(
        (x: TaxonMatching) => x.endpointType === params.data.endpointTypePk
      ).length <= 0
    )
      return [];

    this.taxonList.filter((x: TaxonMatching) => x.endpointType === params.data.endpointTypePk).forEach((x: TaxonMatching) => {
      x.endpoints?.forEach((x: string) => {
        if (!tempList.includes(x)) {
          tempList.push(x);
        }
      })
    })

    return tempList.map((x: string) => { return { endpointValue: x } });
  }


  setSpeciesList(params: any) {
    let speciesXTaxon: number[] = [];
    let speciesCatalog: Catalog[] = [];
    if (params.data.endpointValue === undefined) return [];

    this.taxonList.filter((x: TaxonMatching) => x.endpointType === params.data.endpointTypePk && x.endpoints?.includes(params.data.endpointValue)).forEach((item: TaxonMatching) => {
      speciesXTaxon.push(...item.species)
    });

    return this.endpointComponent.speciesCatalog.filter((item: Catalog) => {

      if (speciesXTaxon.includes(item.key!)) {
        return item;
      }

      return;
    });
  }

  configureGridRiskAssessment(): void {
    this.columnDefs = [
      {
        headerName: '',
        hide: true,
      },
      {
        headerName: 'Taxon/ Exposure Duration',
        field: 'taxonExposureDuration',
        hide: false,
        width: 300,
        suppressSizeToFit: true,
        columnGroupShow: 'close',
        type: 'select',
        pinned: 'right',
        enableCellChangeFlash: true,
        filter: 'agSetColumnFilter',
        cellRenderer: DropdownListRenderer,
        cellRendererParams: {
          instance: this,
          options: this.endpointComponent.taxonCatalogList,
          columnThatModifies: 'taxonExposureDuration',
          fieldToFilter: 'taxonExposureDuration',
          filterDataValue: this.endpointComponent.taxonCatalogList,
          optionLabel: 'name',
          optionValue: 'key',
          sort: 'asc',
          filterable: false,
          disabled: !this.isProjectOwnershipValid,
          isRequired: true,
        },
        instance: this,
        validationType: 'string',
      },
      {
        headerName: 'Substance Type',
        field: 'substanceType',
        hide: false,
        width: 150,
        pinned: 'left',
        suppressSizeToFit: true,
        columnGroupShow: 'close',
        type: 'select',
        filter: DropdownFilterRenderer,
        filterParams: {
          labelId: 'substanceType',
        },
        cellRenderer: DropdownListRenderer,
        cellRendererParams: {
          instance: this,
          options: Constants.SUBSTANCE_TYPE,
          columnThatModifies: 'substanceType',
          fieldToFilter: 'activeIngredientName',
          filterDataValue: Constants.SUBSTANCE_TYPE,
          optionLabel: 'source',
          optionValue: 'source',
          filterable: false,
          disabled: !this.isProjectOwnershipValid,
        },
        instance: this,
        validationType: 'string',
      },
      {
        headerName: 'Substance Name',
        field: 'substanceName',
        width: 190,
        type: 'select',
        pinned: 'left',
        suppressSizeToFit: true,
        filter: DropdownFilterRenderer,
        filterParams: {
          labelId: 'name',
        },
        instance: this,
        filterable: true,
        editable: false,
        cellRenderer: DropdownListRenderer,
        cellRendererParams: (params: any) => {
          return {
            options: this.setSubstancesNames(params),
            columnThatModifies: 'substanceName',
            optionLabel: 'name',
            optionValue: 'name',
            parent: this,
            disabled: !this.isProjectOwnershipValid,
          };
        },
      },
      {
        headerName: 'Endpoint Type',
        field: 'endpointTypePk',
        hide: false,
        width: 250,
        suppressSizeToFit: true,
        columnGroupShow: 'close',
        type: 'select',
        filter: DropdownFilterRenderer,
        filterParams: {
          labelId: 'endpointType',
        },
        cellRenderer: DropdownListRenderer,
        cellRendererParams: (params: any) => {
          return {
            instance: this,
            options: this.endpointTypesList.map((x: any) => x.endpointTypePk),
            columnThatModifies: 'endpointTypePk',
            fieldToFilter: 'endpointTypePk',
            filterDataValue: this.endpointTypesList.map((x: any) => x.endpointTypePk),
            sort: 'asc',
            filterable: false,
            disabled: !this.isProjectOwnershipValid,
          };
        },
        instance: this,
        validationType: 'string',
      },
      {
        headerName: 'Endpoint',
        field: 'endpointValue',
        hide: false,
        width: 250,
        type: 'select',
        suppressSizeToFit: true,
        filter: DropdownFilterRenderer,
        filterParams: {
          labelId: 'name',
        },
        instance: this,
        filterable: true,
        editable: false,
        cellRenderer: DropdownListRenderer,
        cellRendererParams: (params: any) => {
          return {
            options: this.setEndpointValueNames(params),
            columnThatModifies: 'endpointValue',
            optionLabel: 'endpointValue',
            optionValue: 'endpointValue',
            parent: this,
            disabled: !this.isProjectOwnershipValid,
          };
        },
      },
      {
        headerName: 'Species Scientific Name',
        field: 'speciesScientificName',
        hide: false,
        width: 200,
        suppressSizeToFit: true,
        columnGroupShow: 'close',
        type: 'select',
        filter: 'agSetColumnFilter',
        cellRenderer: DropdownListRenderer,
        cellRendererParams: (params: any) => {
          return {
            instance: this,
            options: this.setSpeciesList(params),
            columnThatModifies: 'speciesScientificName',
            fieldToFilter: 'speciesScientificName',
            optionLabel: 'name',
            optionValue: 'name',
            sort: 'asc',
            filterable: false,
            disabled: !this.isProjectOwnershipValid,
          }
        },
        instance: this,
        validationType: 'string',
      },
      {
        headerName: 'Species Body Weight (g)',
        field: 'speciesBodyWeight',
        width: 120,
        minWidth: 120,
        suppressSizeToFit: true,
        filter: 'agSetColumnFilter',
        type: 'default',
        hide: this.taxonGroup === Constants.TAXON_GROUPS.TERRESTRIAL_VERTEBRATE ? false : true,
        editable: (params: any) => this.enableColumnsBySpecies(params.data),
        cellStyle: (params: any) => {
          if (!this.enableColumnsBySpecies(params.data)) {
            return { 'background-color': '#f0f0f0d7' };
          }
          return null;
        }
      },
      {
        headerName: 'Operator',
        field: 'endpointOp',
        hide: false,
        width: 100,
        suppressSizeToFit: true,
        type: 'select',
        editable: true,
        filter: 'agSetColumnFilter',
        cellRenderer: DropdownListRenderer,
        cellRendererParams: {
          instance: this,
          options: ['<', '>', '=', '≤', '≥'],
          columnThatModifies: 'endpointOp',
          sort: 'asc',
          filterable: false,
          disabled: !this.isProjectOwnershipValid,
        },
        instance: this,
        validationType: 'string',
      },
      {
        headerName: 'Quantitative Value (numerical)',
        field: 'value',
        width: 200,
        minWidth: 200,
        suppressSizeToFit: true,
        filter: 'agSetColumnFilter',
        type: 'default',
        editable: this.isProjectOwnershipValid,
      },
      {
        headerName: 'Qualitative Value (text)',
        field: 'textValue',
        width: 200,
        minWidth: 200,
        suppressSizeToFit: true,
        filter: 'agSetColumnFilter',
        type: 'default',
        editable: this.isProjectOwnershipValid,
      },
      {
        headerName: 'Unit',
        field: 'unitValue',
        hide: false,
        width: 130,
        suppressSizeToFit: true,
        columnGroupShow: 'close',
        type: 'select',
        filter: 'agSetColumnFilter',
        cellRenderer: DropdownListRenderer,
        cellRendererParams: {
          instance: this,
          options: this.endpointComponent.units.sort((a: Catalog, b: Catalog) => a.name!.localeCompare(b.name!)),
          columnThatModifies: 'unitValue',
          fieldToFilter: 'unitValue',
          filterDataValue: this.endpointComponent.units,
          optionLabel: 'name',
          optionValue: 'name',
          sort: 'asc',
          filterable: false,
          disabled: !this.isProjectOwnershipValid,
        },
        instance: this,
        validationType: 'string',
      },
      {
        headerName: 'Dietary Concentration Reported?',
        field: 'dietaryConcentrationReported',
        width: 125,
        minWidth: 125,
        type: 'select',
        suppressSizeToFit: true,
        filter: DropdownFilterRenderer,
        cellRenderer: DropdownListRenderer,
        cellRendererParams: (params: any) => {
          return {
            options: [{ key: Constants.AFFIRMATIVE_VALUES.YES }, { key: Constants.AFFIRMATIVE_VALUES.NO }],
            columnThatModifies: 'dietaryConcentrationReported',
            optionLabel: 'key',
            optionValue: 'key',
            parent: this,
            disabled: Constants.DISABLE_SPECIES_LIST_TERRESTRIAL_VERTEBRATES.includes(params.data.speciesScientificName) || !this.isProjectOwnershipValid,
          };
        },
        editable: false,
        hide: this.taxonGroup === Constants.TAXON_GROUPS.TERRESTRIAL_VERTEBRATE ? false : true,
        cellStyle: (params: any) => {
          if (!this.enableColumnsBySpecies(params.data)) {
            return { 'background-color': '#f0f0f0d7' };
          }
          return null;
        }
      },
      {
        headerName: 'Estimated or Reported dietary concentration (mg/kg diet)',
        field: 'dietaryConcentration',
        width: 170,
        minWidth: 170,
        suppressSizeToFit: true,
        filter: 'agSetColumnFilter',
        type: 'default',
        hide: this.taxonGroup === Constants.TAXON_GROUPS.TERRESTRIAL_VERTEBRATE ? false : true,
        editable: (params: any) => this.setEditableDietaryConcentration(params.data),
        cellStyle: (params: any) => {
          if (!this.setEditableDietaryConcentration(params.data)) {
            return { 'background-color': '#f0f0f0d7' };
          }
          return null;
        },
      },
      {
        headerName: '',
        colId: 'duplicate',
        field: 'duplicateButton',
        cellRenderer: DuplicateButtonRenderer,
        type: 'rightAligned',
        width: 130,
        cellRendererParams: {
            disabled: !this.isProjectOwnershipValid,
            mainTablePkName: "endpointEcotoxicologyPk",
            instantDuplication: true
        }        
      }        
    ];
  }

  enableColumnsBySpecies(data: any) {
    if (Constants.DISABLE_SPECIES_LIST_TERRESTRIAL_VERTEBRATES.includes(data.speciesScientificName)) return false;

    return true;
  }

  setEditableDietaryConcentration(data: any) {
    if (this.enableColumnsBySpecies(data)) {
      if (data.dietaryConcentrationReported === Constants.AFFIRMATIVE_VALUES.YES) return true;

      return false;
    }

    return false;
  }

  getEndpointFieldToGetSelectedValue(): any {
    return (this.endpointTypeIdentity = ['endpointTypePk']);
  }

  getEndpointFieldToFilter(): any {
    return (this.endpointTypeIdentity = ['endpoint_type']);
  }

  setDataToSave(): void {
    this.setDataSetPkToTransactionRow();
    this.addAuthor();
    this.addLastModifiedDate();
  }

  addAuthor(): void {
    this.dataToSave.forEach((transaction: any) => {
      if (transaction['row'].status === Constants.CONST_APPROVED) {
        transaction['row'].reviewedBy = this.usersService.getActiveUserID();
      } else {
        transaction['row'].modifiedBy = this.usersService.getActiveUserID();
      }
      transaction['row'].dataSource =
        Constants.THIS_APPLICATION_ENDPOINT_DATA_SOURCE;
    });
  }

  setDataSetPkToTransactionRow(): void {
    if (this.dataSet) {
      this.dataToSave.forEach((transaction: any) => {
        transaction['row'].dataSetPk = this.dataSet.dataSetPk;
        transaction['row'].activeIngredientName =
          this.setActiveIngredientName();
        transaction['row'].disciplinePk = this.endpointComponent.mainDiscipline;
        transaction['row'].geographyName = this.selectedProject?.geography;
      });
    }
  }

  addLastModifiedDate(): void {
    this.dataToSave.forEach((transaction: any) => {
      transaction['row'].lastModifiedDate = Utils.getDateFormatToSaveData();
    });
  }

  setActiveIngredientName() {
    return this.activeIngredientList.filter(
      (x: any) => x.key === this.dataSet.moleculePk
    )[0].name;
  }


  setMetaboliteList(metabolites: Metabolite[]) {
    var selectedMetabolites: number[] = [];
    this.selectedProject?.projectXCompoundXModel?.filter((x: ProjectXCompoundXModel) => x.MoleculePk == this.endpointComponent.moleculePk && x.MetabolitePk !== null)
      .forEach((x: ProjectXCompoundXModel) => {
        if (!selectedMetabolites.includes(x.MetabolitePk!)) {
          selectedMetabolites.push(x.MetabolitePk!)
        }
      });

    metabolites.forEach((x: Metabolite) => {
      if (selectedMetabolites.includes(x.metabolitePk)) {
        this.metaboliteList.push({ key: x.metabolitePk, name: x.metaboliteName });
      }
    })
  }

  setActiveIngredientList(molecules: Molecule[]) {
    molecules.forEach((x: Molecule) => {
      this.activeIngredientList.push({ key: x.moleculePk, name: x.moleculeName });
    })
  }
}

