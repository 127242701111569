import { Constants } from "src/app/shared/utils/constants";
import { EndpointsParameterConfigurationPwc } from "./eea-endpoints-parameters-pwc.inteface";

export abstract class EndpointsConfigurationPwc {

  public static readonly CONFIGURATION: EndpointsParameterConfigurationPwc[] = [
    {
      displayName: 'Sorption (ml/g)',
      showMoles: false,
      endpointFieldValue: Constants.DATA_VALUES_NAMES.Kfoc,
      exponential: false
    },
    {
      displayName:'Water Column Halflife (day)',
      showMoles: true,
      endpointFieldValue: Constants.DATA_VALUES_NAMES.halfLifeWater,
      exponential: false
    },
    {
      displayName: 'Water Reference Temperature (°C)',
      showMoles: false,
      endpointFieldValue: Constants.DATA_VALUES_NAMES.measuredHalfLifeWater,
      exponential: false
    },
    {
      displayName: 'Benthic Halflife (day)',
      showMoles: true,
      endpointFieldValue: Constants.DATA_VALUES_NAMES.halfLifeSediment,
      exponential: false
    },
    {
      displayName: 'Benthic Reference Temperature (°C)',
      showMoles: false,
      endpointFieldValue: Constants.DATA_VALUES_NAMES.measuredHalfLifeSediment,
      exponential: false
    },
    {
      displayName: 'Aqueous Photolysis Halflife (day)',
      showMoles: true,
      endpointFieldValue: Constants.DATA_VALUES_NAMES.aqueousPhotolysisHalfLife,
      exponential: false
    },
    {
      displayName: 'Photolysis Reference Latitude (°N)',
      showMoles: false,
      endpointFieldValue: Constants.DATA_VALUES_NAMES.photolysisReferenceLatitude,
      exponential: false
    },
    {
      displayName: 'Hydrolysis Halflife (day)',
      showMoles: true,
      endpointFieldValue: Constants.DATA_VALUES_NAMES.hydrolysisHalfLife,
      exponential: false
    },
    {
      displayName: 'Soil Halflife (day)',
      showMoles: true,
      endpointFieldValue: Constants.DATA_VALUES_NAMES.halfLifeInSoil,
      exponential: false
    },
    {
      displayName: 'Soil Reference Temperature (°C)',
      showMoles: false,
      endpointFieldValue: Constants.DATA_VALUES_NAMES.measuredHalfLifeSoil,
      exponential: false
    },
    {
      displayName: 'Foliar Halflife (day)',
      showMoles: true,
      endpointFieldValue: Constants.DATA_VALUES_NAMES.foliarHalfLife,
      exponential: false
    },
    {
      displayName: 'Foliar Washoff (cm¯¹)',
      showMoles: false,
      endpointFieldValue: Constants.DATA_VALUES_NAMES.foliarWashOfFactor,
      exponential: false
    },
    {
      displayName: 'Molecular Weight (g/mol)',
      showMoles: false,
      endpointFieldValue: Constants.DATA_VALUES_NAMES.molecularWeight,
      exponential: false
    },
    {
      displayName: 'Vapor Pressure (torr)',
      showMoles: false,
      endpointFieldValue: Constants.DATA_VALUES_NAMES.saturatedVapourPressure,
      exponential: true
    },
    {
      displayName: 'Solubility (mg/L)',
      showMoles: false,
      endpointFieldValue: Constants.DATA_VALUES_NAMES.solubilityInWater,
      exponential: false
    },
    {
      displayName: 'Henry’s Coefficient (vol/vol)',
      showMoles: false,
      endpointFieldValue: Constants.DATA_VALUES_NAMES.henrysConstant,
      exponential: true
    },
    {
      displayName: 'Air Diffusion Coefficient (cm²/day)',
      showMoles: false,
      endpointFieldValue: Constants.DATA_VALUES_NAMES.airDiffusionCoefficient,
      exponential: true
    },
    { displayName: 'Heat of Henry (j/mol)',
      showMoles: false,
      endpointFieldValue: Constants.DATA_VALUES_NAMES.heatOfHenry,
      exponential: false
    },
  ]
}
