import { Component, DestroyRef, EventEmitter, Input, OnInit, Output, ViewChild, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ColDef, ColGroupDef, RowNode, RowSelectedEvent, SelectionChangedEvent } from 'ag-grid-community';
import { firstValueFrom, take } from 'rxjs';
import { GridComponent } from 'src/app/shared/components/grid/grid.component';
import { Catalog } from 'src/app/shared/models/echo/catalog';
import { Project } from 'src/app/shared/models/project';
import { ActiveIngredientApiService } from 'src/app/shared/services/echo/active-ingredient.api.service';
import { TabMenuLogicService } from 'src/app/shared/services/tab-menu.logic.service';
import { Constants } from 'src/app/shared/utils/constants';
import { HhraGapApiService } from './hhra-gap-non-dietary.api.service';
import { DataSetApiService } from 'src/app/shared/services/echo/data-set.api.service';
import { EEAGapLogicService } from 'src/app/environmental-exposure-assessment/eea-gap/eea-gap.logic.service';
import { HhraGapNonDietaryColdef } from './hhra-gap-non-dietary-coldef';
import { FormulationApiService } from 'src/app/shared/services/echo/formulation.api.service';
import { Molecule } from 'src/app/shared/models/echo/molecule';
import { DropdownListRenderer } from 'src/app/shared/renderers/dropdown-list/dropdown-list.component';
import { HHRAApplicationScheme, ApplicationSchemeXActiveIngredientRate, HHRAApplicationSchemeExposure, HHRAApplicationSchemeExposureScenario } from 'src/app/shared/models/hhraApplicationSchemes';
import { GridTransaction } from 'src/app/shared/components/grid/grid-transaction';
import { FormulationConcentration } from 'src/app/shared/models/echo/formulation-concentration';
import { InputNumberRenderer } from 'src/app/shared/renderers/input-number/input-number.component';
import { HHRAConstants } from '../../hhra-constants';
import { GapApplicationSchemeLogicService } from 'src/app/shared/services/gap-application-scheme.logic.service';
import { Utils } from 'src/app/shared/utils/utils';

@Component({
  selector: 'app-hhra-gap-non-dietary',
  templateUrl: './hhra-gap-non-dietary.component.html',
  styleUrls: ['./hhra-gap-non-dietary.component.css']
})
export class HhraGapNonDietaryComponent implements OnInit {
  @Input() selectedProject?: Project;
  @Input() menuService!: TabMenuLogicService;
  @Input() subTabMenuService!: TabMenuLogicService;
  @Input() isProjectOwnershipValid: boolean = false;

  @Output() public isValid = new EventEmitter<boolean>();

  style: string = 'width: auto; height: 350px; text-align: left; z-index:-1;font-size:10px; color: #656565;';
  destroyRef = inject(DestroyRef);
  saveRef?: string;
  columnDefs: ColGroupDef[] = [];
  concentrationColDef: (ColDef | ColGroupDef)[] | null = [];
  geographies: Catalog[] = [];
  rowData: any[] = [];
  isLoading!: boolean;
  mainCompartment: string = Constants.COMPARTMENTS.NONDIETARY;
  mainCompartmentPk: number = 0;
  formulationConcentrations: FormulationConcentration[] = [];
  molecules: Molecule[] = [];
  geography?: string;
  newItems: any[] = [];
  public showAdd: boolean = true;

  @ViewChild('grid') grid!: GridComponent;

  constructor(private readonly activeIngredientApiService: ActiveIngredientApiService,
    private readonly datasetService: DataSetApiService,
    private readonly gapLogicService: EEAGapLogicService,
    private readonly hhraGapNonDietaryColdef: HhraGapNonDietaryColdef,
    private readonly hhraGapApiService: HhraGapApiService,
    private readonly formulationService: FormulationApiService,
    private gapApplicationSchemeLogicService: GapApplicationSchemeLogicService
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.isLoading = true;
    this.initSubscribes();
    await this.getFormulationConcentrations()
    this.getData();
    this.geography = this.selectedProject?.geography;
  }

  async getFormulationConcentrations() {
    if (this.selectedProject)
      this.formulationConcentrations = await firstValueFrom(this.formulationService.getFormulationConcentration(this.selectedProject?.formulationPk));
  }

  initSubscribes() {
    this.menuService.activeIndexChanged.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.saveRef = this.menuService.saveRef;
      this.saveData(this.grid?.transactionList)
      this.menuService.setSuccess(true)
    });

    this.subTabMenuService.activeIndexChanged.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.saveRef = this.subTabMenuService.saveRef;
      this.saveData(this.grid?.transactionList)
      this.subTabMenuService.setSuccess(true);
    });
  }

  getData() {
    const isOPEXFormulationTypeSolid = this.selectedProject?.OPEXFormulationType === Constants.FORMULATION_TYPE_GRANULES;
    this.getCompartments().then((compartments) => {
      this.mainCompartmentPk = compartments.find((x: any) => x.compartment.replace("-", "").toLowerCase() == this.mainCompartment.replace("-", "").toLowerCase())?.endpointCompartmentPk;

      if (this.geography != Constants.GEOGRAPHYS.USA) {
        this.hhraGapApiService.getHumanHealthApplicationSchemesByProjectAndCompartment(this.selectedProject!.projectPk, this.mainCompartmentPk).subscribe({
          next: async (applicationSchemes: HHRAApplicationScheme[]) => {
            this.loadApplicationSchemeHumanHealthData(applicationSchemes);
            this.columnDefs = this.hhraGapNonDietaryColdef.getColumnsDefinition(this.isProjectOwnershipValid, isOPEXFormulationTypeSolid);
            await this.addActiveSubstanceConcentrationToGrid(applicationSchemes, this.selectedProject!);
            this.isLoading = false;
            this.refreshSelectedValuesTimeout();
          }
        });
      }

      if (this.geography === Constants.GEOGRAPHYS.USA) {
        this.hhraGapApiService.getHumanHealthApplicationSchemeExposureByProjectAndCompartment(this.selectedProject!.projectPk, this.mainCompartmentPk).subscribe({
          next: async (applicationSchemes: HHRAApplicationSchemeExposure[]) => {
            this.columnDefs = await this.hhraGapNonDietaryColdef.getUSAColumnsDefinition(this.isProjectOwnershipValid);
            await this.addExposureActiveSubstanceConcentrationToGrid(applicationSchemes, this.selectedProject!);
            this.isLoading = false;
            this.refreshSelectedValuesTimeout();
          }
        });
      }
    })
  }

  duplicateRow(event:any)
  {
    this.enableControls(false);
  }

  newRowAdded(event:any) {        
    this.enableControls(false);
    if (!(event && event.isDuplicate))
    {
      this.newItems = [];
      if(this.selectedProject?.geography === Constants.GEOGRAPHYS.USA) {
        this.newItems.push(this.createHHRAApplicationSchemeExposure());

      }else{
        this.newItems.push(this.createObjectHHRA());
      }

      this.newItems.forEach((newRow: any) => {
        this.molecules?.forEach((molecule: Molecule) => {
          if (!this.selectedProject || molecule.moleculePk == 0) return;
          const concentration = this.formulationConcentrations?.find(fc => fc.moleculePk === molecule.moleculePk);
          if (!concentration) return;
          let newRate = undefined;
          let newUOM = '';
          if (this.geography != Constants.GEOGRAPHYS.USA) {
            newRate = concentration.aeConcentration ? +concentration.aeConcentration : concentration.aiConcentration ? +concentration.aiConcentration : 0;
            newUOM = concentration.aeConcentration ? 'a.e' : concentration.aiConcentration ? 'a.i' : '';
          }
          let applicationSchemeXActiveIngredientRate: ApplicationSchemeXActiveIngredientRate = {
            application_scheme_x_application_pk: 0,
            applicationSchemePk: 0,
            applicationSchemeXActiveIngredientRatePk: 0,
            moleculePk: molecule.moleculePk!,
            rate: newRate,
            unitOfMeasure: newUOM,
            incrementRate: 0,
            minRate: 0,
            maxRate: 0
          }
          newRow[`rate${molecule.moleculePk}`] = newRate;
          newRow[`rateUOM${molecule.moleculePk}`] = newUOM;
          newRow.useInProject = true;
          this.newItems[0].applicationSchemeXActiveIngredientRate.push(applicationSchemeXActiveIngredientRate);
        });
      });

      if (this.selectedProject?.geography === Constants.GEOGRAPHYS.USA) {
        this.saveCurrentData();
      } else {
        this.addNewElementTOGrid(this.newItems);
      }
    }
  }

  saveCurrentData() {
    let dataTransactions: GridTransaction[] = [];
    let unique: number = -1;
    this.rowData.forEach((row: any) => {
      unique++;
      let dataTransaction: any = {
        rowUniqueID: unique.toString(),
        row: row,
        transactionType: row.newRow ? 2 : 1,
        setRowUniqueID: function (pIdRowUniqueID: string): void {
          dataTransaction.rowUniqueID = pIdRowUniqueID;
        },
        getRowUniqueID: function (): string {
          return dataTransaction.rowUniqueID;
        },
        setTransactionType: function (pTransactionType: number): void {
          dataTransaction.transactionType = pTransactionType;
        },
        getTransactionType: function (): number {
          return dataTransaction.transactionType;
        },
        setRow: function (pRow: any): void {
          dataTransaction.row = pRow;
        },
        getRow: function () {
          return dataTransaction.row;
        }
      }
      if (row.name) {
        dataTransactions.push(dataTransaction);
      }
    })
    this.saveData(dataTransactions, true);
  }

  addNewElementTOGrid(newItems: HHRAApplicationScheme[]) {
    this.rowData.unshift(newItems[0]);
    if (this.selectedProject?.geography != Constants.GEOGRAPHYS.USA) {
      this.grid.gridApi.applyTransaction({
        add: newItems,
      });
      this.grid.setLastRowSelected('useInProject');
      this.refreshSelectedValues();
    }
  }

  loadApplicationSchemeHumanHealthData(applicationSchemes: HHRAApplicationScheme[]) {
    if (!applicationSchemes) return;
    applicationSchemes.map(scheme => {
      scheme.activity = scheme.applicationSchemeHumanHealth?.activity;
      scheme.applicationEquipment = scheme.applicationSchemeHumanHealth?.applicationEquipment;
      scheme.applicationWaterVolumeMin = scheme.applicationSchemeHumanHealth?.applicationWaterVolumeMin;
      scheme.applicationWaterVolumeMax = scheme.applicationSchemeHumanHealth?.applicationWaterVolumeMax;
      scheme.bufferStrip = scheme.applicationSchemeHumanHealth?.bufferStrip;
      scheme.cropType = scheme.applicationSchemeHumanHealth?.cropType;
      scheme.driftReduction = scheme.applicationSchemeHumanHealth?.driftReduction;
      if (this.geography != Constants.GEOGRAPHYS.USA) {
        scheme.applicationClosedCab = scheme.applicationSchemeHumanHealth?.applicationClosedCab;
      }
      scheme.indoorOutdoorApplication = scheme.applicationSchemeHumanHealth?.indoorOutdoorApplication;
      scheme.maxProductApplicationRate = scheme.applicationSchemeHumanHealth?.maxProductApplicationRate;
      scheme.season = scheme.applicationSchemeHumanHealth?.season;
      scheme.useInProject = scheme.useInProject;
    });
  }

  saveData(dataTransaction: GridTransaction[], isAddEvent: boolean = false) {

    if (!dataTransaction || dataTransaction.length <= 0) {
      this.menuService.setSuccess(true);
      this.subTabMenuService.setSuccess(true);
      this.updateNewRowGridData(isAddEvent);
      return;
    }

    this.rowData = this.rowData.filter((r: any) => r.deleted === undefined || r.deleted === false);
    const deletedRow = dataTransaction.find( (row: any) => row.transactionType === Constants.TRANSACTION_TYPE.DELETE ) as any;
    this.checkIfApplicationSchemeIsValid( deletedRow?.row.name, deletedRow?.row.applicationSchemePk );

    this.createTransactionsForActiveConcentration(dataTransaction);
    this.setParametersToNewRows(dataTransaction, this.selectedProject?.projectPk);
    this.setActiveIngredientsRate(dataTransaction);
    if (this.geography != Constants.GEOGRAPHYS.USA) {
      this.hhraGapApiService.saveHumanHealthRiskAssessmentApplicationSchemes(dataTransaction).pipe(take(1)).subscribe({
        next: () => {
          this.saveRef === Constants.WIZARD_MENU_REF.Master ? this.menuService.setSuccess(true) : this.subTabMenuService.setSuccess(true);
        },
        error: (err) => {
          this.saveRef === Constants.WIZARD_MENU_REF.Master ? this.menuService.setSuccess(false) : this.subTabMenuService.setSuccess(false);
        }
      });
    }
    if (this.geography === Constants.GEOGRAPHYS.USA) {
      this.hhraGapApiService.saveHumanHealthRiskAssessmentApplicationSchemeExposure(dataTransaction).pipe(take(1)).subscribe({
        next: () => {
          this.saveRef === Constants.WIZARD_MENU_REF.Master ? this.menuService.setSuccess(true) : this.subTabMenuService.setSuccess(true);
          this.updateNewRowGridData(isAddEvent);
        },
        error: (err) => {
          this.saveRef === Constants.WIZARD_MENU_REF.Master ? this.menuService.setSuccess(false) : this.subTabMenuService.setSuccess(false);
        }
      });
    }
  }

  updateNewRowGridData(refresh: boolean = false) {
    if (refresh) {
      this.updateGridData(refresh);
    } else {
      this.updateGridData();
    }
  }

  updateGridData(refresh: boolean = false) {
    this.hhraGapApiService.getHumanHealthApplicationSchemeExposureByProjectAndCompartment(this.selectedProject!.projectPk, this.mainCompartmentPk).subscribe({
      next: async (applicationSchemes: HHRAApplicationSchemeExposure[]) => {
        await this.addExposureActiveSubstanceConcentrationToGrid(applicationSchemes, this.selectedProject!, refresh);
        this.refreshSelectedValuesTimeout();
      }
    });
  }

  setParametersToNewRows(dataTransaction: GridTransaction[], projectPk?: number) { 
    dataTransaction.forEach(x => {
      if (x.getTransactionType() == 1 || x.getTransactionType() == 2) {
        x.getRow().projectPk = projectPk;
        x.getRow().compartmentPk = this.mainCompartmentPk;
        x.getRow().applicationSchemeXActiveIngredientRate = [
          ...x.getRow().applicationSchemeXActiveIngredientRate, 
          ...this.setMissingApplicationSchemeXActiveIngredientRate(x.getRow())
        ];

        if (this.geography != Constants.GEOGRAPHYS.USA) {
          const row = x.getRow();
          if (row.applicationSchemeHumanHealth) { 
            row.applicationSchemeHumanHealth.applicationClosedCab = row.applicationClosedCab;
          } 
        }
      }
    });
}

  createTransactionsForActiveConcentration(dataTransaction: GridTransaction[]) {
    this.grid.gridApi.forEachNode((node: RowNode) => node.allLeafChildren?.forEach((node: RowNode) => {
      let transaction = dataTransaction.find((x: any) => x.rowUniqueID === node.id);
      if (transaction) {

        if ((transaction.getTransactionType() == 1 || transaction.getTransactionType() == 2) && transaction.getRowUniqueID() === node.id) {
          if (!transaction.getRow()?.applicationSchemeXActiveIngredientRate) {
            transaction.getRow().applicationSchemeXActiveIngredientRate = [];
          }
          transaction.getRow().applicationSchemeXActiveIngredientRate = [...transaction.getRow().applicationSchemeXActiveIngredientRate, ...this.setMissingApplicationSchemeXActiveIngredientRate(transaction.getRow())]
        }
      } else {
        if (!node.data?.applicationSchemeXActiveIngredientRate) {
          node.data.applicationSchemeXActiveIngredientRate = [];
        }
        let rates = [...node.data.applicationSchemeXActiveIngredientRate, ...this.setMissingApplicationSchemeXActiveIngredientRate(node.data)];
        node.data.applicationSchemeXActiveIngredientRate = rates;
        this.grid.CreateTransaction(node.id, node.id, null, node.data);
      }
    }))
  }

  async setActiveIngredientsRate(dataTransaction: GridTransaction[]) {
    await dataTransaction?.forEach(async data => {
      await data.getRow()?.applicationSchemeXActiveIngredientRate?.map(async (x: any) => {
        x.rate = data.getRow()[`rate${x.moleculePk}`];
        x.unitOfMeasure = data.getRow()[`rateUOM${x.moleculePk}`];
        if ((!x.rate || x.rate == 0) && (this.geography != Constants.GEOGRAPHYS.USA)) await this.setActiveIngredientConcentration(x);
      });

      data.getRow()?.children?.forEach((child: any) => {
        child.applicationSchemeXActiveIngredientRate?.map(async (x: any) => {
          x.rate = child[`rate${x.moleculePk}`];
          x.unitOfMeasure = child[`rateUOM${x.moleculePk}`];
          if ((!x.rate || x.rate == 0) && (this.geography != Constants.GEOGRAPHYS.USA)) await this.setActiveIngredientConcentration(x);
        });
      });
    })
  }

  setActiveIngredientConcentration(applicationSchemeXActiveIngredient: any): void {
    if (!this.selectedProject) return;
    const formulationConcentration = this.formulationConcentrations?.find(fc => fc.moleculePk === applicationSchemeXActiveIngredient.moleculePk);
    if (!formulationConcentration) return;
    applicationSchemeXActiveIngredient.rate = formulationConcentration.aeConcentration ? +formulationConcentration.aeConcentration : formulationConcentration.aiConcentration ? +formulationConcentration.aiConcentration : 0;
    applicationSchemeXActiveIngredient.unitOfMeasure = formulationConcentration.aeConcentration ? 'a.e' : formulationConcentration.aiConcentration ? 'a.i' : '';
  }

  setMissingApplicationSchemeXActiveIngredientRate(data: any): any[] {
    let activeIngredients: any[] = []
    this.concentrationColDef?.forEach((x: any) => {
      let moleculePk: string = x.field?.toLowerCase().replace("rate", "");
      if (+moleculePk) {
        let existMolecule = data.applicationSchemeXActiveIngredientRate.find((x: any) => x.moleculePk == moleculePk);
        if (!existMolecule) {
          activeIngredients.push({
            moleculePk,
            rate: null,
            unitOfMeasure: ''
          });
        }
      }
    })

    return activeIngredients;
  }

  async addActiveSubstanceConcentrationToGrid(applicationSchemes: HHRAApplicationScheme[], selectedProject: Project): Promise<void> {
    const compoundMoleculePks = this.gapLogicService.getCompoundMoleculePks(applicationSchemes, selectedProject);
    this.molecules = await firstValueFrom(this.activeIngredientApiService.getMoleculeByMoleculePks(compoundMoleculePks));
    this.transformData(applicationSchemes);
    this.rowData = applicationSchemes;
    const activeSubstanceColDefs = this.createActiveSubstanceConcentrationAsColDef()
    this.insertConcentrationActiveToColumnDefs(activeSubstanceColDefs);
  }

  async addExposureActiveSubstanceConcentrationToGrid(applicationSchemes: HHRAApplicationSchemeExposure[], selectedProject: Project, addEvent: boolean = false): Promise<void> {
    const compoundMoleculePks = this.gapLogicService.getCompoundMoleculePks(applicationSchemes, selectedProject);
    this.molecules = await firstValueFrom(this.activeIngredientApiService.getMoleculeByMoleculePks(compoundMoleculePks));
    this.transformExposureData(applicationSchemes);
    this.rowData = applicationSchemes;
    const activeRateColDef = this.createActiveRateAsColDef()
    this.insertApplicationRateToColumnDefs(activeRateColDef);
    if (addEvent)
      this.addNewElementTOGrid(this.newItems);
  }

  insertConcentrationActiveToColumnDefs(activeSubstanceColDefs: ColGroupDef[]) {
    this.columnDefs.map((columDef: ColGroupDef) => {
      if (columDef.headerName === Constants.COLUMN_NAMES.CONCENTRATION_ACTIVE_SUBSTANCE) {
        this.concentrationColDef = activeSubstanceColDefs;
        columDef.children = activeSubstanceColDefs;
      }
    });
  }

  insertApplicationRateToColumnDefs(activeRateColDef: ColDef[]) {
    this.columnDefs.map((columDef: ColGroupDef) => {
      if (columDef.headerName === Constants.COLUMN_NAMES.APPLICATION_RATE) {
        columDef.children = activeRateColDef;
      }
    });
  }

  transformData(applicationSchemes: HHRAApplicationScheme[]) {
    applicationSchemes?.map((scheme: any) => {
      scheme.applicationSchemeXActiveIngredientRate.map((applicationSchemeAIRate: any) => {
        let newRate = applicationSchemeAIRate.rate;
        let newUOM = applicationSchemeAIRate.unitOfMeasure;
        if (!newRate || newRate == 0) {
          if (!this.selectedProject) return;
          const concentration = this.formulationConcentrations?.find(fc => fc.moleculePk === applicationSchemeAIRate.moleculePk);
          if (!concentration) return;
          newRate = concentration.aeConcentration ? +concentration.aeConcentration : concentration.aiConcentration ? +concentration.aiConcentration : 0;
          newUOM = concentration.aeConcentration ? 'a.e' : concentration.aiConcentration ? 'a.i' : '';
        }
        scheme[`rate${applicationSchemeAIRate.moleculePk}`] = newRate;
        scheme[`rateUOM${applicationSchemeAIRate.moleculePk}`] = newUOM;
      });
    });
  }

  transformExposureData(applicationSchemes: HHRAApplicationSchemeExposure[]) {
    applicationSchemes?.map((scheme: any) => {
      scheme.applicationSchemeXActiveIngredientRate.map((applicationSchemeAIRate: any) => {
        scheme[`rate${applicationSchemeAIRate.moleculePk}`] = applicationSchemeAIRate.rate;
        scheme[`rateUOM${applicationSchemeAIRate.moleculePk}`] = applicationSchemeAIRate.unitOfMeasure;
      });
    });
  }

  createActiveSubstanceConcentrationAsColDef(): ColGroupDef[] {
    let concentrationColdef: ColGroupDef[] = [];
    this.molecules?.forEach((molecule: Molecule) => {
      concentrationColdef.push(
        {
          headerName: molecule.moleculeName,
          children: [
            {
              headerName: 'Type',
              field: `rateUOM${molecule.moleculePk}`,
              type: 'default',
              filter: 'agSetColumnFilter',
              cellRenderer: DropdownListRenderer,
              cellRendererParams: {
                options: Constants.CONCENTRATION_ACTIVE_TYPE,
                optionLabel: 'name',
                optionValue: 'name',
                disabled: !this.isProjectOwnershipValid,
              },
              wrapText: true,
              resizable: true,
              sortable: true,
              width: 120,
              editable: false,
            },
            {
              headerName: 'Concentration',
              field: `rate${molecule.moleculePk}`,
              wrapText: true,
              type: 'default',
              filter: 'agSetColumnFilter',
              width: 150,
              resizable: true,
              editable: false,
              cellRenderer: InputNumberRenderer,
              cellRendererParams: {
                disabled: !this.isProjectOwnershipValid
              }
            }
          ],
        }
      );
    });
    return concentrationColdef;
  }

  createActiveRateAsColDef(): ColDef[] {
    let activeRateColdef: ColDef[] = [];
    this.molecules?.forEach((molecule: Molecule) => {
      activeRateColdef.push(
        {
          headerName: molecule.moleculeName,
          field: `rate${molecule.moleculePk}`,
          wrapText: true,
          type: 'default',
          filter: 'agSetColumnFilter',
          width: 150,
          resizable: true,
          editable: false,
          cellRenderer: InputNumberRenderer,
          cellRendererParams: {
            disabled: !this.isProjectOwnershipValid
          }
        },
        {
          headerName: 'Units',
          field: `rateUOM${molecule.moleculePk}`,
          type: 'default',
          filter: 'agSetColumnFilter',
          cellRenderer: DropdownListRenderer,
          cellRendererParams: {
            options: HHRAConstants.HHRA_RATE_UOM,
            optionLabel: 'name',
            optionValue: 'name',
            disabled: !this.isProjectOwnershipValid,
          },
          wrapText: true,
          resizable: true,
          sortable: true,
          width: 190,
          editable: false,
        }
      );
    });
    return activeRateColdef;
  }

  getCompartments(): Promise<any[]> {
    return new Promise<number[]>((resolve, reject) => {
      this.datasetService.getCompartments(Constants.CONST_COMPARTMENT).subscribe({
        next: (comparments: any) => {
          resolve(comparments);
        },
        error: (error: Error) => {
          reject(error);
        }
      });
    });
  }

  async dropDownSelectionChangeEvent(params: any) {
    if (params.instances) {
      let filteredScenarios: HHRAApplicationSchemeExposureScenario[] = [];
      let newValues: any = undefined;
      if (params.params.filterAll) {
        filteredScenarios = await this.hhraGapApiService.getFilteredExposureScenarioValue(params);
      }

      if (params.field == HHRAConstants.APPLICATION_METHOD && params.row[HHRAConstants.APPLICATION_METHOD] && params.instances.find((x: any) => x.field == HHRAConstants.BUFFER_STRIP)) {
        this.updateBufferStrip(params);
      }
      if (this.geography != Constants.GEOGRAPHYS.USA) {
        if (params.field == HHRAConstants.APPLICATION_EQUIPMENT && (params.row[HHRAConstants.APPLICATION_METHOD] != HHRAConstants.UPWARD_SPRAYING || params.row[HHRAConstants.APPLICATION_EQUIPMENT] != HHRAConstants.VEHICULE_MOUNTED) && params.row[HHRAConstants.APPLICATION_METHOD] != HHRAConstants.NOT_RELEVANT) {
          const appClosedCabInstance = params.instances.find((x: any) => x.field == HHRAConstants.APPLICATION_CLOSED_CAB);
          if (!appClosedCabInstance.params.options) return;
          appClosedCabInstance.newOptions = appClosedCabInstance.params.options.filter((option: any) => option.value == HHRAConstants.NOT_RELEVANT);
          appClosedCabInstance.selectedValue = HHRAConstants.NOT_RELEVANT;
        }
        if (params.field == HHRAConstants.APPLICATION_EQUIPMENT && params.row[HHRAConstants.APPLICATION_METHOD] == HHRAConstants.UPWARD_SPRAYING && params.row[HHRAConstants.APPLICATION_EQUIPMENT] == HHRAConstants.VEHICULE_MOUNTED) {
          const appClosedCabInstance = params.instances.find((x: any) => x.field == HHRAConstants.APPLICATION_CLOSED_CAB);
          if (!appClosedCabInstance.params.options) return;
          appClosedCabInstance.newOptions = appClosedCabInstance.params.options.filter((option: any) => option.value != HHRAConstants.NOT_RELEVANT);
        }
      }

      params.instances?.forEach(async (instance: any) => {
        if (params.params.filterAll && instance.field != params.field) {
          if (filteredScenarios) newValues = this.getUniqueFilteredExposureScenarioValue(instance.field, filteredScenarios);
          instance.setFilteredValues(params.sourceValues?.find((v: any) => v.value === params.value), params.params.filterAll, newValues);

          if (!newValues.some((option: any) => option.value === instance.selectedValue)) {
            const selectedOption = instance.params.options.find((option: any) => option.value === instance.selectedValue);
            if (selectedOption) {
              newValues.push(selectedOption);
            }
          }
          instance.setFilteredValues(instance.selectedValue, true, newValues);

          return;
        }

        if (instance.newOptions) {
          instance.setFilteredValues(instance.selectedValue, true, instance.newOptions);
          return;
        }

        instance.setFilteredValues(params.sourceValues?.find((v: any) => v.name === params.value));
      });
    }

    if(params.field.includes("rateUOM"))
    {
      const field = params.field;
      const moleculePk = field.replace("rateUOM", "");
      const rateId = field.replace("UOM", "");
      const concentration = this.formulationConcentrations?.find(fc => fc.moleculePk == moleculePk);
      const concentrationValue = params.value === "a.e" ? concentration?.aeConcentration : concentration?.aiConcentration;
      if(!concentration)
      {
        params.row[rateId] = null;
        return;
      }
      params.row[rateId] = concentrationValue ? +concentrationValue : null;
      this.grid.gridApi.refreshCells();
    }
  }

  private updateBufferStrip(params: any): void {
    if (!params.row[HHRAConstants.BUFFER_STRIP]) {
      const bufferStripOption = this.getBufferStripOptions(params.value);
      if (!bufferStripOption) return;
      params.row[HHRAConstants.BUFFER_STRIP] = bufferStripOption;
      params.instances.find((x: any) => x.field == HHRAConstants.BUFFER_STRIP).selectedValue = bufferStripOption;
    }
  }

  private getBufferStripOptions(applicationMethod: string): any {
    if (applicationMethod.toLowerCase().includes(HHRAConstants.DOWNWARD)) {
      return HHRAConstants.HHRA_BUFFER_STRIPS[0].name;
    } else if (applicationMethod.toLowerCase().includes(HHRAConstants.UPWARD)) {
      return HHRAConstants.HHRA_BUFFER_STRIPS[1].name;
    }
  }

  getUniqueFilteredExposureScenarioValue(field: string, filteredScenarios: HHRAApplicationSchemeExposureScenario[]) {
    const mappedScenarios = filteredScenarios.map(scenario => scenario[field as keyof typeof scenario]);
    const uniqueScenarios = [...new Set(mappedScenarios)];
    const sortedUniqueScenarios = Array.from(uniqueScenarios).sort();
    return sortedUniqueScenarios.map(scenario => ({ label: scenario, value: scenario }));
  }

  public onBlurInputText({ row }: { row: any }): void {
    this.checkIfApplicationSchemeIsValid(row.name, row.applicationSchemePk);
  }

  private async checkIfApplicationSchemeIsValid(applicationSchemeName: string | undefined, applicationSchemePk: number | undefined): Promise<void> {
    const invalidRows = this.rowData.some(row => row.name === undefined || row.name === HHRAConstants.EMPTY);
    const transactionOcurrences = this.gapApplicationSchemeLogicService.getTransactionOccurrences(this.rowData);
    const duplicateDatasetsInTransaction = this.gapApplicationSchemeLogicService.duplicateDataInRecord(transactionOcurrences);
    applicationSchemePk = applicationSchemePk ?? -1

    if (this.selectedProject?.geography != Constants.GEOGRAPHYS.USA) {

      if (applicationSchemeName != undefined && applicationSchemeName != HHRAConstants.EMPTY) {
        if (duplicateDatasetsInTransaction)
          Utils.showErrorMessage('The Application Scheme name already exists.', 'Please use another name');
      }
    }

    this.enableControls(!invalidRows || duplicateDatasetsInTransaction);
  }

  private enableControls(enable: boolean): void {
    this.showAdd = enable;
    this.isValid.emit(enable);
  }

  private refreshSelectedValuesTimeout(): void {
    setTimeout(() => {
      this.refreshSelectedValues();
    }, 75);
  }

  private refreshSelectedValues(): void {
    if (!this.grid) return;
    this.grid.refreshSelectedValues('useInProject');
  }

  public onSelectedRow(event: RowSelectedEvent): void {
    if (!this.grid) return;
    this.createTransactionForSelectedRow(event.node);
  }

  public onSelectionChanged(event: SelectionChangedEvent) {
    if (event.source !== 'uiSelectAll' || !this.grid) return;
    this.grid.gridApi.forEachNode((node: any) => {
      this.createTransactionForSelectedRow(node);
    });
  }

  private createTransactionForSelectedRow(node: any): void {
    const { id, data } = node;
    data.useInProject = node.isSelected();
    this.grid.CreateTransaction(id, id, data.useInProject, data);
  }

  createObjectHHRA(): HHRAApplicationScheme {
    let hhra: HHRAApplicationScheme = {
      projectPk: this.selectedProject?.projectPk ?? 0,
      name: '',
      compartmentPk: this.mainCompartmentPk,
      numberOfApplications: 0,
      applicationSchemeXGeography: [],
      applicationSchemeXActiveIngredientRate: [],
      applicationSchemeHumanHealthPk: 0,
      applicationSchemePk: 0,
      cropType: '',
      activity: '',
      indoorOutdoorApplication: '',
      maxProductApplicationRate: 0,
      applicationWaterVolumeMin: 0,
      applicationWaterVolumeMax: 0,
      applicationEquipment: '',
      bufferStrip: '',
      season: '',
      driftReduction: '',
      applicationClosedCab: '',
      applicationSchemeHumanHealth: {
        applicationSchemeHumanHealthPk: 0,
        applicationSchemePk: 0,
        cropType: '',
        activity: '',
        indoorOutdoorApplication: '',
        maxProductApplicationRate: 0,
        applicationWaterVolumeMin: 0,
        applicationWaterVolumeMax: 0,
        applicationEquipment: '',
        bufferStrip: '',
        season: '',
        driftReduction: '',
        aiConcentration: 0,
        aeConcentration: 0,
        applicationClosedCab: ''
      },
      children: [],
      useInProject: false
    }
    return hhra;
  }

  createHHRAApplicationSchemeExposure(): HHRAApplicationSchemeExposure {
    let hhra: HHRAApplicationSchemeExposure = {
      projectPk: this.selectedProject?.projectPk ?? 0,
      name: '',
      compartmentPk: this.mainCompartmentPk,
      applicationSchemePk: 0,
      applicationEquipment: '',
      applicationSchemeXActiveIngredientRate: [],
      workerActivity: '',
      applicationSchemeExposurePk: 0,
      applicationType: '',
      cropTargetCategory: '',
      dermal: '',
      formulation: '',
      inhalation: '',
      newRow: true,
    }
    return hhra
  }
}
