import { Component, DestroyRef, EventEmitter, Input, Output, SimpleChanges, ElementRef, inject } from '@angular/core';
import { ESALogicService } from '../udl-overlap-tool/udl-overlap-tool.logic.service';
import { Run } from 'src/app/shared/models/run/run';
import { Project } from 'src/app/shared/models/project';
import { DatafieldValue } from 'src/app/shared/models/echo/data-field-value';
import { TabMenuLogicService } from 'src/app/shared/services/tab-menu.logic.service';
import { UserLogicService } from 'src/app/shared/services/user.logic.service';
import { SelectedProjectApiService } from 'src/app/shared/components/selected-project/selected-project.api.service';
import { MoleculePkAndName } from 'src/app/shared/models/echo/molecule';
import { CustomTabMenuItem } from 'src/app/shared/models/custom-tab-menu-item';
import Swal from 'sweetalert2';
import { Catalog } from 'src/app/shared/models/echo/catalog';

@Component({
  selector: 'app-esa-run-project',
  templateUrl: './esa-run-project.component.html',
  styleUrls: ['./esa-run-project.component.css']
})
export class ESARunProjectComponent {
  runs: Run[] = [];
  @Input() selectedProject?: Project;
  @Input() selectedProjectStatus?: number;
  @Input() menuService!: TabMenuLogicService;
  @Input() environmentalAssessment!: string;
  @Output() showLoadingForOutput = new EventEmitter<boolean>();
  runQc: Boolean = false;
  loading: boolean = false;
  destroyRef = inject(DestroyRef)
  transactionList: any;
  dataFieldValues: DatafieldValue[] = [];
  activeIngredients: MoleculePkAndName[] = [];
  geographiesList!: Catalog[];
  isProjectOwnershipValid: boolean = false;
  molecules: MoleculePkAndName[] = [];
  activeItem!: CustomTabMenuItem;

  constructor(public ESALogicService: ESALogicService,
    private userService: UserLogicService,
    public selectedProjectApiService: SelectedProjectApiService,
    private elementRef: ElementRef) { }

  ngOnInit(): void {
    this.renderSweetAlertSuccessIcon();
  }

  renderSweetAlertSuccessIcon(): void {
    Swal.fire({
      icon: 'success',
      title: 'Success',
      showConfirmButton: false,
      showCancelButton: false,
      timer: 0
    });

    const successIcon = Swal.getIcon();
    if (successIcon) {
      const targetContainer = this.elementRef.nativeElement.querySelector(
        '#swal-success-icon'
      );
      if (targetContainer) {
        targetContainer.appendChild(successIcon.cloneNode(true));
      }
    }

    Swal.close();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedProject'].currentValue.projectPk) {
      this.runQc = false;
      this.isProjectOwnershipValid = this.userService.verifyProjectOwnership(this.selectedProject?.createdBy);
    }
  }

  setLoadingState(pState: boolean): void {
    this.loading = pState;
  }

  RunModel(){
    //TODO: logic to be added in the future. (ticket #68204)
  }

  BackToSetup(){
    //TODO: logic to be added in the future. (ticket #68204)
  }
}
